import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfile } from "@anw/gor-sdk";

import { TTMSearchResult } from "../../../utils/locationTypes";
import { getLocalStorageJsonArray } from "../../../utils/localStorage";

export const userLngLatKey = "user-lng-lat";
export const userLocationInfoKey = "user-location-info";

export type AwsUserLocationInfo = {
    coordinates: [number, number];
    countryCode: string;
    countryName: string;
    city: string;
};

const initialState = {
    // coordinates of the user location
    userLngLat: getLocalStorageJsonArray<number>(userLngLatKey) as [number, number],
    // user location can come from a fresh geolocate button click, or more automatically when already approved on startups or further position updates
    userLngLatFromRecentButtonClick: false,
    // geo reversed information from the user location coordinates
    userLocationInfo: null as TTMSearchResult,
    // if the user allowed the geolocation previously
    geolocationAllowed: false,
    // aws information received from CloudFront
    awsUserLocationInfo: null as AwsUserLocationInfo,
    locationAccuracy: null as number,
    profile: null as UserProfile
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserLocation: (
            state,
            action: PayloadAction<{
                lngLat: [number, number];
                accuracy: number;
                userLngLatFromRecentButtonClick?: boolean;
            }>
        ) => {
            state.userLngLat = action.payload?.lngLat;
            state.locationAccuracy = action.payload?.accuracy;
            state.userLngLatFromRecentButtonClick = action.payload?.userLngLatFromRecentButtonClick;
        },
        updateUserLocationInfo: (state, action: PayloadAction<TTMSearchResult>) => {
            state.userLocationInfo = action.payload;
        },
        setGeolocationAllowed: (state, action: PayloadAction<boolean>) => {
            state.geolocationAllowed = action.payload;
        },
        setAwsUserLocationInfo: (state, action: PayloadAction<AwsUserLocationInfo>) => {
            state.awsUserLocationInfo = action.payload;
        },
        setCurrentProfile: (state, action: PayloadAction<UserProfile>) => {
            state.profile = action.payload;
        }
    }
});

export const { actions } = userSlice;

export default userSlice.reducer;

import React, { ReactNode, TextareaHTMLAttributes, useRef } from "react";
import uniqueId from "lodash/uniqueId";
import classNames from "classnames";
import "../../../../sass/ui-library/components/base/Input.scss";
import { IcClose16, IcAlert16, IcCheck16 } from "../../icons";
import bem from "../../utils/bem";
import { qaId, QaId } from "../../utils/qa-id";
import { HelperText } from "./Input";

const inputControl = bem("input-control");

type TextAreaBaseProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "value"> & {
    controlClassName?: string;
    success?: boolean;
    error?: boolean;
    onClear?: () => void;
    value?: string;
    label?: string;
    helperTextLeft?: string;
    helperTextRight?: string;
};

export type TextAreaProps = TextAreaBaseProps & {
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
};

const TextAreaComponent = (props: TextAreaProps) => {
    const {
        className,
        success,
        error,
        iconLeft,
        iconRight,
        onClear,
        label,
        helperTextLeft,
        helperTextRight,
        controlClassName,
        placeholder = " ",
        rows = 6,
        ...inputProps
    } = props;
    const isValueSet = props.value?.length > 0;
    const id = props.id || uniqueId("form-floating");
    const inputControlQa = useRef<QaId>(qaId(props.id || "input-control"));
    const checkOrIconRightIcon = success ? <IcCheck16 /> : iconRight;
    const iconRightDerived = error ? <IcAlert16 /> : checkOrIconRightIcon;
    const feedbackId = id + "Feedback";

    return (
        <>
            <div
                {...inputControlQa.current.block()}
                className={classNames(inputControl.block(), controlClassName, {
                    "has-inner-left": iconLeft,
                    "has-inner-right": iconRightDerived || (onClear && isValueSet),
                    "form-floating": label,
                    [inputControl.modifier("invalid")]: error
                })}
            >
                <textarea
                    {...inputProps}
                    className={classNames("form-control", className, {
                        "is-valid": success,
                        "is-invalid": error
                    })}
                    id={id}
                    aria-describedby={feedbackId}
                    placeholder={placeholder}
                    rows={rows}
                    {...inputControlQa.current.element("input")}
                />
                {iconLeft && (
                    <span className="icon is-left" {...inputControlQa.current.element("icon-left")}>
                        {iconLeft}
                    </span>
                )}
                {(onClear && isValueSet && (
                    <button
                        type="button"
                        className="is-right"
                        onClick={onClear}
                        {...inputControlQa.current.element("clear")}
                    >
                        <IcClose16 />
                    </button>
                )) ||
                    (iconRightDerived && (
                        <span className="icon is-right" {...inputControlQa.current.element("icon-right")}>
                            {iconRightDerived}
                        </span>
                    ))}
                {label && (
                    <label className="accent-text" htmlFor={id} {...inputControlQa.current.element("label")}>
                        {label}
                    </label>
                )}
                {(helperTextLeft || helperTextRight) && (
                    <HelperText
                        feedbackId={feedbackId}
                        error={error}
                        success={success}
                        parentQaId={inputControlQa.current}
                        helperTextLeft={helperTextLeft}
                        helperTextRight={helperTextRight}
                    />
                )}
            </div>
        </>
    );
};

export const TextArea = (props: TextAreaProps) => {
    return <TextAreaComponent {...props} />;
};

export default TextArea;

import React, { PropsWithChildren, ReactNode } from "react";

import classNames from "classnames";
import { TextWithIcon } from "./TextWithIcon";

enum ButtonType {
    TEXT_ONLY,
    TEXT_WITH_ICON,
    ICON_ONLY
}

type ButtonBaseProps = {
    text?: string;
    className?: string;
    icon?: ReactNode;
    direction?: "horizontal" | "vertical";
    small?: boolean;
};

type ButtonProps = ButtonBaseProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonIconProps = Omit<ButtonProps, "text">;

type ButtonAsContainerProps = PropsWithChildren<Partial<ButtonProps>>;

type ButtonCloseProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "text">;

const ButtonBase = (props: ButtonProps) => {
    const { className, icon, text, title, small, direction, ...rest } = props;
    const textWithIconOrIconOnly = text ? ButtonType.TEXT_WITH_ICON : ButtonType.ICON_ONLY;
    const buttonType: ButtonType = icon ? textWithIconOrIconOnly : ButtonType.TEXT_ONLY;
    return (
        <button
            className={classNames("btn", className, {
                "as-icon": buttonType === ButtonType.ICON_ONLY,
                "btn-sm": small
            })}
            {...rest}
        >
            {buttonType === ButtonType.ICON_ONLY && <span className="icon">{props.icon}</span>}
            {buttonType === ButtonType.TEXT_ONLY ? (
                <span className="text">{text}</span>
            ) : (
                buttonType === ButtonType.TEXT_WITH_ICON && (
                    <TextWithIcon text={text} title={title} icon={icon} direction={direction} />
                )
            )}
        </button>
    );
};

const defaultButtonText = "";

// --- Normal Buttons ---
export const ButtonPrimary = (props: ButtonProps) => (
    <ButtonBase
        {...props}
        className={classNames("btn-primary", props.className)}
        text={props.text || defaultButtonText}
    />
);
export const ButtonSecondary = (props: ButtonProps) => (
    <ButtonBase
        {...props}
        className={classNames("btn-secondary", props.className)}
        text={props.text || defaultButtonText}
    />
);
export const ButtonTertiary = (props: ButtonProps) => (
    <ButtonBase
        {...props}
        className={classNames("btn-tertiary", props.className)}
        text={props.text || defaultButtonText}
    />
);

// --- Icon Buttons ---
export const ButtonIconPrimary = (props: ButtonIconProps) => (
    <ButtonBase {...props} className={classNames("btn-primary", props.className)} />
);
export const ButtonIconSecondary = (props: ButtonIconProps) => (
    <ButtonBase {...props} className={classNames("btn-secondary", props.className)} />
);

// --- Button used as a container with no styling of its own ---
export const ButtonContainer = (props: ButtonAsContainerProps) => {
    const { children, className, ...rest } = props;
    return children ? (
        <button {...rest} className={classNames("is-container", className)} value="">
            {props.children}
        </button>
    ) : (
        <ButtonBase
            {...rest}
            text={props.text || defaultButtonText}
            title={props.title}
            className={classNames("is-container", className)}
        />
    );
};

// --- Close Button ---
export const ButtonClose = (props: ButtonCloseProps) => (
    <button
        {...props}
        aria-label={props?.["aria-label"] ?? "close"}
        className={classNames("btn-close", props.className)}
    />
);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { URL_PATH_MAP } from "../../../components/elements/Links";
import { isBaseURLPathMatch, isExactURLPathMatch } from "../../../classes/localization/Localization";

export enum ForegroundOption {
    DEFAULT_SEARCH = URL_PATH_MAP.INDEX,
    SELECTED_LOCATION = URL_PATH_MAP.LOCATION_BASE,
    ROUTE_PLANNER = URL_PATH_MAP.ROUTE_PLAN,
    ROUTE_PLANNER_SETTINGS = URL_PATH_MAP.ROUTE_PLAN_SETTINGS,
    MY_PLACES = URL_PATH_MAP.MY_ITEMS_PLACES,
    MY_PLACES_OV2 = URL_PATH_MAP.MY_ITEMS_OV2,
    MY_ROUTES = URL_PATH_MAP.MY_ITEMS_ROUTES,
    ITINERARY_DETAILS = URL_PATH_MAP.ROUTE_VIEW_BASE,
    SAVE_ROUTE = URL_PATH_MAP.ROUTE_EDIT_INFO_BASE,
    SETTINGS = URL_PATH_MAP.SETTINGS,
    LABS = URL_PATH_MAP.LABS
}

export const isFromMyItems = (foreground: ForegroundOption) =>
    foreground == ForegroundOption.MY_PLACES ||
    foreground == ForegroundOption.MY_PLACES_OV2 ||
    foreground == ForegroundOption.MY_ROUTES;

export const foregroundsChangingStartupViewport = [
    ForegroundOption.SELECTED_LOCATION,
    ForegroundOption.ROUTE_PLANNER,
    ForegroundOption.ITINERARY_DETAILS
];

export const calculateForegroundFromPath = (path: string): ForegroundOption => {
    // please note, that the planner and planner settings pages are considered as the same foreground
    if (
        isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_PLAN) ||
        isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_EDIT_LINE_BASE)
    ) {
        return ForegroundOption.ROUTE_PLANNER;
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.LOCATION_BASE)) {
        return ForegroundOption.SELECTED_LOCATION;
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.MY_ITEMS_PLACES)) {
        return ForegroundOption.MY_PLACES;
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.MY_ITEMS_OV2)) {
        return ForegroundOption.MY_PLACES_OV2;
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.MY_ITEMS_ROUTES)) {
        return ForegroundOption.MY_ROUTES;
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.MY_ITEMS_BASE)) {
        // (assuming /my-items always defaults to /my-items/places, no need for another foreground state there)
        return ForegroundOption.MY_PLACES;
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_VIEW_BASE)) {
        return ForegroundOption.ITINERARY_DETAILS;
    } else if (isBaseURLPathMatch(path, URL_PATH_MAP.ROUTE_EDIT_INFO_BASE)) {
        return ForegroundOption.SAVE_ROUTE;
    } else if (isExactURLPathMatch(path, URL_PATH_MAP.SETTINGS)) {
        return ForegroundOption.SETTINGS;
    } else if (isExactURLPathMatch(path, URL_PATH_MAP.LABS)) {
        return ForegroundOption.LABS;
    } else {
        return ForegroundOption.DEFAULT_SEARCH;
    }
};

export type RetryAction<T = unknown> = {
    retryActionName: string;
    retryActionPayload: T;
};

export type LoginAnalyticsInfo = {
    method: string;
    trigger: string; // presently only used for methods that trigger when user is not logged in, we don't care if session expired
};

const initialState = {
    foreground: null,
    previousForeground: null,
    isMenuOpen: false,
    retryAction: null as RetryAction,
    loginAnalyticsInfo: null as LoginAnalyticsInfo,
    // object containing translations downloaded via TomTom category api
    // {categoryId:{language:translation}} -> example: {"7317" : {"es" : "Museo"}, "7318" : {"es" : "Teatro"}}
};

export const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {
        updateForeground: (state, action: PayloadAction<ForegroundOption>) => {
            if (state.foreground !== action.payload) {
                // (changing foregrounds within my-items is considered like switching between similar foregrounds, so they don't overwrite the previous ones)
                if (!isFromMyItems(state.foreground) || !isFromMyItems(action.payload)) {
                    state.previousForeground = state.foreground;
                }
                state.foreground = action.payload;
            }
        },
        setIsMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.isMenuOpen = action.payload;
        },
        setRetryAction: (state, action: PayloadAction<RetryAction>) => {
            state.retryAction = action.payload;
        },
        setLoginAnalyticsInfo: (state, action: PayloadAction<LoginAnalyticsInfo>) => {
            state.loginAnalyticsInfo = action.payload;
        }
    }
});

export const { actions } = applicationSlice;

export default applicationSlice.reducer;

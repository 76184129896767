import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { bem } from "../../utils/bem";
import { qaId, QaId, QaIdModifier } from "../../utils/qa-id";
import { ButtonContainer } from "../base/Button";
import { Subtitle, Title } from "../base/Typography";
import { TooltipComingSoon } from "../base/Tooltip";
import { ListsItem } from "./Lists";
import "../../../../sass/ui-library/components/simple/CardHeading.scss";
import { IcStar24, IcFavourite24, IcPlaceholder24 } from "../../icons";

const cardHeading = bem("card-heading");

export type CardHeadingProps = QaIdModifier<HTMLAttributes<HTMLElement>> & {
    title: string;
    subtitle?: string;
    isFavorite?: boolean;
    id?: string;
    isFavouriteInvisible?: boolean;
    isFavoriteDisabled?: boolean;
    noOverflow?: boolean;
    favoriteComingSoon?: boolean;
    small?: boolean;
    browserTooltip?: boolean;
    onFavoriteClick?: () => void;
    onUnfavoriteClick?: () => void;
    onAddPlace?: () => void;
    onAddHome?: () => void;
    onAddWork?: () => void;
};

export const CardHeading = ({
    title,
    subtitle,
    isFavorite,
    id,
    isFavouriteInvisible,
    isFavoriteDisabled,
    noOverflow,
    className,
    favoriteComingSoon,
    small,
    browserTooltip,
    qaIdModifier,
    onFavoriteClick,
    onUnfavoriteClick,
    onAddPlace,
    onAddHome,
    onAddWork,
    onClick,
    ...rest
}: CardHeadingProps) => {
    const cardHeadingQa = useRef<QaId>(qaId("card-heading", qaIdModifier));
    const [isFavoriteOpen, setIsFavoriteOpen] = useState(false);
    useEffect(() => {
        setIsFavoriteOpen(false);
    }, [id]);

    return (
        <div
            className={classNames(cardHeading.block(), className, {
                [cardHeading.modifier("favorite")]: isFavorite,
                [cardHeading.modifier("favorite-coming-soon")]: favoriteComingSoon,
                [cardHeading.modifier("no-overflow")]: noOverflow,
                [cardHeading.modifier("small")]: small
            })}
            {...rest}
            {...cardHeadingQa.current.block()}
        >
            <div className={cardHeading.element("title-area")}>
                <Title
                    className={cardHeading.element("title")}
                    size={small ? "s" : null}
                    onClick={onClick}
                    {...cardHeadingQa.current.element("title")}
                >
                    {title}
                </Title>
                {(favoriteComingSoon && (
                    <TooltipComingSoon data-bs-offset="0,0" browserTooltip={browserTooltip}>
                        <ButtonContainer
                            className={cardHeading.element("favorite")}
                            {...cardHeadingQa.current.element("favorite")}
                        >
                            <IcStar24 />
                        </ButtonContainer>
                    </TooltipComingSoon>
                )) || (
                    <>
                        {!isFavouriteInvisible && (
                            <ButtonContainer
                                {...cardHeadingQa.current.element("favorite")}
                                onClick={onFavoriteClick}
                                className={classNames(cardHeading.element("favorite-btn"), { active: isFavoriteOpen })}
                            >
                                {isFavorite ? (
                                    <IcFavourite24
                                        className={classNames(cardHeading.element("favorite"), "solid", {
                                            disabled: isFavoriteDisabled
                                        })}
                                        onClick={() => {
                                            onUnfavoriteClick && !isFavoriteDisabled && onUnfavoriteClick();
                                        }}
                                        {...cardHeadingQa.current.element("filled-favorite-icon")}
                                    />
                                ) : (
                                    <IcStar24
                                        className={classNames(cardHeading.element("favorite"), {
                                            disabled: isFavoriteDisabled
                                        })}
                                        onClick={() => {
                                            !onFavoriteClick &&
                                                !isFavoriteDisabled &&
                                                setIsFavoriteOpen(!isFavoriteOpen);
                                        }}
                                        {...cardHeadingQa.current.element("empty-favorite-icon")}
                                    />
                                )}
                            </ButtonContainer>
                        )}
                        {/*temporary save options panel to be placed next to favourite icon*/}
                        <div
                            className={classNames(cardHeading.element("favorite-options"), {
                                [bem(cardHeading.element("favorite-options")).modifier("open")]: isFavoriteOpen
                            })}
                        >
                            <ListsItem title={"Save to:"} />
                            <ListsItem
                                title={"Places"}
                                onClick={() => {
                                    onAddPlace();
                                    setIsFavoriteOpen(!isFavoriteOpen);
                                }}
                                iconLeft={<IcPlaceholder24 />}
                                parentQaId={cardHeadingQa.current.withModifier("add-place")}
                                clickable
                            />
                            <ListsItem
                                title={"Pin as Home"}
                                onClick={() => {
                                    onAddHome();
                                    setIsFavoriteOpen(!isFavoriteOpen);
                                }}
                                iconLeft={<IcPlaceholder24 />}
                                parentQaId={cardHeadingQa.current.withModifier("add-home")}
                                clickable
                            />
                            <ListsItem
                                title={"Pin as Work"}
                                onClick={() => {
                                    onAddWork();
                                    setIsFavoriteOpen(!isFavoriteOpen);
                                }}
                                iconLeft={<IcPlaceholder24 />}
                                parentQaId={cardHeadingQa.current.withModifier("add-work")}
                                clickable
                            />
                        </div>
                    </>
                )}
            </div>
            <Subtitle className={cardHeading.element("sub-title")} {...cardHeadingQa.current.element("sub-title")}>
                {subtitle}
            </Subtitle>
        </div>
    );
};

import React, { HTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";

export type Themes = "base" | "light" | "light-darker" | "dark-lighter" | "dark" | "accent";

export type ThemedDivProps = PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & {
        theme?: Themes;
        level?: 2 | 3 | 4;
    }
>;

export const ThemedDiv = ({ theme = "base", level, className, children, ...rest }: ThemedDivProps) => (
    <div className={classNames(className, { ["bg-" + theme]: theme, ["lv-" + level]: level })} {...rest}>
        {children}
    </div>
);

import { RootState } from "../../../RootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { selectSelectedMapStyleID } from "../../global-configuration/selectors";

export const selectMapOptionsDisplayed = (state: RootState) => state.mapPage.mapControls.mapOptionsDisplayed.displayed;
export const selectMapStyleIDBeingEdited = (state: RootState) => state.mapPage.mapControls.mapStyleIDBeingEdited;
export const selectMapStyleEdits = (state: RootState) => state.mapPage.mapControls.mapStyleEdits;
export const selectCurrentMapStyleEdit = createSelector(
    selectMapStyleEdits,
    selectSelectedMapStyleID,
    (mapStyleEdits, selectedMapStyleID) => mapStyleEdits[selectedMapStyleID]
);
export const selectMapOptionsDisplayedFromTrafficShortcut = (state: RootState) =>
    state.mapPage.mapControls.mapOptionsDisplayed.fromTrafficShortcut;
export const selectTrafficIncidentsToggled = (state: RootState) => state.mapPage.mapControls.trafficIncidentsToggled;
export const selectTrafficFlowToggled = (state: RootState) => state.mapPage.mapControls.trafficFlowToggled;
export const selectAnyTrafficToggled = createSelector(
    selectTrafficIncidentsToggled,
    selectTrafficFlowToggled,
    (incidentsToggled, flowToggled) => incidentsToggled || flowToggled
);

export const selectScenicSegmentsToggled = (state: RootState) => state.mapPage.mapControls.scenicSegmentsToggled;
export const selectMapOptionsDisplayedFromRoadTripShortcut = (state: RootState) =>
    state.mapPage.mapControls.mapOptionsDisplayed.fromRoadTripShortcut;
export const selectRoadTripsToggled = (state: RootState) => state.mapPage.mapControls.roadTripsToggled;
export const selectRiderRoutesToggled = (state: RootState) => state.mapPage.mapControls.riderRoutesToggled;
export const selectIsMyPlacesToggled = (state: RootState) => state.mapPage.mapControls.isMyPlacesToggled;
export const selectIsSelectAllPOIsToggled = (state: RootState) => state.mapPage.mapControls.isSelectAllPOIsToggled;
export const selectIsOtherPOIsToggled = (state: RootState) => state.mapPage.mapControls.isOtherPOIsToggled;
export const selectHiddenPOIsCategoryGroupIconIDs = (state: RootState) =>
    state.mapPage.mapControls.hiddenPOIsCategoryGroupIconIDs;
export const selectIsPOILayerChanged = createSelector(
    selectIsSelectAllPOIsToggled,
    selectIsOtherPOIsToggled,
    selectHiddenPOIsCategoryGroupIconIDs,
    (selectAllPOIs, otherPOIs, hiddenCat) => !selectAllPOIs || !otherPOIs || hiddenCat.length > 0
);

import React, { HTMLAttributes, ReactNode, useEffect, useRef } from "react";
import classNames from "classnames";
import { bem } from "../../utils/bem";
import { Accent, Body, Title } from "../base/Typography";
import "../../../../sass/ui-library/components/simple/Notifications.scss";
import { QaIdModifier } from "../../utils/qa-id";
import { PageBannerMessageVariants } from "../Variants";
import { Il404, IlConnectionFailure, IlWhereAreYou } from "../../illustrations";
import { ButtonClose, ButtonPrimary } from "../base/Button";
import Toast from "bootstrap/js/dist/toast";
import useQaId from "../../hooks/useQaId";

const notificationsBem = bem("notifications");
const notificationBem = bem("notification");

export type InPanelMessageProps = QaIdModifier<HTMLAttributes<HTMLElement>> & {
    image: ReactNode;
    message: string;
};

export const InPanelMessage = ({ image, message, className, qaIdModifier, ...rest }: InPanelMessageProps) => {
    const notificationsQa = useQaId("notification-in-panel", null, qaIdModifier);
    return (
        <div
            {...rest}
            role="alert"
            {...notificationsQa.block()}
            className={classNames(notificationBem.block(), notificationBem.modifier("in-panel"), className)}
        >
            <div className={notificationBem.element("image")} {...notificationsQa.element("image")}>
                {image}
            </div>
            <Accent className={notificationBem.element("message")} {...notificationsQa.element("message")} size="s">
                {message}
            </Accent>
        </div>
    );
};

export type PageBannerMessageProps = QaIdModifier<HTMLAttributes<HTMLElement>> & {
    image: ReactNode;
    heading: ReactNode;
    message: ReactNode;
    onCloseClicked?: (e) => void;
    startShown?: boolean;
    autoHide?: boolean;
    onShow?: () => void;
    onShown?: () => void;
    onHide?: () => void;
    onHidden?: () => void;
    onBannerClicked?: () => void;
    hideOnClick?: boolean;
};

export const PageBannerMessage = ({
    image,
    heading,
    message,
    className,
    qaIdModifier,
    onCloseClicked,
    startShown = true,
    autoHide = true,
    onShow,
    onShown,
    onHide,
    onHidden,
    hideOnClick,
    onBannerClicked,
    ...rest
}: PageBannerMessageProps) => {
    const notificationsQa = useQaId("notification-page-banner", null, qaIdModifier);
    const notificationRef = useRef();

    useEffect(() => {
        if (notificationRef && notificationRef.current) {
            const notificationEl: HTMLDivElement = notificationRef.current;
            const notification = Toast.getOrCreateInstance(notificationEl);
            if (notification) {
                if (startShown) {
                    notification.show();
                }
                notificationEl.addEventListener("hidden.bs.toast", () => onHidden?.());
                notificationEl.addEventListener("hide.bs.toast", () => onHide?.());
                notificationEl.addEventListener("show.bs.toast", () => onShow?.());
                notificationEl.addEventListener("shown.bs.toast", () => onShown?.());
            }

            return function cleanup() {
                notification.dispose();
            };
        }
    }, [notificationRef, startShown]);

    const hideNotification = () => {
        if (notificationRef && notificationRef.current) {
            const notificationEl: HTMLDivElement = notificationRef.current;
            const notification = Toast.getOrCreateInstance(notificationEl);
            if (notification) {
                notification.hide();
            }
        }
    };

    return (
        <div
            {...rest}
            ref={notificationRef}
            role="alert"
            {...notificationsQa.block()}
            className={classNames(
                notificationBem.block(),
                notificationBem.modifier("page-banner"),
                "toast",
                className,
                { [notificationBem.modifier("clickable")]: hideOnClick }
            )}
            data-bs-autohide={autoHide}
            data-bs-animation={true}
            onClick={() => {
                if (hideOnClick) {
                    hideNotification();
                }
                onBannerClicked?.();
            }}
        >
            <div className={notificationBem.element("image")} {...notificationsQa.element("image")}>
                {image}
            </div>
            <div className={notificationBem.element("message")}>
                <Title
                    size={"s"}
                    className={notificationBem.element("heading")}
                    {...notificationsQa.element("heading")}
                >
                    {heading}
                </Title>
                <Body className={notificationBem.element("text")} {...notificationsQa.element("message")}>
                    {message}
                </Body>
            </div>
            <ButtonClose
                className={notificationBem.element("close")}
                // manually hiding rather than using data-bs-toggle as it is triggering 'hide' multiple times on click.
                // data-bs-dismiss={"toast"}
                onClick={(e) => {
                    e.stopPropagation();
                    hideNotification();
                    onCloseClicked?.(e);
                }}
                {...notificationsQa.element("close")}
            />
        </div>
    );
};

export type PageBannerMessageTypeProps = Omit<PageBannerMessageProps, "image" | "heading" | "message"> &
    PageBannerMessageVariants;

export const PageBannerMessageType = ({ type, ...rest }: PageBannerMessageTypeProps) => {
    switch (type) {
        case "connection-failure":
            return (
                <PageBannerMessage
                    image={<IlConnectionFailure />}
                    heading={"Sorry. There seems to be a connection failure."}
                    message={"We will help you find your way very soon!"}
                    {...rest}
                />
            );
        case "unknown-location":
            return (
                <PageBannerMessage
                    image={<IlWhereAreYou />}
                    heading={"We couldn’t find you"}
                    message={"To use your current location make sure to enable access from your browser"}
                    {...rest}
                />
            );
    }
};

export type PageBannerMessagePositions = "top center" | "top right" | "bottom right" | "bottom center";

export type PageBannerMessageContainerProps = QaIdModifier<HTMLAttributes<HTMLElement>> & {
    position?: PageBannerMessagePositions;
};

export const PageBannerMessageContainer = ({
    className,
    position = "top center",
    qaIdModifier,
    ...rest
}: PageBannerMessageContainerProps) => {
    const notificationsQa = useQaId("notification-page-banner-container", null, qaIdModifier);
    return (
        <div
            {...rest}
            className={classNames(notificationsBem.block(), "toast-container position-absolute", className, {
                "top-0 start-50 translate-middle-x": position == "top center",
                "top-0 end-0": position == "top right",
                "bottom-0 start-50 translate-middle-x": position == "bottom center",
                "bottom-0 end-0": position == "bottom right"
            })}
            {...notificationsQa.block()}
        />
    );
};

export type FullPageMessageProps = QaIdModifier<HTMLAttributes<HTMLElement>> & {
    image: ReactNode;
    heading: string;
    message: string;
    buttonText: string;
    onCloseClicked: () => void;
};

export const FullPageMessage = ({
    image,
    heading,
    message,
    buttonText,
    className,
    qaIdModifier,
    onCloseClicked,
    ...rest
}: FullPageMessageProps) => {
    const notificationsQa = useQaId("notification-full-page", null, qaIdModifier);

    return (
        <div
            {...rest}
            role="alert"
            {...notificationsQa.block()}
            className={classNames(
                notificationBem.block(),
                notificationBem.modifier("full-page"),
                "position-absolute top-50 start-50 translate-middle",
                className
            )}
        >
            <div className={notificationBem.element("image")} {...notificationsQa.element("image")}>
                {image}
            </div>
            <div className={notificationBem.element("message")}>
                <Title className={notificationBem.element("heading")} {...notificationsQa.element("heading")}>
                    {heading}
                </Title>
                <Body className={notificationBem.element("text")} {...notificationsQa.element("message")}>
                    {message}
                </Body>
            </div>
            <ButtonPrimary
                text={buttonText}
                className={notificationBem.element("close")}
                onClick={onCloseClicked}
                {...notificationsQa.element("primary-action")}
            />
        </div>
    );
};

export type FullPageMessage404Props = {
    onCloseClicked: () => void;
};
export const FullPageMessage404 = ({ onCloseClicked }: FullPageMessage404Props) => (
    <FullPageMessage
        image={<Il404 />}
        heading={"Oops! We’ve searched everywhere but can’t seem to find the page you’re looking for"}
        message={"Why don’t you try again?"}
        buttonText={"Go back"}
        onCloseClicked={onCloseClicked}
    />
);

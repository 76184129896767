import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Itinerary, LocationInfo, UserMapLocationType } from "@anw/gor-sdk";
import { PageBannerMessageTypeVariant } from "../../../ui-library";
import isEqual from "lodash/isEqual";
import { TTMLocationInfo } from "../../../utils/locationTypes";

export type MyItemsPlacesNotificationTypes = "place-add" | "place-update" | "place-remove";
export type MyItemsRoutesNotificationTypes =
    | "route-add"
    | "route-update"
    | "route-removed"
    | "route-published"
    | "route-un-published"
    | "route-synced"
    | "route-un-synced";
export type ActiveDestinationNotificationTypes = "activeDestination-add" | "activeDestination-remove";
export type PreDefinedNotificationTypes = PageBannerMessageTypeVariant;
export type ReachableRangeNotificationTypes = "detailed-range-uncalculable";
export type GenericNotificationTypes = "generic-error" | "generic-message";

export type NotificationTypes =
    | MyItemsPlacesNotificationTypes
    | MyItemsRoutesNotificationTypes
    | GenericNotificationTypes
    | ActiveDestinationNotificationTypes
    | PreDefinedNotificationTypes
    | ReachableRangeNotificationTypes;

export interface CommonNotification {
    notificationType: NotificationTypes;
    hidden?: boolean;
    timestamp?: number;
}

export interface MyItemsPlacesNotification extends CommonNotification {
    notificationType: MyItemsPlacesNotificationTypes;
    locationType?: UserMapLocationType;
    locationInfo?: LocationInfo;
}

export interface MyItemsRoutesNotification extends CommonNotification {
    notificationType: MyItemsRoutesNotificationTypes;
    itinerary?: Itinerary;
}

export interface ActiveDestinationNotification extends CommonNotification {
    notificationType: ActiveDestinationNotificationTypes;
    locationInfo: TTMLocationInfo | LocationInfo | LocationInfo[];
}

export interface GenericNotification extends CommonNotification {
    notificationType: GenericNotificationTypes;
    heading: string;
    message: string;
}

export interface PreDefinedNotification extends CommonNotification {
    notificationType: PreDefinedNotificationTypes;
}

export interface ReachableRangeNotification extends CommonNotification {
    notificationType: ReachableRangeNotificationTypes;
}

const initialState = {
    notifications: [] as CommonNotification[]
};

const addNotification = <T extends CommonNotification>(state, action: PayloadAction<T>) => {
    let payload = action.payload;

    if (payload) {
        if (!payload.timestamp) {
            // original action object is not extensible
            payload = { ...payload, timestamp: Date.now() };
        }
        state.notifications.push(payload);
    }
};

const removeNotification = <T = CommonNotification>(state, action: PayloadAction<T>) => {
    if (action.payload) {
        const indexOf = state.notifications.findIndex((notification) => isEqual(notification, action.payload));
        if (indexOf != -1) {
            state.notifications[indexOf].hidden = true;
        }
    }
};

export const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        addPreDefinedNotification: (state, action: PayloadAction<PreDefinedNotification>) => {
            addNotification(state, action);
        },
        removePreDefinedNotification: (state, action: PayloadAction<PreDefinedNotification>) => {
            removeNotification(state, action);
        },
        addGenericNotification: (state, action: PayloadAction<GenericNotification>) => {
            addNotification(state, action);
        },
        removeGenericNotification: (state, action: PayloadAction<GenericNotification>) => {
            removeNotification(state, action);
        },
        addMyItemsPlacesNotification: (state, action: PayloadAction<MyItemsPlacesNotification>) => {
            addNotification(state, action);
        },
        removeMyItemsPlacesNotification: (state, action: PayloadAction<MyItemsPlacesNotification>) => {
            removeNotification(state, action);
        },
        addMyItemsRoutesNotification: (state, action: PayloadAction<MyItemsRoutesNotification>) => {
            addNotification(state, action);
        },
        removeMyItemsRoutesNotification: (state, action: PayloadAction<MyItemsRoutesNotification>) => {
            removeNotification(state, action);
        },
        addActiveDestinationNotification: (state, action: PayloadAction<ActiveDestinationNotification>) => {
            addNotification(state, action);
        },
        removeActiveDestinationNotification: (state, action: PayloadAction<ActiveDestinationNotification>) => {
            removeNotification(state, action);
        },
        addReachableRangeNotification: (state, action: PayloadAction<ReachableRangeNotification>) => {
            addNotification(state, action);
        },
        removeReachableRangeNotification: (state, action: PayloadAction<ReachableRangeNotification>) => {
            removeNotification(state, action);
        }
    }
});

export const { actions } = notificationSlice;

export default notificationSlice.reducer;

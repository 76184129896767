import { Heading, TTMLocation, TTMUserMapLocation } from "../../../../utils/locationTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type LocationSelectionOrigin = "MAP" | "HTML_UI";

const initialState = {
    selectedLocation: null as TTMLocation,
    selectedFromSavedLocations: null as TTMUserMapLocation,
    previousSelectedLocation: null as TTMLocation,
    selectedFrom: null as LocationSelectionOrigin,
    hasBoundaryError: false,
    previousHeadingInfo: undefined as Heading
};

export const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        setSelectedLocation: (
            state,
            action: PayloadAction<{ location: TTMLocation; selectedFrom: LocationSelectionOrigin }>
        ) => {
            state.previousSelectedLocation = state.selectedLocation;
            state.selectedLocation = action.payload?.location;
            state.selectedFrom = action.payload?.selectedFrom;
            state.selectedFromSavedLocations = null;
            // reset the boundary error state
            state.hasBoundaryError = false;
        },
        setSelectedFromSavedLocations: (state, action: PayloadAction<TTMUserMapLocation>) => {
            state.selectedFromSavedLocations = action.payload;
        },
        setHasBoundaryError: (state, action: PayloadAction<boolean>) => {
            state.hasBoundaryError = action.payload;
        },
        setPreviousHeadingInfo: (state, action: PayloadAction<Heading>) => {
            state.previousHeadingInfo = action.payload;
        }
    }
});

export const { actions } = locationSlice;

export default locationSlice.reducer;

import queryString from "query-string";

import { RootState } from "../../RootReducer";
import { toFixedExtra, toFixedLngLat } from "../../../utils/numbers";

const isZoom = (urlPart: string) => urlPart.slice(urlPart.length - 1) === "z";
const isBearing = (urlPart: string) => urlPart.slice(urlPart.length - 1) === "b";
const isPitch = (urlPart: string) => urlPart.slice(urlPart.length - 1) === "p";

const fromLocationToPositionParams = (position: string) => {
    const info = position ? position.split(",") : [];
    const zoom = info.find(isZoom);
    const bearing = info.find(isBearing);
    const pitch = info.find(isPitch);

    return {
        lat: info[0] && toFixedLngLat(Number(info[0])),
        lng: info[1] && toFixedLngLat(Number(info[1])),
        ...(zoom && { zoom: toFixedExtra(Number(zoom.slice(0, zoom.length - 1))) }),
        ...(bearing && { bearing: toFixedExtra(Number(bearing.slice(0, bearing.length - 1))) }),
        ...(pitch && { pitch: toFixedExtra(Number(pitch.slice(0, pitch.length - 1))) })
    };
};

export const retrieveQueryFromSearch = (state: RootState): string =>
    (queryString.parse(state.router.location.search)?.q as string) ?? "";

export const retrievePositionFromURL = (state: RootState) =>
    fromLocationToPositionParams((queryString.parse(state.router.location.search)?.p as string) ?? "");

export const selectStartedWithPosition = (state: RootState) => state.navigation.startedWithPosition;

import React, { HTMLAttributes, useRef } from "react";
import classNames from "classnames";
import "../../../../sass/ui-library/components/simple/PriceIndicator.scss";
import { IcEuro116 } from "../../icons";
import bem from "../../utils/bem";
import { qaId, QaId, QaIdModifier } from "../../utils/qa-id";

const priceIndicator = bem("price-indicator");

export type PriceIndicatorProps = QaIdModifier<HTMLAttributes<HTMLElement>> & {
    priceValue?: number;
};

export const PriceIndicator = ({ priceValue, className, qaIdModifier, ...rest }: PriceIndicatorProps) => {
    const priceIndicatorQa = useRef<QaId>(qaId("price-indicator", qaIdModifier));
    return (
        <div className={classNames(priceIndicator.block(), className)} {...rest} {...priceIndicatorQa.current.block()}>
            {[...Array(4).keys()].map((e) => (
                <IcEuro116
                    key={e}
                    className={classNames(priceIndicator.element("unit"), {
                        [priceIndicator.modifier("active")]: e < priceValue
                    })}
                />
            ))}
        </div>
    );
};

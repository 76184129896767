import React, { HTMLAttributes, InputHTMLAttributes, PropsWithChildren, ReactElement, ReactNode } from "react";
import classNames from "classnames";
import { IcBlueCircle, IcWhiteDot } from "../../icons";
import "../../../../sass/ui-library/components/base/Toggles.scss";
import { bem } from "../../utils/bem";
import { RadioGroup, Radio as RadioGroupRadio } from "react-radio-group";
import { TextWithIcon } from "./TextWithIcon";
import { Body } from "./Typography";
import { ParentQaId, QaIdModifier } from "../../utils/qa-id";
import useQaId from "../../hooks/useQaId";

const toggle = bem("toggle");
const iconToggle = bem("icon-toggle");
const toggleGroup = bem("toggle-group");

export type ToggleBaseProps = QaIdModifier<Omit<InputHTMLAttributes<HTMLInputElement>, "type">> &
    ParentQaId & {
        text?: string;
        subText?: string;
        busy?: boolean;
    };

type ToggleTypes = {
    type: "checkbox" | "radio" | "switch";
};

type ToggleProps = ToggleBaseProps & ToggleTypes;

const Toggle: (props: ToggleProps) => ReactElement = (props) => {
    const {
        text,
        subText,
        type,
        name,
        className,
        checked,
        value = "",
        busy,
        parentQaId,
        blockElement,
        qaIdModifier,
        ...inputProps
    } = props;
    const toggleQa = useQaId("toggle", parentQaId, qaIdModifier);

    return (
        <div className={classNames("control", className)} {...toggleQa.block(blockElement || (parentQaId && "toggle"))}>
            <label
                className={classNames(toggle.block(), toggle.modifier(type), {
                    [toggle.modifier("busy")]: busy,
                    [toggle.modifier("with-sub-text")]: subText
                })}
                aria-disabled={props.disabled}
                {...toggleQa.element("label")}
                {...{ disabled: props.disabled }}
            >
                {type == "radio" ? (
                    <RadioGroupRadio
                        className={toggle.element("input")}
                        value={value}
                        autoComplete="off"
                        {...toggleQa.element("input-radio")}
                        {...inputProps}
                    />
                ) : (
                    <input
                        {...toggleQa.element("input-check")}
                        {...inputProps}
                        type="checkbox"
                        name={name}
                        value={value}
                        className={toggle.element("input")}
                        checked={checked}
                    />
                )}
                <span className={toggle.element("checkmark")} />
                {busy && (
                    <span className={toggle.element("busy")} {...toggleQa.element("busy")}>
                        <IcBlueCircle />
                        <IcWhiteDot className={toggle.element("busy-dot")} />
                    </span>
                )}
                <Body className={toggle.element("body")}>
                    {text && (
                        <div className={toggle.element("text")} {...toggleQa.element("text")}>
                            {text}
                        </div>
                    )}
                    {subText && (
                        <div className={toggle.element("sub-text")} {...toggleQa.element("sub-text")}>
                            {subText}
                        </div>
                    )}
                </Body>
            </label>
        </div>
    );
};

export const Checkbox = (props: ToggleBaseProps) => <Toggle {...props} type="checkbox" />;

export const Radio = (props: ToggleBaseProps) => <Toggle {...props} type="radio" />;

export const Switch = (props: ToggleBaseProps) => <Toggle {...props} type="switch" />;

// -- ICON -----------
type BaseProps = ParentQaId & {
    icon: ReactNode;
    text?: string;
};

export type RadioAsIconProps = BaseProps & InputHTMLAttributes<HTMLInputElement>;

export const RadioIcon: (props: RadioAsIconProps) => ReactElement = (props) => {
    const { className, icon, value = "", title, parentQaId, blockElement, ...rest } = props;
    const toggleQa = useQaId("toggle", parentQaId);
    return (
        <label
            {...toggleQa.block(blockElement || (parentQaId && "toggle"))}
            className={classNames(iconToggle.block(), iconToggle.modifier("radio"), className)}
            title={title}
        >
            <RadioGroupRadio
                {...toggleQa.element("radio")}
                className={iconToggle.element("input")}
                value={value}
                autoComplete="off"
                {...rest}
            />
            <span className={classNames(iconToggle.element("icon"))} {...toggleQa.element("icon")}>
                {icon}
            </span>
        </label>
    );
};

type CheckboxIconProps = BaseProps &
    InputHTMLAttributes<HTMLInputElement> & { direction: "vertical" | "horizontal"; secondary?: boolean };

export const CheckboxTextWithIcon: (props: CheckboxIconProps) => ReactElement = (props) => {
    const { className, icon, direction, secondary, parentQaId, blockElement, ...rest } = props;
    const toggleQa = useQaId("toggle", parentQaId);
    return (
        <label
            {...toggleQa.block(blockElement || (parentQaId && "toggle"))}
            className={classNames(iconToggle.block(), iconToggle.modifier("check"), className)}
        >
            <input
                type="checkbox"
                className={classNames(iconToggle.element("input"))}
                autoComplete="off"
                {...rest}
                {...toggleQa.element("check")}
            />
            <TextWithIcon
                icon={icon}
                text={props.text}
                direction={direction}
                disabled={props.disabled}
                checked={props.checked}
                secondary={secondary}
                clickable
                parentQaId={toggleQa.qaId()}
            />
        </label>
    );
};
// ----------------

export type ToggleGroupProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

export const ToggleGroupRadio = (props: RadioGroup.RadioGroupProps) => {
    const { className, ...rest } = props;
    return (
        <RadioGroup className={classNames(toggleGroup.block(), toggleGroup.modifier("radio"), className)} {...rest} />
    );
};

export const ToggleGroup = (props: ToggleGroupProps) => {
    const { className, ...rest } = props;
    return <div className={classNames(toggleGroup.block(), className)} {...rest} />;
};

import { createSelector } from "@reduxjs/toolkit";
import compact from "lodash/compact";

import { RootState } from "../../../RootReducer";
import * as listsUtils from "../../../../utils/lists";
import { DepartArriveMode } from "../../../../components/planner/PlannerDepartArrivePicker";
import {
    hasNonWaypointGeoInputs,
    isPlannerInputWaypoint,
    isRoundTrip,
    waypointIndexMappings,
    waypointsOnlyWithPlaceholdersAndChargingStops
} from "../../../../utils/waypoint";
import { comparePlanningRequests } from "../../../../utils/route";

export const selectCurrentPlannerParams = (state: RootState) => state.mapPage.planner.currentPlannerParams;
export const selectVehicleParameters = (state: RootState) =>
    state.mapPage.planner.currentPlannerParams.vehicleParameters;
export const selectWaypoints = createSelector(selectCurrentPlannerParams, (params) =>
    waypointsOnlyWithPlaceholdersAndChargingStops(params.sortedGeoInputs)
);
/**
 * Planner input waypoints are the ones that are represented in the planner itself (typically the hard waypoints - start, stops, destination)
 */
export const selectPlannerInputWaypoints = createSelector(selectWaypoints, (waypoints) =>
    waypoints.filter((waypoint) => isPlannerInputWaypoint(waypoint))
);
export const selectWaypointIndexMappings = createSelector(selectWaypoints, (waypoints) =>
    waypointIndexMappings(waypoints)
);
export const selectFilledWaypoints = createSelector(selectWaypoints, (waypoints) => compact(waypoints));
export const selectDepartArriveMode = createSelector(selectCurrentPlannerParams, (params) => {
    const arriveOrNowText = params.arriveTime ? "ARRIVE" : "NOW";
    return (params.departTime ? "DEPART" : arriveOrNowText) as DepartArriveMode;
});
export const selectDepartArriveTime = createSelector(
    selectCurrentPlannerParams,
    (params) => params.departTime || params.arriveTime
);
export const selectPlannedRouteInformation = (state: RootState) => state.mapPage.planner.plannedRouteInformation;
export const selectRouteCannotBePlannedError = (state: RootState) => state.mapPage.planner.errors.routeCannotBePlanned;

/**
 * Return true if route can be planned with available waypoints.
 * It implies we have at least 2 waypoints, and all waypoints are filled in, or any section inputs.
 */
export const canRouteBePlanned = createSelector(
    selectCurrentPlannerParams,
    selectFilledWaypoints,
    (planningParams, filledWaypoints) =>
        filledWaypoints.length >= 2 ||
        hasNonWaypointGeoInputs(planningParams.sortedGeoInputs) ||
        hasNonWaypointGeoInputs(planningParams.unsortedGeoInputs)
);

/**
 * Check if current stored route options in reducer has already planned.
 * This function should be changed to account for important differences, but not everything requires to be checked.
 */
export const isCurrentRouteAlreadyPlanned = createSelector(
    [selectCurrentPlannerParams, selectPlannedRouteInformation],
    (currentParams, alreadyPlannedInfo) => comparePlanningRequests(currentParams, alreadyPlannedInfo.effectiveRequest)
);

export const selectIsRoundTrip = createSelector(selectFilledWaypoints, (waypoints) => isRoundTrip(waypoints));
export const selectPlannerSearchQuery = (state: RootState) => state.mapPage.planner.search.query;
export const selectPlannerSearchResults = (state: RootState) => state.mapPage.planner.search.results;
export const selectPlannerMyPlacesSearchResults = (state: RootState) =>
    state.mapPage.planner.search.myPlacesSearchResults;
export const selectPlannerHistorySearchResults = (state: RootState) =>
    state.mapPage.planner.search.historySearchResults;
export const selectRouteSelection = (state: RootState) => state.mapPage.planner.routeSelection;
export const selectRoutePathSelection = (state: RootState) => state.mapPage.planner.routePathSelection;
export const selectAvoidableOptions = (state: RootState) =>
    listsUtils.ensureList(state.mapPage.planner.currentPlannerParams.avoidOptions);
export const selectCostModel = (state: RootState) => state.mapPage.planner.currentPlannerParams.costModel;
export const selectThrillingPreferences = (state: RootState) =>
    state.mapPage.planner.currentPlannerParams.thrillingPreferences;

export const selectTravelMode = (state: RootState) => state.mapPage.planner.currentPlannerParams.travelMode;

export const selectPlannerMode = (state: RootState) => state.mapPage.planner.plannerMode;
export const selectRouteCalculation = (state: RootState) => state.mapPage.planner.routeCalculation;

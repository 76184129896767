import queryString from "query-string";
import { RootState } from "../../RootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { defaultGlobalConfiguration, ServiceSource, ServiceUrls } from "./reducers";

export const selectApiKey = (state: RootState): string =>
    (queryString.parse(state.router.location.search).apiKey as string) || state.globalConfiguration.apiKey;

export const selectServiceSource = (state: RootState): ServiceSource =>
    state.globalConfiguration.featureConfigs.serviceSource;
export const selectExtraPlanningTrafficInfo = (state: RootState): boolean =>
    state.globalConfiguration.featureConfigs.extraPlanningTrafficInfo;
export const selectDetectCurves = (state: RootState): boolean => state.globalConfiguration.featureConfigs.detectCurves;

export const selectAnalyticsEnvKey = (state: RootState): string => state.globalConfiguration.analytics?.envKey ?? "";

const selectMapStyles = (state: RootState) => state.globalConfiguration.map.styles;
export const selectMapAttribution = (state: RootState) => state.globalConfiguration.map.attribution;
export const selectExperimentalMapStyles = (state: RootState) =>
    state.globalConfiguration.map.styles.filter((style) => style.experimental);
export const selectMapStylesByServiceSource = createSelector(
    selectMapStyles,
    selectServiceSource,
    (mapStyles, serviceSource) =>
        serviceSource == "ORBIS"
            ? mapStyles.filter((style) => style.orbis)
            : mapStyles.filter((style) => !style.orbis && !style.experimental)
);

export const selectFeatureConfigs = (state: RootState) => state.globalConfiguration.featureConfigs;
export const selectExternalLinks = (state: RootState) => state.globalConfiguration.externalLinks;
export const selectLegalLinks = (state: RootState) => state.globalConfiguration.legalLinks;
export const selectRouteSyncSupport = (state: RootState) => state.globalConfiguration.routeSync;
export const selectSelectedMapStyleID = createSelector(
    selectFeatureConfigs,
    (featureConfigs) => featureConfigs?.selectedMapStyle?.id
);
export const selectDefaultMapStyleID = createSelector(
    selectFeatureConfigs,
    (featureConfigs) => featureConfigs?.defaultMapStyleID
);
export const selectSelectedMapStyle = createSelector(
    selectMapStyles,
    selectSelectedMapStyleID,
    selectDefaultMapStyleID,
    (mapStyles, selectSelectedMapStyleID, defaultMapStyleID) => {
        let style = mapStyles.find((style) => style.id == (selectSelectedMapStyleID || defaultMapStyleID));

        // if the selected map style is not found, use the default map style
        if (!style) {
            style = mapStyles.find((style) => style.id == "DEFAULT");
        }

        return style;
    }
);

export const selectSelectedMapStyleUrl = createSelector(
    selectSelectedMapStyle,
    selectServiceSource,
    selectApiKey,
    (mapStyle, serviceSource, apiKey) => {
        const url = new URL(serviceSource && serviceSource == "KOREA" ? mapStyle.styleKr : mapStyle.style);
        url.searchParams.set("key", apiKey);
        return url.toString();
    }
);

export const selectAuthConfig = (state: RootState) => state.globalConfiguration.authentication;

export const selectServiceUrls = (state: RootState): ServiceUrls =>
    state.globalConfiguration.search.endpointServiceUrls?.[state.globalConfiguration.featureConfigs.serviceSource] ||
    defaultGlobalConfiguration.search.endpointServiceUrls.GLOBAL;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    authenticated: false
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
        }
    }
});

export const { actions } = authenticationSlice;

export default authenticationSlice.reducer;

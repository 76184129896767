import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { RootState } from "../../../RootReducer";
import { selectServiceSource, selectMapAttribution, selectApiKey } from "../../global-configuration/selectors";

export const fetchCopyrightAction = createAsyncThunk<string, void, { state: RootState }>(
    "map/prepareCopyrightAction",
    async (props, thunkApi) => {
        const state = thunkApi.getState();
        const serviceSource = selectServiceSource(state);
        const mapAttribution = selectMapAttribution(state);

        const baseURL = mapAttribution?.[serviceSource]?.url ?? mapAttribution["OTHER"].url;
        const urlWithKey = new URL(baseURL);
        urlWithKey.searchParams.append("key", selectApiKey(state));
        const {
            data: { copyrightsCaption }
        } = await axios.get<{ copyrightsCaption: string; formatVersion: string }>(urlWithKey.href);

        return copyrightsCaption;
    }
);

import React, { HTMLAttributes, PropsWithChildren, ReactNode, useEffect } from "react";
import BsTooltip from "bootstrap/js/dist/tooltip";
import { IcPlaceholder16 } from "../../icons";
import classNames from "classnames";

import "../../../../sass/ui-library/components/base/Tooltip.scss";
import bem from "../../utils/bem";
import { TooltipVariants } from "../Variants";

const tooltipBem = bem("tooltip");

export type TooltipProps = PropsWithChildren<
    HTMLAttributes<HTMLElement> &
        Partial<TooltipVariants> & {
            message: string;
            icon?: ReactNode;
            startShown?: boolean;
            browserTooltip?: boolean;
            showDelay?: number;
            hideDelay?: number;
        }
>;

export const Tooltip = ({
    message,
    placement,
    type,
    icon,
    children,
    className,
    startShown,
    browserTooltip,
    showDelay = 0,
    hideDelay = 0,
    ...rest
}: TooltipProps) => {
    const tooltipRef = React.useRef<HTMLDivElement>();
    useEffect(() => {
        if (!browserTooltip) {
            const tooltip = BsTooltip.getOrCreateInstance(tooltipRef.current, {
                delay: {
                    show: showDelay,
                    hide: hideDelay
                }
            });
            if (startShown) {
                tooltip.show();
            }

            return function cleanup() {
                tooltip.dispose();
            };
        }
    }, [tooltipRef, startShown, browserTooltip, message]);

    return (
        <div
            data-bs-offset="0,4"
            {...rest}
            className={classNames("tooltip__trigger", className, {
                [tooltipBem.modifier("icon")]: !children
            })}
            ref={tooltipRef}
            data-bs-toggle="tooltip"
            data-bs-placement={placement || "right"}
            title={message}
            data-bs-animation={false}
            data-bs-custom-class={classNames(tooltipBem.modifier(type))}
        >
            {children || icon || <IcPlaceholder16 />}
        </div>
    );
};

export const TooltipComingSoon = (props: Partial<TooltipProps>) => (
    <Tooltip message="Coming soon" {...props} type="coming-soon" />
);

// Retry Async Thunk logic
import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { RootState } from "./RootReducer";
import { actions as applicationActions } from "./tree/application/reducers";
import { selectAuthenticated } from "./tree/authentication/selectors";
import { actions as notificationActions } from "./tree/notification/reducers";

export enum AuthCheck {
    /**
     * The thunk action execution will be attempted first, and if that throws a 401, the login-retrial will kick in.
     */
    LAZY,
    /**
     * A basic state auth check will be done before the thunk action execution is attempted.
     * If not authenticated, login retrial mechanism will kick in.
     * If authenticated upfront, the thunk action is executed same as with lazy auth check mode.
     */
    EAGER
}

// using map because of the reported error in case the async thunk function is put into state (not serializable)
export const retryAsyncThunks: Map<string, AsyncThunk<any, any, any>> = new Map([]);

// taken from redux types
type ThunkApiConfig = {
    state?: unknown;
    dispatch?: Dispatch;
    extra?: unknown;
    rejectValue?: unknown;
    serializedErrorType?: unknown;
    pendingMeta?: unknown;
    fulfilledMeta?: unknown;
    rejectedMeta?: unknown;
};

type Conf = ThunkApiConfig & {
    state: RootState;
};

export const createRetryAsyncThunk = <Returned, ThunkArg>(
    type: string,
    authCheck: AuthCheck,
    thunk: AsyncThunkPayloadCreator<Returned, ThunkArg, Conf>,
    final?: AsyncThunkPayloadCreator<Returned, ThunkArg, Conf>
): AsyncThunk<Returned, ThunkArg, Conf> => {
    const asyncThunk = createAsyncThunk<Returned, ThunkArg, Conf>(type, async (data, thunkApi): Promise<any> => {
        const setRetryAction = () => {
            if (!retryAsyncThunks.has(type)) {
                retryAsyncThunks.set(type, asyncThunk);
            }
            thunkApi.dispatch(
                applicationActions.setRetryAction({
                    retryActionName: type,
                    retryActionPayload: data
                })
            );
        };

        const state = thunkApi.getState();
        if (authCheck == AuthCheck.EAGER && !selectAuthenticated(state)) {
            setRetryAction();
            return thunkApi.rejectWithValue(null);
        }

        try {
            // do some stuff here that happens on every action
            return await thunk(data, thunkApi);
        } catch (err) {
            if (err.code === 401) {
                setRetryAction();
            } else if (err.code === 503) {
                if (state.mapPage.map.mapReady) {
                    // show notification for connection issue
                    thunkApi.dispatch(
                        notificationActions.addPreDefinedNotification({
                            notificationType: "connection-failure"
                        })
                    );
                }
            }
            // do some stuff here that happens on every error
            return thunkApi.rejectWithValue(null);
        } finally {
            // some extra logic if needed
            if (final) {
                await final(data, thunkApi);
            }
        }
    });
    return asyncThunk;
};

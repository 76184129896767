import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    plannerCollapsed: true
};

export const header = createSlice({
    name: "header",
    initialState,
    reducers: {
        setPlannerCollapsed: (state, action: PayloadAction<boolean>) => {
            state.plannerCollapsed = action.payload;
        }
    }
});

export const headerActions = header.actions;

export default header.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../RootReducer";
import isEqual from "lodash/isEqual";
import { actions as mapControlsActions } from "./reducers";
import { logEventWithActiveMode } from "../../application/thunks";
import {
    actions as globalConfigActions,
    ServiceSource,
    MapStyleGlobalConfig,
    MapStyleConfigBase,
    CUSTOM_URL_STYLE_ID
} from "../../global-configuration/reducers";
import { selectMapReady } from "../map/selectors";
import { selectFeatureConfigs, selectSelectedMapStyleID } from "../../global-configuration/selectors";
import { POICategoryGroup, POICategoryGroupIconIDs } from "../../../../utils/mapControls";
import { handleUpdateEndpoint } from "../../global-configuration/thunks";
import { selectMapStyleIDBeingEdited } from "./selectors";
import { planRoute } from "../planner/thunks";

const getSelectAllValue = (hiddenPOIsCategories: number[][], isOtherToggled: boolean) => {
    return !(hiddenPOIsCategories.length !== 0 || !isOtherToggled);
};

export const onMapStyleSelected = createAsyncThunk<void, MapStyleGlobalConfig, { state: RootState }>(
    "map-controls/onMapStyleSelected",
    async (data: MapStyleGlobalConfig, thunkApi) => {
        const state = thunkApi.getState();
        const mapReady = selectMapReady(state);
        if (!mapReady) {
            return;
        }

        const selectedMapStyleID = selectSelectedMapStyleID(state);
        const mapStyleIDBeingEdited = selectMapStyleIDBeingEdited(state);
        const featureConfigs = selectFeatureConfigs(state);

        if (data.id != selectedMapStyleID) {
            // saving map style
            thunkApi.dispatch(
                globalConfigActions.putFeatureConfigs({
                    selectedMapStyle: { id: data.id, orbis: data.orbis }
                })
            );

            thunkApi.dispatch(mapControlsActions.setMapStyleIDBeingEdited(null));

            thunkApi.dispatch(
                logEventWithActiveMode({
                    event_name: "map_style_selected",
                    selected_item: data.id,
                    experimental: !!data.experimental,
                    orbis: !!data.orbis
                })
            );
        } else if (featureConfigs.userMapStyling) {
            thunkApi.dispatch(mapControlsActions.setMapStyleIDBeingEdited(!mapStyleIDBeingEdited ? data.id : null));
        }
    }
);

export const onCustomStyleURLInput = createAsyncThunk<void, MapStyleConfigBase, { state: RootState }>(
    "map-controls/onCustomStyleURLInput",
    async (config, thunkApi) => {
        // - putting a special style with CUSTOM_URL ID in the state,
        thunkApi.dispatch(globalConfigActions.putCustomURLMapStyle(config));

        // - then selecting it
        thunkApi.dispatch(
            onMapStyleSelected(
                thunkApi.getState().globalConfiguration.map.styles.find((style) => style.id == CUSTOM_URL_STYLE_ID)
            )
        );
    }
);

export const onTrafficIncidentsToggled = createAsyncThunk<void, boolean, { state: RootState }>(
    "map-controls/onTrafficIncidentsToggled",
    async (data: boolean, thunkApi) => {
        thunkApi.dispatch(mapControlsActions.toggleTrafficIncidents(data));
        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "map_layer_toggle",
                selected_item: "traffic_incidents",
                change: data
            })
        );
    }
);

export const onTrafficFlowToggled = createAsyncThunk<void, boolean, { state: RootState }>(
    "map-controls/onTrafficFlowToggled",
    async (data: boolean, thunkApi) => {
        thunkApi.dispatch(mapControlsActions.toggleTrafficFlow(data));
        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "map_layer_toggle",
                selected_item: "traffic_flow",
                change: data
            })
        );
    }
);

export const onScenicSegmentsToggled = createAsyncThunk<void, boolean, { state: RootState }>(
    "map-controls/onScenicSegmentsToggled",
    async (data: boolean, thunkApi) => {
        thunkApi.dispatch(mapControlsActions.toggleScenicSegments(data));
        thunkApi.dispatch(
            logEventWithActiveMode({ event_name: "map_layer_toggle", selected_item: "scenic_segments", change: data })
        );
    }
);

export const onRoadTripsToggled = createAsyncThunk<void, boolean, { state: RootState }>(
    "map-controls/onRoadTripsToggled",
    async (data: boolean, thunkApi) => {
        thunkApi.dispatch(mapControlsActions.toggleRoadTrips(data));
        thunkApi.dispatch(
            logEventWithActiveMode({ event_name: "map_layer_toggle", selected_item: "tomtom_road_trips", change: data })
        );
    }
);

export const onRiderRoutesToggled = createAsyncThunk<void, boolean, { state: RootState }>(
    "map-controls/onRiderRoutesToggled",
    async (data: boolean, thunkApi) => {
        thunkApi.dispatch(mapControlsActions.toggleRiderRoutes(data));
        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "map_layer_toggle",
                selected_item: "tomtom_rider_routes",
                change: data
            })
        );
    }
);

export const onRoadTripsShortcutToggled = createAsyncThunk<void, void, { state: RootState }>(
    "map-controls/onRoadTripsToggled",
    async (data, thunkApi) => {
        thunkApi.dispatch(mapControlsActions.toggleRoadTrips(true));
        thunkApi.dispatch(mapControlsActions.openMapOptionsFromRoadTripShortcut());
        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "map_layer_toggle",
                selected_item: "tomtom_road_trips",
                change: data
            })
        );
    }
);

export const onTrafficShortcutSelected = createAsyncThunk<void, void, { state: RootState }>(
    "map-controls/onTrafficShortcutSelected",
    async (data: void, thunkApi) => {
        //not firing onTrafficFlowToggled to not trigger analytics
        thunkApi.dispatch(mapControlsActions.toggleTrafficIncidents(true));
        thunkApi.dispatch(mapControlsActions.openMapOptionsFromTrafficShortcut());
        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "map_traffic_shortcut"
            })
        );
    }
);

export const onPOIsCategoryToggle = createAsyncThunk<void, POICategoryGroup, { state: RootState }>(
    "map-controls/onPOIsCategoryToggle",
    async ({ iconIDs: categoryGroupIconIDs, label }, thunkApi) => {
        const state = thunkApi.getState();
        let hiddenPOIsCategoryGroupIconIDs = [...state.mapPage.mapControls.hiddenPOIsCategoryGroupIconIDs];
        let change;

        if (hiddenPOIsCategoryGroupIconIDs.some((cat) => isEqual(cat, categoryGroupIconIDs))) {
            hiddenPOIsCategoryGroupIconIDs = hiddenPOIsCategoryGroupIconIDs.filter(
                (cat) => !isEqual(cat, categoryGroupIconIDs)
            );
            change = "include";
        } else {
            hiddenPOIsCategoryGroupIconIDs.push(categoryGroupIconIDs);
            change = "exclude";
        }
        thunkApi.dispatch(mapControlsActions.updateHiddenPOIsCategoryGroupIconIDs(hiddenPOIsCategoryGroupIconIDs));
        const newSelectAllValue = getSelectAllValue(
            hiddenPOIsCategoryGroupIconIDs,
            state.mapPage.mapControls.isOtherPOIsToggled
        );
        thunkApi.dispatch(mapControlsActions.toggleSelectAllPOIs(newSelectAllValue));

        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "poi_category_toggle",
                selected_item: label,
                change
            })
        );
    }
);

export const onSelectAllPOIsToggle = createAsyncThunk<void, boolean, { state: RootState }>(
    "map-controls/onSelectAllPOIsToggle",
    async (toggle: boolean, thunkApi) => {
        thunkApi.dispatch(mapControlsActions.toggleSelectAllPOIs(toggle));
        thunkApi.dispatch(mapControlsActions.toggleOtherPOIs(toggle));
        toggle
            ? thunkApi.dispatch(mapControlsActions.updateHiddenPOIsCategoryGroupIconIDs([]))
            : thunkApi.dispatch(mapControlsActions.updateHiddenPOIsCategoryGroupIconIDs(POICategoryGroupIconIDs));

        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "poi_category_toggle",
                selected_item: "select_all",
                change: toggle
            })
        );
    }
);

export const onOtherPOIsToggle = createAsyncThunk<void, boolean, { state: RootState }>(
    "map-controls/onOtherPOIsToggle",
    async (toggle: boolean, thunkApi) => {
        const state = thunkApi.getState();
        const newSelectAllValue = getSelectAllValue(state.mapPage.mapControls.hiddenPOIsCategoryGroupIconIDs, toggle);
        thunkApi.dispatch(mapControlsActions.toggleOtherPOIs(toggle));
        thunkApi.dispatch(mapControlsActions.toggleSelectAllPOIs(newSelectAllValue));

        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "poi_category_toggle",
                selected_item: "other",
                change: toggle
            })
        );
    }
);

export const onMyPlacesDisplayToggle = createAsyncThunk<void, boolean, { state: RootState }>(
    "map-controls/onMyPlacesDisplayToggle",
    async (display: boolean, thunkApi) => {
        thunkApi.dispatch(mapControlsActions.toggleMyPlaces(display));

        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "map_layer_toggle",
                selected_item: "my_places",
                change: display
            })
        );
    }
);

export const onEndpointSelected = createAsyncThunk<void, ServiceSource, { state: RootState }>(
    "map-controls/onEndpointSelected",
    async (data: ServiceSource, thunkApi) => {
        //not firing onTrafficFlowToggled to not trigger analytics
        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "endpoint_change",
                selected: data
            })
        );
        await thunkApi.dispatch(handleUpdateEndpoint(data));
        thunkApi.dispatch(planRoute());
    }
);

import { Itinerary, service, UserMapLocation } from "@anw/gor-sdk";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { changeSelectedLocation } from "../location/thunks";
import { actions as myItemsActions } from "./reducers";
import { actions as notificationActions } from "../../notification/reducers";
import {
    defaultNameForType,
    getSelectedLocationAfterSavedPlacesChanged,
    withMyPlaceSearchTexts
} from "../../../../utils/myPlaces";
import { withMyRouteSearchTexts } from "../../../../utils/itinerary";
import { AuthCheck, createRetryAsyncThunk } from "../../../retryAsyncThunk";
import { TTMUserMapLocation } from "../../../../utils/locationTypes";
import { RootState } from "../../../RootReducer";
import { searchItems } from "../../../../utils/search";

export const updateMyPlaces = createAsyncThunk<void, TTMUserMapLocation[], { state: RootState }>(
    "myItems/updateMyPlaces",
    async (myPlaces, thunkApi) => {
        const mapPageState = thunkApi.getState().mapPage;
        const prevMyPlaces = mapPageState.myItems.myPlaces;
        thunkApi.dispatch(myItemsActions.setMyPlaces(myPlaces));

        // Here we detect if the selected location could have been related to saved places, and if changed, update it:
        const selectedLocation = mapPageState.location.selectedLocation;
        if (selectedLocation) {
            const updatedLocation = getSelectedLocationAfterSavedPlacesChanged(
                selectedLocation,
                prevMyPlaces,
                myPlaces
            );
            if (updatedLocation.changed) {
                thunkApi.dispatch(
                    changeSelectedLocation({
                        location: updatedLocation.location,
                        selectedFrom: mapPageState.location.selectedFrom
                    })
                );
            }
        }
    }
);

export const fetchMyPlaces = createRetryAsyncThunk<void, void>(
    "myItems/fetchMyPlaces",
    AuthCheck.EAGER,
    async (data, thunkApi) => {
        const locationCollections = await service().api.userMapLocationCollections.fetch();
        // @ts-ignore to be fixed in gor-sdk types
        const favCollectionId = locationCollections.find((collection) => collection.type === "FAVOURITES")?.id;
        thunkApi.dispatch(myItemsActions.setFavCollectionId(favCollectionId));
        const favoritesCollection = await service().api.userMapLocationCollections.fetchSingle({
            parameters: { id: favCollectionId }
        });
        thunkApi.dispatch(updateMyPlaces(withMyPlaceSearchTexts(favoritesCollection.locations) || []));
    }
);

export const fetchOV2Files = createRetryAsyncThunk<void, void>(
    "myItems/fetchOV2Files",
    AuthCheck.EAGER,
    async (data, thunkApi) => {
        const response = await service().api.ov2.getOV2Files();
        thunkApi.dispatch(myItemsActions.setOV2Files(response.poiFiles));
    }
);

export const uploadOV2File = createRetryAsyncThunk<void, File>(
    "myItems/uploadOV2File",
    AuthCheck.LAZY,
    async (data, thunkApi) => {
        const formData = new FormData();
        formData.append("file", data);
        await service().api.ov2.uploadOV2({ payload: formData, parameters: { fileName: data.name } });
        thunkApi.dispatch(fetchOV2Files());
    }
);

export const deleteOV2File = createRetryAsyncThunk<void, string>(
    "myItems/deleteOV2File",
    AuthCheck.LAZY,
    async (data, thunkApi) => {
        thunkApi.dispatch(myItemsActions.addBusyItem(data));
        await service().api.ov2.deleteOV2File({ parameters: { id: data } });
        thunkApi.dispatch(fetchOV2Files());
        thunkApi.dispatch(myItemsActions.removeBusyItem(data));
    }
);

export const getItineraries = createRetryAsyncThunk<void, void>(
    "myItems/getItineraries",
    AuthCheck.EAGER,
    async (data, thunkApi) => {
        const itineraries = await service().api.itineraries.fetchItineraries({
            parameters: { viewType: "SUMMARY" }
        });

        if (itineraries) {
            thunkApi.dispatch(myItemsActions.setUserItineraries(withMyRouteSearchTexts(itineraries)));
        }
    }
);

export const savePlace = createRetryAsyncThunk<void, Partial<UserMapLocation>>(
    "myItems/savePlace",
    AuthCheck.EAGER,
    async (data, thunkApi) => {
        const locationInfo = data.mapLocation.locationInfo;
        thunkApi.dispatch(myItemsActions.addBusyItem(locationInfo.externalID));
        const state = thunkApi.getState();
        const favCollectionId = state.mapPage.myItems.userFavCollectionId;
        const nameForType = defaultNameForType(data.type);
        const collectionsToSaveTo = [{ id: favCollectionId }];

        const userMapLocation: UserMapLocation = {
            ...data,
            mapLocation: {
                ...data.mapLocation,
                locationInfo:
                    !locationInfo.customName && nameForType
                        ? { ...locationInfo, customName: nameForType }
                        : locationInfo
            },
            collections: collectionsToSaveTo,
            type: data.type
        };

        if (userMapLocation.id) {
            await service().api.userMapLocation.update({ payload: userMapLocation });
        } else {
            await service().api.userMapLocation.create({ payload: userMapLocation });
        }

        thunkApi.dispatch(
            notificationActions.addMyItemsPlacesNotification({
                notificationType: userMapLocation.id ? "place-update" : "place-add",
                locationType: data.type,
                locationInfo
            })
        );

        await thunkApi.dispatch(fetchMyPlaces());
        thunkApi.dispatch(myItemsActions.removeBusyItem(locationInfo.externalID));
        thunkApi.dispatch(myItemsActions.setPlaceBeingEdited(null));
    },
    async (data, thunkApi) => {
        // remove failed because of missing user authentication, unblock item
        thunkApi.dispatch(myItemsActions.removeBusyItem(data.mapLocation.locationInfo?.externalID));
    }
);

export const removePlace = createRetryAsyncThunk<void, string>(
    "myItems/removePlace",
    AuthCheck.EAGER,
    async (id, thunkApi) => {
        const state = thunkApi.getState();
        const favLocation = state.mapPage.myItems.myPlaces.find((item) => item.id === id);
        const externalID = favLocation.mapLocation?.locationInfo.externalID ?? "";

        thunkApi.dispatch(myItemsActions.addBusyItem(externalID));
        await service().api.userMapLocation.remove({ parameters: { id } });
        thunkApi.dispatch(
            notificationActions.addMyItemsPlacesNotification({
                notificationType: "place-remove",
                locationInfo: favLocation.mapLocation?.locationInfo
            })
        );
        await thunkApi.dispatch(fetchMyPlaces());
        thunkApi.dispatch(myItemsActions.removeBusyItem(externalID));
    },
    async (id, thunkApi) => {
        // remove failed because of missing user authentication, unblock item
        const state = thunkApi.getState();
        const favLocation = state.mapPage.myItems.myPlaces.find((item) => item.id === id);
        const externalID = favLocation.mapLocation?.locationInfo.externalID ?? "";
        thunkApi.dispatch(myItemsActions.removeBusyItem(externalID));
    }
);

export const deleteItinerary = createRetryAsyncThunk<void, Itinerary>(
    "myItems/deleteItinerary",
    AuthCheck.EAGER,
    async (itinerary, thunkApi) => {
        thunkApi.dispatch(myItemsActions.addBusyItem(itinerary.id));
        await service().api.itineraries.deleteItinerary({ parameters: { itineraryId: itinerary.id } });
        thunkApi.dispatch(
            notificationActions.addMyItemsRoutesNotification({
                notificationType: "route-removed",
                itinerary
            })
        );
        await thunkApi.dispatch(getItineraries());
        thunkApi.dispatch(myItemsActions.removeBusyItem(itinerary.id));
    },
    async (itinerary, thunkApi) => {
        thunkApi.dispatch(myItemsActions.removeBusyItem(itinerary.id));
    }
);

export const importItinerary = createRetryAsyncThunk<void, string>(
    "myItems/importItinerary",
    AuthCheck.EAGER,
    async (itineraryId, thunkApi) => {
        await service().api.itineraries.copyToMyRoutes({ parameters: { itineraryId } });
        thunkApi.dispatch(
            notificationActions.addMyItemsRoutesNotification({
                notificationType: "route-add"
            })
        );
        await thunkApi.dispatch(getItineraries());
    }
);

export const shareItinerary = createAsyncThunk<void, string>(
    "myItems/shareItinerary",
    async (itineraryId, thunkApi) => {
        const updatedItinerary = await service().api.itineraries.shareItinerary({
            parameters: { itineraryId }
        });
        await thunkApi.dispatch(getItineraries());
        thunkApi.dispatch(myItemsActions.setSelectedItinerary(updatedItinerary));
    }
);

export const searchMyPlaces = createAsyncThunk<void, string, { state: RootState }>(
    "myItems/searchMyPlaces",
    async (searchInput, thunkApi) => {
        const myPlaces = thunkApi.getState().mapPage.myItems.myPlaces;
        thunkApi.dispatch(
            myItemsActions.setFilteredMyPlaces(searchInput ? searchItems(myPlaces, searchInput) : myPlaces)
        );
    }
);

export const searchMyRoutes = createAsyncThunk<void, string, { state: RootState }>(
    "myItems/searchMyRoutes",
    async (searchInput, thunkApi) => {
        const myRoutes = thunkApi.getState().mapPage.myItems.userItineraries;
        thunkApi.dispatch(
            myItemsActions.setFilteredItineraries(searchInput ? searchItems(myRoutes, searchInput) : myRoutes)
        );
    }
);

import { RootState } from "../../../RootReducer";

export const selectUnitsType = (state: RootState) => state.mapPage.settings.unitsType;
export const selectVehicleParameters = (state: RootState) => state.mapPage.settings.vehicleParameters;
export const selectMSPSettings = (state: RootState) => state.mapPage.settings.vehicleParameters.mspSettings;
export const selectElectricVehicleConsumptionSettings = (state: RootState) =>
    state.mapPage.settings.vehicleParameters.electricVehicleConsumptionSettings;
export const selectCombustionVehicleConsumptionModel = (state: RootState) =>
    state.mapPage.settings.vehicleParameters.combustionVehicleConsumptionModel;
export const selectVehicleEngineType = (state: RootState) => state.mapPage.settings.vehicleParameters.engineType;
export const selectReachableRangeSettings = (state: RootState) => state.mapPage.settings.reachableRangeSettings;
export const selectChargingParameters = (state: RootState) =>
    state.mapPage.settings.vehicleParameters.chargingParameters;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ItineraryPlanningResponse, shared } from "@anw/gor-sdk";
import i18next from "i18next";

import { RootState } from "../../RootReducer";
import { TealiumLogger } from "../../../classes/TealiumLogger";
import { navigateToPath } from "../navigation/thunks";

const getActiveMode = (plannedRouteResponse: ItineraryPlanningResponse): string =>
    plannedRouteResponse ? "route_planned" : "no_route_planned";

// adding thunks that help keeping more complex analytics logic separate
export const logEventWithActiveMode = createAsyncThunk<void, Record<string, unknown>, { state: RootState }>(
    "application/logEventWithActiveMode",
    (data, thunkApi) => {
        TealiumLogger.link({
            ...data,
            active_mode: getActiveMode(thunkApi.getState().mapPage.planner.plannedRouteInformation.response)
        });
    }
);

export const handleGORConfigLanguageChange = createAsyncThunk<void, string, { state: RootState }>(
    "application/handleGORConfigLanguageChange",
    async (locale) => {
        shared.service.setLocale(locale);
    }
);

export const changeLocale = createAsyncThunk<void, string, { state: RootState }>(
    "application/logEventWithActiveMode",
    async (locale, thunkApi) => {
        const prevLang = i18next.language;
        await i18next.changeLanguage(locale);
        thunkApi.dispatch(handleGORConfigLanguageChange(locale));
        const pathname = thunkApi.getState().router.location.pathname;
        await thunkApi.dispatch(navigateToPath(pathname.replace(prevLang, locale)));
    }
);

import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import "../../../../sass/ui-library/components/simple/Spinner.scss";
import { bem } from "../../utils/bem";

const spinner = bem("spinner");

export type SpinnerProps = HTMLAttributes<HTMLElement> & {
    message?: string;
    spinnerClassName?: string;
};

export const Spinner = ({ message, className, spinnerClassName, ...rest }: SpinnerProps) => (
    <div className={classNames(spinner.block(), className)}>
        <div className={classNames("spinner-border", spinnerClassName)} role="status" {...rest}>
            <span className="visually-hidden">Loading...</span>
        </div>
        <label className={spinner.element("message")}>{message}</label>
    </div>
);

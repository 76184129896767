import React, { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import bem from "../../utils/bem";
import { ParentQaId, QaIdModifier } from "../../utils/qa-id";

import "../../../../sass/ui-library/components/base/TextWithIcon.scss";
import useQaId from "../../hooks/useQaId";

const textWithIcon = bem("text-with-icon");

export type TextWithIconProps = QaIdModifier<HTMLAttributes<HTMLDivElement>> &
    ParentQaId & {
        text: string;
        icon?: ReactNode;
        secondary?: boolean;
        iconSecondary?: boolean;
        direction?: "horizontal" | "vertical";
        size?: "s" | "m" | "l";
        disabled?: boolean;
        checked?: boolean;
        clickable?: boolean;
    };

/**
 * @deprecated To be replaced by IconSelect
 */
export const TextWithIcon = (props: TextWithIconProps) => {
    const {
        icon,
        secondary,
        text,
        title,
        direction,
        className,
        clickable,
        disabled,
        checked,
        size,
        iconSecondary,
        parentQaId,
        blockElement,
        qaIdModifier,
        ...rest
    } = props;
    const iconSelectQa = useQaId("icon-select", parentQaId, qaIdModifier, true);
    return (
        <div
            {...iconSelectQa.block(blockElement)}
            className={classNames(textWithIcon.block(), className, {
                [textWithIcon.modifier("secondary")]: secondary,
                [textWithIcon.modifier("icon-secondary")]: iconSecondary,
                [textWithIcon.modifier("clickable")]: clickable,
                [textWithIcon.modifier("disabled")]: disabled,
                [textWithIcon.modifier("checked")]: checked,
                [textWithIcon.modifier(direction)]: direction,
                [textWithIcon.modifier(size)]: size
            })}
            {...rest}
        >
            {icon && (
                <span className={classNames(textWithIcon.element("icon"))} {...iconSelectQa.element("icon")}>
                    {icon}
                </span>
            )}
            <span title={title} className={classNames(textWithIcon.element("text"))} {...iconSelectQa.element("text")}>
                {text}
            </span>
        </div>
    );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    CostModel,
    ItineraryPlanningRequest,
    LNHPreference,
    LocationInfo,
    Point,
    service,
    TravelMode,
    VehicleParameters,
    Waypoint
} from "@anw/gor-sdk";
import axios, { AxiosRequestConfig } from "axios";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";

import { EngineTypeValue } from "../../../../constants/EvConstants";
import { TealiumLogger } from "../../../../classes/TealiumLogger";
import {
    canRouteBePlanned,
    selectCurrentPlannerParams,
    selectFilledWaypoints,
    selectIsRoundTrip,
    selectPlannedRouteInformation,
    selectRouteSelection,
    selectVehicleParameters,
    selectWaypointIndexMappings,
    selectWaypoints
} from "./selectors";
import { actions as plannerActions, PlanningActionContext } from "./reducers";
import { actions as locationActions } from "../location/reducers";
import { actions as notificationActions } from "../../notification/reducers";
import { RootState } from "../../../RootReducer";
import { selectServiceSource, selectServiceUrls } from "../../global-configuration/selectors";
import { selectMapCenterCoordinates } from "../map/selectors";
import { TTMLocation, TTMLocationContext, TTMWaypoint } from "../../../../utils/locationTypes";
import { detailsToTTMSearchResult, getLocationInfo, getPoint } from "../../../../utils/location";
import {
    calcNewWaypointIndex,
    hasNonWaypointGeoInputs,
    NewWaypointIndex,
    updatedFromResponse,
    waypointsOnlyWithPlaceholders
} from "../../../../utils/waypoint";
import { IN_LINE_PLANNER, trackRouteFoundEvent } from "../../../../utils/analytics";
import { isAnyEqualToHistoricTrafficRoute, toRouteFeatures } from "../../../../utils/route";
import { searchItems } from "../../../../utils/search";
import { fromURLPlanningCriteria } from "../../../../utils/routeURL";
import { navigateToRoutePlan, updateWithPlanningCriteria } from "../../navigation/thunks";
import { logEventWithActiveMode } from "../../application/thunks";
import { selectMyItems } from "../my-items/selectors";
import { reverseGeocode } from "../../../../components/map-page/SearchResults";
import {
    toGeoJson,
    toReachableRangeAvoidable,
    toReachableRangeLNH,
    toReachableRangeRouteType
} from "../../../../utils/reachableRange";
import { getLocalStorageJson } from "../../../../utils/localStorage";
import { AdditionalSearchOption } from "../../../../components/map-page/SearchResults";
import { featureConfigsKey } from "../../global-configuration/reducers";
import i18next from "i18next";
import { PlannerMode } from "./constants";
import { updateUserPosition } from "../thunks";
import { actions } from "./index";
import { suggest } from "../../../../services/ngs/ngsClient";
import { mapToTTMSearchResults } from "../../../../services/ngs/ngsAdapter";
import { fetchPoiById } from "../../../../hooks/useFetchPoi";

type ActionSource = "map_contextual" | "map_rightclick" | "location";

type HandlerRouteActionData = {
    location: TTMLocation;
    actionSource: ActionSource;
    waypointIndex: number;
    forcedIndex?: number;
};

const getActionDirection = (waypoints: TTMWaypoint[], forcedIndex: number, waypointIndex: number) => {
    if (!isNil(forcedIndex)) {
        return forcedIndex === 0 ? "direction_from" : "direction_to";
    } else {
        return waypointIndex === 0 ? "direction_from" : "direction_to";
    }
};

export const clearReachableRange = createAsyncThunk<void, void, { state: RootState; rejectValue: string }>(
    "plannerPage/clearReachableRange",
    async (data, thunkApi) => {
        thunkApi.dispatch(plannerActions.updateReachableRange(null));
    }
);

export const clearCalculatedRouteAndCriteria = createAsyncThunk<void, void, { state: RootState; rejectValue: string }>(
    "plannerPage/clearCalculatedRouteAndCriteria",
    async (data, thunkApi) => {
        thunkApi.dispatch(plannerActions.clearCalculatedRouteAndWaypoints());
        thunkApi.dispatch(clearReachableRange());
        thunkApi.dispatch(updateWithPlanningCriteria(null));
    }
);

function verifyMandatoryConsumptionModelParams(vehicleParameters: VehicleParameters): boolean {
    if (vehicleParameters?.engineType !== EngineTypeValue.Electric) {
        return true;
    } else {
        const cm = vehicleParameters?.electricVehicleConsumptionModel;
        return vehicleParameters.vehicleModelId
            ? !isNil(cm?.currentChargeInkWh) &&
                  !isNil(cm?.minChargeAtChargingStopsInkWh) &&
                  !isNil(cm?.minChargeAtDestinationInkWh)
            : !isEmpty(cm?.constantSpeedConsumptionInkWhPerHundredkm) &&
                  !isNil(cm?.currentChargeInkWh) &&
                  !isNil(cm?.maxChargeInkWh) &&
                  !isNil(cm?.minChargeAtChargingStopsInkWh) &&
                  !isNil(cm?.minChargeAtDestinationInkWh);
    }
}

export const calculateReachableRange = createAsyncThunk<void, void, { state: RootState; rejectValue: string }>(
    "plannerPage/calculateReachableRange",
    async (data, thunkApi) => {
        const settingState = thunkApi.getState().mapPage.settings;
        if (!settingState.reachableRangeSettings.showReachableRange) {
            thunkApi.dispatch(clearReachableRange());
            return;
        }
        const currentPlannerParams = thunkApi.getState().mapPage.planner.currentPlannerParams;
        const originWaypoint = currentPlannerParams.sortedGeoInputs[0] as Waypoint;
        if (!originWaypoint) {
            console.log("ReachableRange - clear and skip calculation. No origin specified");
            thunkApi.dispatch(clearReachableRange());
            return;
        }
        const settingsVehicleParameters = settingState?.vehicleParameters;
        const isEv = currentPlannerParams?.vehicleParameters?.engineType === "ELECTRIC";
        const isDetailed = settingState.reachableRangeSettings.showDetailedRange;

        const params = new URLSearchParams();
        params.append("key", thunkApi.getState().globalConfiguration.apiKey);
        params.append("vehicleEngineType", isEv ? "electric" : "combustion");
        params.append("routeType", toReachableRangeRouteType(currentPlannerParams?.costModel));
        if (currentPlannerParams?.costModel === "THRILLING") {
            params.append("hilliness", toReachableRangeLNH(currentPlannerParams?.thrillingPreferences?.hilliness));
            params.append("windingness", toReachableRangeLNH(currentPlannerParams?.thrillingPreferences?.windingness));
        }
        if (settingsVehicleParameters?.length) {
            params.append("vehicleLength", settingsVehicleParameters?.length);
        }
        if (settingsVehicleParameters?.height) {
            params.append("vehicleHeight", settingsVehicleParameters?.height);
        }
        if (settingsVehicleParameters?.width) {
            params.append("vehicleWidth", settingsVehicleParameters?.width);
        }
        if (settingsVehicleParameters?.weight) {
            params.append("vehicleWeight", settingsVehicleParameters?.weight);
        }
        if (settingsVehicleParameters?.axleWeight) {
            params.append("vehicleAxleWeight", settingsVehicleParameters?.axleWeight);
        }
        if (settingsVehicleParameters?.maxSpeed) {
            params.append("vehicleMaxSpeed", settingsVehicleParameters?.maxSpeed);
        }

        currentPlannerParams?.avoidOptions?.forEach((avoidOption) => {
            params.append("avoid", toReachableRangeAvoidable(avoidOption));
        });

        if (currentPlannerParams?.departTime) {
            params.append("departAt", new Date(currentPlannerParams?.departTime).toISOString());
        }

        const evConsumptionModel = currentPlannerParams.vehicleParameters?.electricVehicleConsumptionModel;
        const combustionConsumptionModel = settingState.vehicleParameters?.combustionVehicleConsumptionModel;
        const electricVehicleConsumptionSettings = settingState.vehicleParameters?.electricVehicleConsumptionSettings;

        if (isEv && evConsumptionModel && electricVehicleConsumptionSettings) {
            params.append("energyBudgetInkWh", evConsumptionModel.currentChargeInkWh.toString());
            params.append("maxChargeInkWh", electricVehicleConsumptionSettings.maxChargeInkWh.toString());
            params.append(
                "constantSpeedConsumptionInkWhPerHundredkm",
                electricVehicleConsumptionSettings.constantSpeedConsumptionInkWhPerHundredkm
            );
            params.append("currentChargeInkWh", evConsumptionModel.currentChargeInkWh.toString());

            if (electricVehicleConsumptionSettings.auxiliaryPowerInkW) {
                params.append("auxiliaryPowerInkW", electricVehicleConsumptionSettings.auxiliaryPowerInkW.toString());
            }
            if (evConsumptionModel.accelerationEfficiency) {
                params.append("accelerationEfficiency", evConsumptionModel.accelerationEfficiency.toString());
            }
            if (evConsumptionModel.decelerationEfficiency) {
                params.append("decelerationEfficiency", evConsumptionModel.decelerationEfficiency.toString());
            }
            if (evConsumptionModel.downhillEfficiency) {
                params.append("downhillEfficiency", evConsumptionModel.downhillEfficiency.toString());
            }
            if (evConsumptionModel.uphillEfficiency) {
                params.append("uphillEfficiency", evConsumptionModel.uphillEfficiency.toString());
            }
            if (evConsumptionModel.consumptionInkWhPerkmAltitudeGain) {
                params.append(
                    "consumptionInkWhPerkmAltitudeGain",
                    evConsumptionModel.consumptionInkWhPerkmAltitudeGain.toString()
                );
            }
            if (evConsumptionModel.recuperationInkWhPerkmAltitudeLoss) {
                params.append(
                    "recuperationInkWhPerkmAltitudeLoss",
                    evConsumptionModel.recuperationInkWhPerkmAltitudeLoss.toString()
                );
            }
            if (evConsumptionModel.consumptionInkWhPerkmAltitudeGain) {
                params.append(
                    "consumptionInkWhPerkmAltitudeGain",
                    evConsumptionModel.consumptionInkWhPerkmAltitudeGain.toString()
                );
            }

            // override vehicle if specified in consumption model
            if (evConsumptionModel.vehicleWeight) {
                params.set("vehicleWeight", evConsumptionModel.vehicleWeight.toString());
            }
        } else if (!isEv && combustionConsumptionModel) {
            params.append(
                "constantSpeedConsumptionInLitersPerHundredkm",
                combustionConsumptionModel.constantSpeedConsumptionInLitersPerHundredkm.toString()
            );
            params.append("fuelBudgetInLiters", combustionConsumptionModel.currentFuelInLiters.toString());
        } else {
            // we should never be here
            console.warn("ReachableRange - skip calculation. no consumption Model given");
            return;
        }

        if (isDetailed) {
            params.append("shape", "detailed");
        }

        const config = {
            baseURL: "https://api.tomtom.com/routing/1/calculateReachableRange",
            params: params
        } as AxiosRequestConfig;
        try {
            const response = await axios.get(originWaypoint.pointLatLon + "/json", config);
            thunkApi.dispatch(plannerActions.updateReachableRange(toGeoJson(response)));
        } catch (error) {
            console.error("Calculate reachableRange failed " + error);

            if (error.response && isDetailed) {
                console.error(
                    "Couldn't calculate detailed reachableRange - " + error.response.data?.error?.description
                );

                thunkApi.dispatch(
                    notificationActions.addReachableRangeNotification({
                        notificationType: "detailed-range-uncalculable"
                    })
                );

                thunkApi.dispatch(clearReachableRange());
            }
        }
    }
);

const toPlanningContext = (source?: ActionSource): PlanningActionContext =>
    source == "map_contextual" || source == "map_rightclick" ? "MAP_POPUP_ACTION" : "DEFAULT";

export const planRoute = createAsyncThunk<void, void, { state: RootState; rejectValue: string }>(
    "plannerPage/planRoute",
    async (data, thunkApi) => {
        const stateBeforeCall = thunkApi.getState();
        const vehicleParameters = selectVehicleParameters(stateBeforeCall);
        const isMandatoryConsumptionModelParamsSet = verifyMandatoryConsumptionModelParams(vehicleParameters);
        const features: { [key: string]: unknown } = getLocalStorageJson(featureConfigsKey);
        // check feature toggle config from local storage or runtimeCfg
        const showReachableRange =
            (features?.enableLDEV ?? stateBeforeCall.globalConfiguration.featureConfigs.enableLDEV) &&
            stateBeforeCall.mapPage.settings.reachableRangeSettings.showReachableRange;

        if (!canRouteBePlanned(stateBeforeCall) || !isMandatoryConsumptionModelParamsSet) {
            if (stateBeforeCall.mapPage.planner.plannedRouteInformation?.response) {
                // if there was a response we need to clear the route
                thunkApi.dispatch(plannerActions.clearCalculatedRoute());
                thunkApi.dispatch(updateWithPlanningCriteria(null));
            }
            showReachableRange && thunkApi.dispatch(calculateReachableRange());
            // if not enough waypoints skip planning
            return;
        }
        thunkApi.dispatch(plannerActions.removeChargingStopsFromGeoInputs());
        const request = selectCurrentPlannerParams(stateBeforeCall);
        const numGeoInputs =
            waypointsOnlyWithPlaceholders(request.sortedGeoInputs).length + (request.unsortedGeoInputs?.length || 0);
        const hasAnyNonWaypointGeoInputs =
            hasNonWaypointGeoInputs(request.sortedGeoInputs) || hasNonWaypointGeoInputs(request.unsortedGeoInputs);
        const draggingMode = stateBeforeCall.mapPage.planner.routeCalculation.context === "DRAGGING";
        const roundTrip = selectIsRoundTrip(stateBeforeCall);
        const extraPlanningTrafficInfo = stateBeforeCall.globalConfiguration.featureConfigs.extraPlanningTrafficInfo;
        const numLockedSortedWaypoints = request.unsortedGeoInputs && (roundTrip || draggingMode) ? 1 : null;
        const isEv = request?.vehicleParameters?.engineType === "ELECTRIC";

        const effectiveRequest = omitBy<ItineraryPlanningRequest>(
            {
                ...request,
                routingProvider: selectServiceSource(stateBeforeCall),
                // making sure that we don't send electric vehicle model id in case of combustion engine type, causes problems with route planning
                vehicleParameters: {
                    ...request?.vehicleParameters,
                    vehicleModelId:
                        request?.vehicleParameters?.engineType === "ELECTRIC"
                            ? request?.vehicleParameters?.vehicleModelId
                            : null
                },
                // we override the request waypoints here to follow with the current waypoints modeling:
                numAlternatives: numGeoInputs > 2 || draggingMode || hasAnyNonWaypointGeoInputs || isEv ? 0 : 2,
                includeGuidanceInFullView: !draggingMode,
                compareWithHistoricTrafficItinerary: extraPlanningTrafficInfo && !draggingMode,
                numLockedFirstSortedWaypoints: numLockedSortedWaypoints,
                numLockedLastSortedWaypoints: numLockedSortedWaypoints,
                thrillingPreferences: request.costModel === "THRILLING" ? request.thrillingPreferences : null
            },
            isNil
        );

        const requestTimestamp = Date.now();

        try {
            thunkApi.dispatch(plannerActions.updatePlannerMode(PlannerMode.PLANNED_ROUTE));
            const [, , response] = await Promise.all([
                thunkApi.dispatch(plannerActions.setLastPlannerRequestTimestamp(requestTimestamp)),
                thunkApi.dispatch(plannerActions.setRouteCannotBePlannedError(null)),
                service().api.itineraryPlanning.plan({ payload: effectiveRequest, cancellable: !draggingMode })
            ]);

            let lastRouteIsHistoricTrafficOne = false;
            if (
                // Lab feature: we append the historic-traffic route as a special extra alternative:
                extraPlanningTrafficInfo &&
                response.historicTrafficItinerary &&
                !isAnyEqualToHistoricTrafficRoute(response.plannedItineraries)
            ) {
                response.plannedItineraries.push({ itinerary: response.historicTrafficItinerary });
                lastRouteIsHistoricTrafficOne = true;
            }

            const mapPageStateAfterCall = thunkApi.getState().mapPage;
            const responseUpdatedRequest = updatedFromResponse(
                effectiveRequest,
                response,
                mapPageStateAfterCall.myItems.selectedItinerary
            );
            const plannerStateAfterCall = mapPageStateAfterCall.planner;
            const dragEnd =
                stateBeforeCall.mapPage.planner.plannedRouteInformation.context === "DRAGGING" &&
                plannerStateAfterCall.routeCalculation.context === "DEFAULT";
            const routeTrackingMethod = dragEnd ? "drag_route" : "default";

            if (
                requestTimestamp === plannerStateAfterCall.routeCalculation.lastPendingRequestTimestamp ||
                (draggingMode && requestTimestamp >= plannerStateAfterCall.plannedRouteInformation.requestTimestamp)
            ) {
                thunkApi.dispatch(
                    plannerActions.setPlannedRouteInformation({
                        requestTimestamp,
                        context: plannerStateAfterCall.routeCalculation.context,
                        effectiveRequest: responseUpdatedRequest.updatedRequest,
                        response,
                        responseFeatures: toRouteFeatures(
                            response.plannedItineraries?.map((plannedItinerary) => plannedItinerary.itinerary)
                        ),
                        lastRouteIsHistoricTrafficOne
                    })
                );
                if (!draggingMode || request.unsortedGeoInputs) {
                    thunkApi.dispatch(plannerActions.setCurrentPlannerParams(responseUpdatedRequest.updatedRequest));
                }
                // default route option should be selected
                thunkApi.dispatch(plannerActions.changeRouteSelection({ index: 0, selectedFrom: "DEFAULT" }));

                if (!draggingMode) {
                    thunkApi.dispatch(updateWithPlanningCriteria(effectiveRequest));
                    // (we don't track route planned while still dragging)
                    trackRouteFoundEvent(response, effectiveRequest, null, routeTrackingMethod);
                }
            }
            if (draggingMode && null == plannerStateAfterCall.routeCalculation.draggingWaypointIndex) {
                if (responseUpdatedRequest.unsortedResolvedIndexes?.length) {
                    thunkApi.dispatch(
                        plannerActions.setDraggingWaypointIndex(responseUpdatedRequest.unsortedResolvedIndexes[0])
                    );
                }
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // we ignore cancellation errors
                return;
            }
            const plannerStateAfterCall = thunkApi.getState().mapPage.planner;
            if (
                requestTimestamp === plannerStateAfterCall.routeCalculation.lastPendingRequestTimestamp ||
                (draggingMode && requestTimestamp >= plannerStateAfterCall.plannedRouteInformation.requestTimestamp)
            ) {
                const updatedEffectiveRequest = { ...effectiveRequest };
                // defensive fix to clean up the unsorted geo-inputs that can keep causing errors:
                if (!draggingMode && updatedEffectiveRequest.unsortedGeoInputs) {
                    delete updatedEffectiveRequest.unsortedGeoInputs;
                }
                if (!draggingMode) {
                    // (we don't bother tracking errors while still dragging)
                    trackRouteFoundEvent(null, effectiveRequest, error);
                    thunkApi.dispatch(plannerActions.setCurrentPlannerParams(updatedEffectiveRequest));
                    thunkApi.dispatch(
                        plannerActions.setPlannedRouteInformation({
                            requestTimestamp,
                            context: plannerStateAfterCall.routeCalculation.context,
                            effectiveRequest: updatedEffectiveRequest,
                            response: undefined,
                            responseFeatures: undefined,
                            lastRouteIsHistoricTrafficOne: false
                        })
                    );
                    thunkApi.dispatch(updateWithPlanningCriteria(null));
                }

                thunkApi.dispatch(plannerActions.setRouteCannotBePlannedError(JSON.stringify(error)));
                thunkApi.dispatch(plannerActions.updatePlannerMode(PlannerMode.ROUTE_CANNOT_BE_PLANNED));

                if (error.code === 503) {
                    // show notification for connection issue
                    thunkApi.dispatch(
                        notificationActions.addPreDefinedNotification({
                            notificationType: "connection-failure"
                        })
                    );
                }
                thunkApi.rejectWithValue(JSON.stringify(error));
            }
        } finally {
            if (requestTimestamp === thunkApi.getState().mapPage.planner.routeCalculation.lastPendingRequestTimestamp) {
                thunkApi.dispatch(plannerActions.setLastPlannerRequestTimestamp(0));
            }
            showReachableRange && thunkApi.dispatch(calculateReachableRange());
        }
    }
);

// add waypoint via map or planner panel
export const handleAddWaypoint = createAsyncThunk<
    void,
    {
        waypoint: TTMWaypoint;
        index: NewWaypointIndex;
        method: string;
        favourite?: boolean;
        actionSource?: ActionSource;
    },
    { state: RootState }
>("plannerPage/handleAddWaypoint", async (data, thunkApi) => {
    thunkApi.dispatch(plannerActions.updatePlannerMode(PlannerMode.HIDDEN));
    if (!data.favourite) {
        const locationDetails = await fetchPoiById(
            data.waypoint.locationInfo.externalID,
            data.waypoint.locationInfo.link
        );
        if (locationDetails) {
            const {point: _, ...newLocationInfo} = data.waypoint.locationInfo;
            const locationFromDetails = detailsToTTMSearchResult(locationDetails);
            data.waypoint.pointLatLon = locationFromDetails.point;
            newLocationInfo['point'] = locationFromDetails.point;
            newLocationInfo.formattedAddress = locationFromDetails.formattedAddress;
            newLocationInfo.boundingBox = locationFromDetails.boundingBox;
            data.waypoint.locationInfo = newLocationInfo as LocationInfo;
        }
    }
    thunkApi.dispatch(plannerActions.addWaypoint(data));
    thunkApi.dispatch(plannerActions.setRouteCalculationContext(toPlanningContext(data.actionSource)));
    await thunkApi.dispatch(planRoute());
});

export const handleRemoveWaypoint = createAsyncThunk<
    void,
    { index: number; method: string; favourite?: boolean; actionSource?: ActionSource },
    { state: RootState }
>("plannerPage/handleRemoveWaypoint", async (data, thunkApi) => {
    const { index, method, favourite = false, actionSource } = data;

    TealiumLogger.link({
        event_name: "remove_stop",
        method,
        favourite
    });

    thunkApi.dispatch(plannerActions.updatePlannerMode(PlannerMode.HIDDEN));
    thunkApi.dispatch(
        plannerActions.removeWaypoint({
            index,
            method
        })
    );
    thunkApi.dispatch(plannerActions.setRouteCalculationContext(toPlanningContext(actionSource)));
    await thunkApi.dispatch(planRoute());

    if (index == 0) {
        await thunkApi.dispatch(clearReachableRange());
    }
});

export const handleRouteAction = createAsyncThunk<void, HandlerRouteActionData, { state: RootState }>(
    "plannerPage/handleRouteAction",
    async ({ location, actionSource, waypointIndex, forcedIndex }, thunkApi) => {
        const state = thunkApi.getState();
        const locationInfo = getLocationInfo(location);
        const myItems = selectMyItems(state);
        // only used for the second analytics event
        const favourite = myItems.myPlaces.some(
            (item) => item.mapLocation?.locationInfo?.externalID == locationInfo?.externalID
        );

        // We navigate to the planner, unless we're in saved-route-plan mode
        // (in saved-route-plan mode so far you can't leave the planner without resetting it)
        if (!myItems.selectedItinerary) {
            thunkApi.dispatch(navigateToRoutePlan());
        }

        if (location.context === TTMLocationContext.WAYPOINT) {
            // removing a stop:
            thunkApi.dispatch(plannerActions.highlightWaypointIndex(null));
            thunkApi.dispatch(plannerActions.hoverWaypointIndex(null));
            thunkApi.dispatch(
                logEventWithActiveMode({
                    event_name: `${actionSource}_panel_action`,
                    selected_item: "remove_stop"
                })
            );

            await thunkApi.dispatch(
                handleRemoveWaypoint({
                    index: waypointIndex,
                    method: `${actionSource}_panel`,
                    favourite,
                    actionSource
                })
            );
        } else {
            // adding a stop:
            const activeRouteIndex = selectRouteSelection(state).index;
            const routeFeatures = selectPlannedRouteInformation(state).responseFeatures;
            const filledWaypointsLength = selectFilledWaypoints(state).length;
            const waypoints = selectWaypoints(state);

            thunkApi.dispatch(locationActions.setSelectedLocation(null));

            const { point } = locationInfo;
            const calculatedWaypointIndex = calcNewWaypointIndex(
                waypoints,
                [point[1], point[0]],
                routeFeatures?.features?.[activeRouteIndex]
            );

            // analytics related variables
            const method = !isNil(forcedIndex) ? "secondary_action" : "primary_action";
            const selected_item =
                filledWaypointsLength > 1
                    ? "add_stop"
                    : getActionDirection(waypoints, forcedIndex, calculatedWaypointIndex.value);
            thunkApi.dispatch(
                logEventWithActiveMode({
                    event_name: `${actionSource}_panel_action`,
                    selected_item,
                    method
                })
            );

            const index = !isNil(forcedIndex)
                ? ({ value: forcedIndex, addMode: "REPLACE" } as NewWaypointIndex)
                : calculatedWaypointIndex;

            // Adding a waypoint from map or location panel:
            await thunkApi.dispatch(
                handleAddWaypoint({
                    waypoint: { type: "HARD", pointLatLon: point, locationInfo, context: location.context },
                    index,
                    method: `${actionSource}_panel`,
                    favourite,
                    actionSource
                })
            );

            // if the user has not yet selected a start location, we geolocate them
            if (!waypoints[0] && forcedIndex !== 0 && waypoints.length === 2) {
                await thunkApi.dispatch(updateUserPosition());

                const userLocationInfo = thunkApi.getState().user.userLocationInfo;

                await thunkApi.dispatch(
                    handleAddWaypoint({
                        waypoint: {
                            type: "HARD",
                            pointLatLon: userLocationInfo.point,
                            locationInfo: userLocationInfo,
                            context: TTMLocationContext.WAYPOINT
                        },
                        index: { value: 0, addMode: "REPLACE" },
                        method: `${actionSource}_panel`
                    })
                );
            }
        }
    }
);

export const prepareRoutePlanData = createAsyncThunk<void, { readFromUrl: boolean }, { state: RootState }>(
    "route-edit/prepareRoutePlanData",
    async (data, thunkApi) => {
        let currentPlannerParams: ItineraryPlanningRequest;
        const state = thunkApi.getState();

        try {
            currentPlannerParams = {
                ...state.mapPage.planner.currentPlannerParams,
                ...(data.readFromUrl && state.router.location.query.r
                    ? fromURLPlanningCriteria(state.router.location.query.r)
                    : {})
            };
        } catch (error) {
            console.error(`Could not parse planning criteria from URL ${error?.message ?? error}`);
            currentPlannerParams = state.mapPage.planner.currentPlannerParams;
        }

        thunkApi.dispatch(plannerActions.setCurrentPlannerParams(currentPlannerParams));
        thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
        await thunkApi.dispatch(planRoute());
    }
);

export type FetchSearchResultsData = { query: string };

export const fetchSearchResults = createAsyncThunk<void, FetchSearchResultsData, { state: RootState }>(
    "plannerPage/fetchSearchResults",
    async (data, thunkApi) => {
        thunkApi.dispatch(plannerActions.updatePlannerMode(PlannerMode.SEARCH_RESULTS));

        let state = thunkApi.getState();
        const center = selectMapCenterCoordinates(state);
        const requestId = Date.now();

        try {
            // avoid unnecessary calls with empty or one letter query
            if (data.query.length > 1) {
                // *** My-Places and History Search section: see similar logic in search/thunks/fetchSearchAndAutocompleteSuggestions
                const searchIDsToSkip = [];
                let myPlacesSearchResults = [];
                if (state.mapPage.myItems.myPlaces) {
                    myPlacesSearchResults = searchItems(state.mapPage.myItems.myPlaces, data.query)?.slice(0, 1) || [];
                    thunkApi.dispatch(plannerActions.updateMyPlacesSearchResults(myPlacesSearchResults));
                    searchIDsToSkip.push(
                        ...myPlacesSearchResults.map((result) => result.mapLocation.locationInfo?.externalID)
                    );
                }

                let historySearchResults = [];
                if (state.mapPage.search.recentSearches) {
                    historySearchResults =
                        searchItems(state.mapPage.search.recentSearches, data.query)
                            ?.slice(0, 1)
                            .filter((result) => !searchIDsToSkip.includes(result.externalID)) || [];
                    searchIDsToSkip.push(...historySearchResults.map((result) => result.externalID));
                    thunkApi.dispatch(plannerActions.updateHistorySearchResults(historySearchResults));
                }
                // *** end of My-Places and History Search section

                const suggestCall = suggest({
                    query: data.query,
                    position: `${center.lat},${center.lng}`,
                    types: "POI,STREET,CROSS_STREET,GEOGRAPHY,POINT_ADDRESS,ADDRESS_RANGE",
                    language: i18next.language,
                    limit: 25
                });

                const [, suggestResponse] = await Promise.all([
                    thunkApi.dispatch(
                        plannerActions.setPendingSearchRequest({
                            lastRequestId: requestId,
                            query: data.query
                        })
                    ),
                    suggestCall
                ]);

                const filteredResults = suggestResponse.results.filter(
                    (result) => !searchIDsToSkip.includes(result.id)
                );

                const results = mapToTTMSearchResults(filteredResults);
                state = thunkApi.getState();
                if (requestId === state.mapPage.planner.search.lastRequestId) {
                    thunkApi.dispatch(plannerActions.updateSearchResults(results));
                }

                if (
                    // @ts-ignore
                    !suggestResponse.results.length &&
                    !myPlacesSearchResults.length &&
                    !historySearchResults.length
                ) {
                    thunkApi.dispatch(plannerActions.updatePlannerMode(PlannerMode.NO_RESULTS));
                } else {
                    thunkApi.dispatch(plannerActions.updatePlannerMode(PlannerMode.SEARCH_RESULTS));
                }
                // if the query is 0 or one letter update query in the state and clear search results (api response will come back empty anyway)
            } else {
                thunkApi.dispatch(plannerActions.setSearchQuery(data.query));
                thunkApi.dispatch(plannerActions.updateSearchResults([]));
                thunkApi.dispatch(plannerActions.updateMyPlacesSearchResults([]));
            }
        } catch (error) {
            thunkApi.dispatch(plannerActions.updateSearchResults([]));
            thunkApi.dispatch(plannerActions.updateMyPlacesSearchResults([]));
            thunkApi.rejectWithValue(JSON.stringify(error));
        }
    }
);

export const moveWaypointByIndexes = createAsyncThunk<
    void,
    { oldIndex: number; newIndex: number },
    { state: RootState }
>("plannerPage/moveWaypointByIndexes", async (data, thunkApi) => {
    const { oldIndex, newIndex } = data;
    const state = thunkApi.getState();

    const plannerInputToWaypointIndexes = selectWaypointIndexMappings(state).plannerInputToWaypointIndexes;
    thunkApi.dispatch(
        plannerActions.moveWaypoint({
            oldIndex: plannerInputToWaypointIndexes[oldIndex],
            newIndex: plannerInputToWaypointIndexes[newIndex]
        })
    );
    thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
    await thunkApi.dispatch(planRoute());
});

export const handleReverse = createAsyncThunk<void, void, { state: RootState }>(
    "plannerPage/handleReverse",
    async (data, thunkApi) => {
        thunkApi.dispatch(plannerActions.reverseRoute());
        thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
        await thunkApi.dispatch(planRoute());
    }
);

export const setTravelMode = createAsyncThunk<void, TravelMode, { state: RootState }>(
    "plannerPage/setTravelMode",
    async (data, thunkApi) => {
        thunkApi.dispatch(plannerActions.changeTravelMode(data));
        thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
        await thunkApi.dispatch(planRoute());
    }
);

export const setCostModel = createAsyncThunk<void, CostModel, { state: RootState }>(
    "plannerPage/setCostModel",
    async (data, thunkApi) => {
        thunkApi.dispatch(plannerActions.changeCostModel(data));
        thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
        await thunkApi.dispatch(planRoute());
    }
);

export const setHilliness = createAsyncThunk<void, LNHPreference, { state: RootState }>(
    "plannerPage/setHilliness",
    async (hilliness, thunkApi) => {
        const thrillingPreferences = selectCurrentPlannerParams(thunkApi.getState()).thrillingPreferences;
        thunkApi.dispatch(plannerActions.changeThrillingPreferences({ ...thrillingPreferences, hilliness }));
        thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
        await thunkApi.dispatch(planRoute());
    }
);

export const setWindiness = createAsyncThunk<void, LNHPreference, { state: RootState }>(
    "plannerPage/setWindiness",
    async (windingness, thunkApi) => {
        const thrillingPreferences = selectCurrentPlannerParams(thunkApi.getState()).thrillingPreferences;
        thunkApi.dispatch(plannerActions.changeThrillingPreferences({ ...thrillingPreferences, windingness }));
        thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
        await thunkApi.dispatch(planRoute());
    }
);

const revGeoDraggedHardWaypoint = createAsyncThunk<void, Point, { state: RootState }>(
    "plannerPage/revGeoDraggedHardWaypoint",
    async (point, thunkApi) => {
        const state = thunkApi.getState();
        const serviceUrls = selectServiceUrls(state);
        const routeCalculationState = state.mapPage.planner.routeCalculation;
        const locationInfo = await reverseGeocode(
            { lat: point[0], lng: point[1] },
            state.globalConfiguration.apiKey,
            i18next.language,
            serviceUrls
        );
        // defensive re-check on the existence of the dragging index to avoid a race condition with drag ending:
        if (routeCalculationState.draggingWaypointIndex != null) {
            thunkApi.dispatch(
                plannerActions.setWaypointLocationInfo({
                    locationInfo,
                    index: routeCalculationState.draggingWaypointIndex
                })
            );
        }
    }
);

export const updateDraggingWaypoint = createAsyncThunk<void, Point, { state: RootState }>(
    "plannerPage/updateDraggingWaypoint",
    async (point, thunkApi) => {
        const plannerState = thunkApi.getState().mapPage.planner;
        // (background rev-geocoding of dragged hard waypoint if necessary)
        const index = plannerState.routeCalculation.draggingWaypointIndex;
        if (index != null) {
            const draggingWaypoint = plannerState.currentPlannerParams.sortedGeoInputs[index];
            if (draggingWaypoint.type === "HARD") {
                thunkApi.dispatch(revGeoDraggedHardWaypoint(point));
            }
        }
        thunkApi.dispatch(plannerActions.setDraggingWaypoint(point));
        await thunkApi.dispatch(planRoute());
    }
);

export const setDepartTime = createAsyncThunk<void, number, { state: RootState }>(
    "plannerPage/setDepartTime",
    async (time, thunkApi) => {
        thunkApi.dispatch(plannerActions.changeDepartTime(time));
        thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
        await thunkApi.dispatch(planRoute());
    }
);

export const setArriveTime = createAsyncThunk<void, number, { state: RootState }>(
    "plannerPage/setArriveTime",
    async (time, thunkApi) => {
        thunkApi.dispatch(plannerActions.changeArriveTime(time));
        thunkApi.dispatch(plannerActions.setRouteCalculationContext("DEFAULT"));
        await thunkApi.dispatch(planRoute());
    }
);

export const changeRouteSelection = createAsyncThunk<
    void,
    { index: number; selectedFrom: "PLANNER" | "MAP" | "DEFAULT" },
    { state: RootState }
>("plannerPage/changeRouteSelection", async ({ index, selectedFrom }, thunkApi) => {
    thunkApi.dispatch(plannerActions.changeRouteSelection({ index, selectedFrom }));
    thunkApi.getState().globalConfiguration.featureConfigs.enableLDEV &&
        thunkApi.dispatch(plannerActions.updateGeoInputsWaypoints(index));
});

export const handleSelectWaypoint = createAsyncThunk<
    void,
    {
        result: TTMLocation;
        index: number;
        focusedInputIndex: number;
        centeringOptions: any;
        additionalSearchOption?: AdditionalSearchOption;
    },
    { state: RootState }
>("plannerPage/handleSelectWaypoint", async ({ result, focusedInputIndex, additionalSearchOption }, thunkApi) => {
    let method: string, favourite: boolean;
    // default value, override in case of current location
    let isCurrentLocation = false;
    if (!isNil(additionalSearchOption)) {
        switch (additionalSearchOption) {
            case AdditionalSearchOption.MY_PLACE:
                method = IN_LINE_PLANNER;
                favourite = true;
                break;
            case AdditionalSearchOption.HISTORY:
                method = IN_LINE_PLANNER;
                break;
            case AdditionalSearchOption.CURRENT_LOCATION:
                isCurrentLocation = true;
                method = "current_location";
                break;
        }
    } else {
        method = IN_LINE_PLANNER;
    }

    await thunkApi.dispatch(
        actions.handleAddWaypoint({
            waypoint: {
                type: "HARD",
                pointLatLon: getPoint(result),
                locationInfo: getLocationInfo(result),
                isCurrentLocation,
                context: result.context
            },
            index: { value: focusedInputIndex, addMode: "REPLACE" },
            method,
            favourite
        })
    );
});

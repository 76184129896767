import { service } from "@anw/gor-sdk";

import { signOut } from "../../authentication/thunks";
import { AuthCheck, createRetryAsyncThunk } from "../../../retryAsyncThunk";

export const onClearData = createRetryAsyncThunk<void, void>(
    "settings/clearData",
    AuthCheck.EAGER,
    async (data, thunkApi) => {
        await service().api.users.deleteUserData({});
        // "deleteUserData" deletes all your GOR and NC data, and also kills the NC session.
        // Therefore reusing the "signOut" action helps the UI to finish the logout process.
        thunkApi.dispatch(signOut(true));
    }
);

export const onDownloadData = createRetryAsyncThunk<void, void>("settings/downloadData", AuthCheck.EAGER, async () => {
    const locationCollections = await service().api.userMapLocationCollections.fetch();
    const favCollectionId = locationCollections.find((collection) => collection.type === "FAVOURITES")?.id;
    const places = await service().api.userMapLocationCollections.fetchSingle({
        parameters: { id: favCollectionId }
    });

    const routes = await service().api.itineraries.fetchItineraries({
        parameters: { viewType: "SUMMARY" }
    });

    const userData = {
        places: places.locations || [],
        routes: routes
    };

    const element = document.createElement("a");
    element.setAttribute("href", window.URL.createObjectURL(new Blob([JSON.stringify(userData, null, 2)])));
    element.setAttribute("download", "userData.json");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
});

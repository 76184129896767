import React, { InputHTMLAttributes, ReactNode, useRef, useState, useEffect, ChangeEvent } from "react";
import uniqueId from "lodash/uniqueId";
import classNames from "classnames";
import "../../../../sass/ui-library/components/base/Input.scss";
import { IcClose16, IcAlert16, IcSearch16, IcCheck16, IcChevronDown16, IcChevronUp16 } from "../../icons";
import { Body } from "./Typography";
import bem from "../../utils/bem";
import { ParentQaId, qaId, QaId } from "../../utils/qa-id";
import { Checkbox } from "./Toggles";
import isNil from "lodash/isNil";

export type HelperTextProps = ParentQaId & {
    feedbackId: string;
    error?: boolean;
    success?: boolean;
    helperTextLeft?: string;
    helperTextRight?: string;
};

export const HelperText = ({
    error,
    feedbackId,
    helperTextLeft,
    helperTextRight,
    success,
    parentQaId
}: HelperTextProps) => (
    <div
        id={feedbackId}
        className={classNames("d-flex justify-content-between input-control__helper-text", {
            "invalid-feedback": error,
            "valid-feedback": success
        })}
        {...parentQaId.element("helper-text")}
    >
        <Body size="s" className="t">
            {helperTextLeft}
        </Body>
        <Body size="s" className="">
            {helperTextRight}
        </Body>
    </div>
);
const inputControl = bem("input-control");

type InputControlBaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "value"> & {
    controlClassName?: string;
    category?: string;
    success?: boolean;
    error?: boolean;
    onClear?: () => void;
    value?: string;
    label?: string;
    helperTextLeft?: string;
    helperTextRight?: string;
    type?: "text" | "email" | "password" | "number" | "search" | "tel";
};

export type InputControlProps = InputControlBaseProps & {
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    onIconRightClick?: () => void;
};

export type InputControlWithUnitProps = InputControlBaseProps & {
    unitText: string;
};

export type PasswordControlProps = Omit<InputControlProps, "type"> & {
    showPassword?: boolean;
    onShowPasswordChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
    showPasswordText?: string;
};

export type SearchProps = Omit<InputControlProps, "iconRight">;

export type InputAsDropdownHeaderProps = Omit<InputControlProps, "iconRight" | "onIconRightClick"> & {
    selected?: boolean;
    onDropdownClick?: () => void;
};

type InputControlComponentProps = InputControlProps & Partial<InputControlWithUnitProps>;

const InputControlComponent = (props: InputControlComponentProps) => {
    const {
        className,
        category,
        success,
        error,
        iconLeft,
        iconRight,
        onIconRightClick,
        unitText,
        onClear,
        label,
        helperTextLeft,
        helperTextRight,
        controlClassName,
        placeholder = " ",
        type = "text",
        ...inputProps
    } = props;
    const isValueSet = props.value?.length > 0;
    const id = props.id || uniqueId("form-floating");
    const inputControlQa = useRef<QaId>(qaId(props.id || "input-control"));
    const checkOrIconRightIcon = success ? <IcCheck16 /> : iconRight;
    const iconRightDerived = error ? <IcAlert16 /> : checkOrIconRightIcon;
    const feedbackId = `${id}Feedback`;

    return (
        <div
            {...inputControlQa.current.block()}
            className={classNames(inputControl.block(), controlClassName, {
                "has-inner-left": iconLeft,
                "has-inner-right": iconRightDerived || (onClear && isValueSet),
                "form-floating": label,
                [inputControl.modifier("unit")]: unitText,
                [inputControl.modifier("category")]: category,
                [inputControl.modifier("invalid")]: error
            })}
        >
            <input
                {...inputProps}
                className={classNames("form-control", className, {
                    "is-valid": success,
                    "is-invalid": error
                })}
                type={type}
                id={id}
                aria-describedby={feedbackId}
                placeholder={placeholder}
                {...inputControlQa.current.element("input")}
            />
            {iconLeft && (
                <span className="icon is-left" {...inputControlQa.current.element("icon-left")}>
                    {iconLeft}
                </span>
            )}
            {(onClear && isValueSet && (
                <button
                    type="button"
                    className="is-right"
                    onClick={onClear}
                    {...inputControlQa.current.element("clear")}
                >
                    <IcClose16 />
                </button>
            )) ||
                (iconRightDerived &&
                    ((onIconRightClick && (
                        <button
                            type="button"
                            className="icon is-right"
                            {...inputControlQa.current.element("icon-right")}
                            onClick={onIconRightClick}
                        >
                            {iconRightDerived}
                        </button>
                    )) || (
                        <span className="icon is-right" {...inputControlQa.current.element("icon-right")}>
                            {iconRightDerived}
                        </span>
                    )))}
            {unitText && <span className="unit">{unitText}</span>}
            {label && (
                <label className="accent-text" htmlFor={id} {...inputControlQa.current.element("label")}>
                    {label}
                </label>
            )}
            {(helperTextLeft || helperTextRight) && (
                <HelperText
                    feedbackId={feedbackId}
                    error={error}
                    success={success}
                    parentQaId={inputControlQa.current}
                    helperTextLeft={helperTextLeft}
                    helperTextRight={helperTextRight}
                />
            )}
        </div>
    );
};

export const Search = ({ controlClassName, iconLeft, ...rest }: SearchProps) => {
    return (
        <InputControlComponent
            controlClassName={classNames(inputControl.modifier("search"), controlClassName)}
            iconLeft={iconLeft || <IcSearch16 />}
            {...rest}
        />
    );
};

export const InputControl = (props: InputControlProps) => {
    return <InputControlComponent {...props} />;
};

export const PasswordControl = ({
    showPassword = false,
    id,
    onShowPasswordChange,
    showPasswordText,
    ...rest
}: PasswordControlProps) => {
    const [show, setShow] = useState<boolean>(showPassword);
    const baseId = useRef(id || uniqueId("login-password"));

    useEffect(() => {
        setShow(showPassword);
    }, [showPassword]);

    const type = show ? "text" : "password";

    return (
        <>
            <div className="input-group">
                <InputControlComponent
                    {...rest}
                    id={baseId.current}
                    type={type}
                    {...(type === "password" && { autoComplete: "current-password" })}
                />
            </div>
            <div className="input-group">
                <Checkbox
                    id={`${baseId.current}-show-password`}
                    className="mt-1"
                    text={isNil(showPasswordText) ? "Show Password" : showPasswordText}
                    checked={show}
                    onChange={(event) => {
                        onShowPasswordChange(event);
                        setShow(event.currentTarget.checked);
                    }}
                    qaIdModifier={"show-password"}
                />
            </div>
        </>
    );
};

export const InputControlWithUnit = (props: InputControlWithUnitProps) => {
    return <InputControlComponent {...props} />;
};

export const InputAsDropdownHeader = ({
    controlClassName,
    selected,
    onDropdownClick,
    ...rest
}: InputAsDropdownHeaderProps) => {
    return (
        <InputControlComponent
            controlClassName={classNames(inputControl.modifier("dropdown-header"), controlClassName, {
                [inputControl.modifier("selected")]: selected
            })}
            iconRight={selected ? <IcChevronUp16 /> : <IcChevronDown16 />}
            onIconRightClick={onDropdownClick}
            {...rest}
        />
    );
};

export default InputControl;

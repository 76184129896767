import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AutocompleteSegment } from "./autocompleteAPITypes";
import { MatchedAutoCompleteSearch, SearchIntention } from "./types";
import { TTMSearchableResult, TTMSearchResult, TTMUserMapLocation } from "../../../../utils/locationTypes";
import { getLocalStorageJsonArray } from "../../../../utils/localStorage";
import { withSearchTexts } from "../../../../utils/search";

export const recentSearchesKey = "recent-searches";

const defaultMatchedAutoCompleteSearch: MatchedAutoCompleteSearch = {
    searchIntention: null,
    matchedBrand: "",
    matchedCategory: "",
    matchedTextValue: "",
    result: null as TTMSearchResult
};

const initialState = {
    inputString: "",
    searchBoxInputConfirmed: false,
    searchResults: [] as TTMSearchResult[],
    myPlacesSearchResults: [] as TTMUserMapLocation[],
    historySearchResults: [] as TTMSearchableResult[],
    recentSearches: withSearchTexts(getLocalStorageJsonArray<TTMSearchResult>(recentSearchesKey)),
    // results used only to calculate the viewport and never get displayed in the list
    viewportCheckTempResults: [] as TTMSearchResult[],
    areLatestResultsDisplayed: true,
    autocompleteSegments: [] as AutocompleteSegment[],
    areAutocompleteDisplayed: true,
    isSearchResultFetching: false,
    searchResultsOnMap: [] as TTMSearchResult[],
    matchedAutoCompleteSearch: { ...defaultMatchedAutoCompleteSearch },
    isAutoCompleteSegmentSelectedByUser: false,
    areResultsOutsideViewport: false,
    searchNeedsUpdate: false,
    selectedResultFeatureIndex: null as number,
    hoveredSearchResultIndex: null as number,
    highlightedSearchResultIndex: null as number,
    lastRequestId: 0,
    isFirstTimeLoaded: true,
    searchFormFocused: false,
    categoryShortcutTriggered: false,
    errors: {
        noResultsFound: false,
        freeError: null
    }
};

const searchFulfilled: Pick<typeof initialState, "errors" | "isSearchResultFetching"> = {
    errors: {
        noResultsFound: false,
        freeError: null
    },
    isSearchResultFetching: false
};

const searchPending: Pick<typeof initialState, "errors" | "isSearchResultFetching"> = {
    errors: {
        noResultsFound: false,
        freeError: null
    },
    isSearchResultFetching: true
};

const searchFailed: Pick<typeof initialState, "searchBoxInputConfirmed" | "isSearchResultFetching"> = {
    searchBoxInputConfirmed: false,
    isSearchResultFetching: false
};

export const search = createSlice({
    name: "searchPage",
    initialState,
    reducers: {
        changeSearchInput: (state, action: PayloadAction<string>) => {
            state.searchBoxInputConfirmed = false;
            state.inputString = action.payload;
        },
        confirmSearchInput: (state, action: PayloadAction<boolean>) => {
            state.searchBoxInputConfirmed = action.payload;
        },
        updateMatchedAutoCompleteSearch: (state, action: PayloadAction<MatchedAutoCompleteSearch>) => {
            state.matchedAutoCompleteSearch = action.payload;
            if (action.payload.searchIntention == SearchIntention.RECOVERY) {
                state.searchResultsOnMap = [];
                state.searchBoxInputConfirmed = false;
                state.isSearchResultFetching = false;
            }
        },
        updateUserIntention: (state, action: PayloadAction<SearchIntention>) => {
            state.matchedAutoCompleteSearch.searchIntention = action.payload;
        },
        updateSelectedResultIndex: (state, action: PayloadAction<number>) => {
            state.selectedResultFeatureIndex = action.payload;
        },
        clearSelectedResultIndex: (state) => {
            state.selectedResultFeatureIndex = null;
        },
        hoveredSelectedResultIndex: (state, action: PayloadAction<number>) => {
            state.hoveredSearchResultIndex = action.payload;
        },
        highlightedSelectedResultIndex: (state, action: PayloadAction<number>) => {
            state.highlightedSearchResultIndex = action.payload;
        },
        changeAreResultsOutsideViewport: (state, action: PayloadAction<boolean>) => {
            state.areResultsOutsideViewport = action.payload;
        },
        changeAreAutocompleteDisplayed: (state, action: PayloadAction<boolean>) => {
            state.areAutocompleteDisplayed = action.payload;
        },
        updateIsAutoCompleteSegmentSelectedByUser: (state, action: PayloadAction<boolean>) => {
            state.isAutoCompleteSegmentSelectedByUser = action.payload;
        },
        clearSearchResults: (state) => {
            state.inputString = "";
            state.searchResults = [];
            state.myPlacesSearchResults = [];
            state.historySearchResults = [];
            state.searchResultsOnMap = [];
            state.autocompleteSegments = [];
            state.areResultsOutsideViewport = false;
            state.matchedAutoCompleteSearch = { ...defaultMatchedAutoCompleteSearch };
            state.areAutocompleteDisplayed = true;
            state.lastRequestId = 0;
            state.errors.noResultsFound = false;
        },
        clearSearchResultsOnMap: (state) => {
            state.searchResultsOnMap = [];
        },
        setSearchNeedsUpdate: (state, action: PayloadAction<boolean>) => {
            state.searchNeedsUpdate = action.payload;
        },
        setIsFirstTimeLoaded: (state, action: PayloadAction<boolean>) => {
            state.isFirstTimeLoaded = action.payload;
        },
        setAreLatestResultsDisplayed: (state, action: PayloadAction<boolean>) => {
            state.areLatestResultsDisplayed = action.payload;
        },
        setSearchFormFocused: (state, action: PayloadAction<boolean>) => {
            state.searchFormFocused = action.payload;
        },
        setSearchError: (state, action) => {
            Object.assign(state, searchFailed);
            state.errors.freeError = action.payload;
            state.lastRequestId = 0;
        },
        setNoResultsFoundError: (state, action: PayloadAction<boolean>) => {
            Object.assign(state, searchFailed);
            state.errors.noResultsFound = action.payload;
            state.lastRequestId = 0;
        },
        setPendingRequest: (state, action: PayloadAction<{ requestId: number }>) => {
            Object.assign(state, searchPending);
            state.lastRequestId = action.payload.requestId;
        },
        setSearchAndAutocompleteFinal: (
            state,
            action: PayloadAction<{
                results: TTMSearchResult[];
            }>
        ) => {
            const { results } = action.payload;
            Object.assign(state, searchFulfilled);
            state.searchResults = results;
            state.searchNeedsUpdate = false;
            state.lastRequestId = 0;
        },
        setSearchAndAutocompleteTemporary: (
            state,
            action: PayloadAction<{
                results: TTMSearchResult[];
            }>
        ) => {
            const { results } = action.payload;
            Object.assign(state, searchFulfilled);
            state.viewportCheckTempResults = results;
            state.searchNeedsUpdate = false;
        },
        resetAutocomplete: (state) => {
            // reset autocomplete matched results
            state.matchedAutoCompleteSearch = { ...defaultMatchedAutoCompleteSearch };
        },
        updateMyPlacesSearchResults: (state, action: PayloadAction<TTMUserMapLocation[]>) => {
            state.myPlacesSearchResults = action.payload;
        },
        updateHistorySearchResults: (state, action: PayloadAction<TTMSearchableResult[]>) => {
            state.historySearchResults = action.payload;
        },
        setSearchCategoryBrandFinal: (state, action: PayloadAction<TTMSearchResult[]>) => {
            Object.assign(state, searchFulfilled);
            state.searchResults = action.payload;
            state.searchResultsOnMap = action.payload;
            state.lastRequestId = 0;
        },
        setSearchCategoryBrandTemporary: (state, action: PayloadAction<TTMSearchResult[]>) => {
            Object.assign(state, searchFulfilled);
            state.viewportCheckTempResults = action.payload;
        },
        setCategoryShortcutTriggered: (state, action: PayloadAction<boolean>) => {
            state.categoryShortcutTriggered = action.payload;
        },
        setRecentSearches: (state, action: PayloadAction<TTMSearchableResult[]>) => {
            state.recentSearches = action.payload;
        }
    }
});

export const searchActions = search.actions;

export default search.reducer;

import React, { Children, HTMLAttributes, PropsWithChildren, ReactNode, useEffect, JSX } from "react";
import classNames from "classnames";

import "../../../../sass/ui-library/components/base/Tabs.scss";
import { ParentQaId, QaIdModifier } from "../../utils/qa-id";
import { bem } from "../../utils/bem";
import { IconSelect } from "./IconSelect";
import { ButtonContainer } from "./Button";
import useQaId from "../../hooks/useQaId";

const tabsBem = bem("tabs");
const tabBem = bem("tab");
const tabPanelBem = bem("tab-panel");

export type TabProps = QaIdModifier<HTMLAttributes<HTMLLIElement>> &
    ParentQaId &
    PropsWithChildren<{
        text: string;
        active?: boolean;
        disabled?: boolean;
        icon?: ReactNode;
        index: number;
        onTabClicked?: () => void;
        enabledTabs?: unknown;
        disabledTabs?: unknown;
        activeDisabled?: unknown;
    }>;

export const Tab = ({
    blockElement,
    qaIdModifier,
    parentQaId,
    className,
    text,
    icon,
    onTabClicked,
    active,
    disabled,
    ...rest
}: Omit<TabProps, "index">) => {
    const tabQa = useQaId("tab", parentQaId, qaIdModifier);
    return (
        <li
            className={classNames(tabsBem.element("tab"), className, tabBem.block(), {
                [tabBem.modifier("active")]: active,
                [tabBem.modifier("disabled")]: disabled
            })}
            {...rest}
            {...tabQa.block(blockElement)}
        >
            <ButtonContainer
                onClick={onTabClicked}
                className={classNames(tabBem.element("action"), {
                    active: active,
                    [tabBem.modifier("disabled")]: disabled
                })}
                {...tabQa.element("action")}
                disabled={disabled}
            >
                <IconSelect
                    value={text}
                    icon={icon}
                    type={active ? "accent" : "base"}
                    iconSize={"24"}
                    state={disabled ? "disabled" : "default"}
                    parentQaId={tabQa.qaId()}
                />
            </ButtonContainer>
        </li>
    );
};

export type TabsProps = QaIdModifier<HTMLAttributes<HTMLDivElement>> & ParentQaId & { defaultIndex?: number };

export const Tabs = ({
    blockElement,
    qaIdModifier,
    parentQaId,
    className,
    children,
    defaultIndex = 0,
    ...rest
}: TabsProps) => {
    const tabsQa = useQaId("tabs", parentQaId, qaIdModifier);

    const [activeTabIndex, setActiveTabIndex] = React.useState(defaultIndex);
    const changeTab = (newIndex) => {
        setActiveTabIndex(newIndex);
    };

    useEffect(() => {
        changeTab(defaultIndex);
    }, [defaultIndex]);

    const tabItems = Children.toArray(children).filter((child: JSX.Element) => child?.type == Tab);
    return (
        <div className={classNames(tabsBem.block(), className)} {...rest} {...tabsQa.block(blockElement)}>
            <ul className={classNames(tabsBem.element("menu"))}>
                {tabItems.map(
                    (
                        { props: { text, icon, disabled, onTabClicked, qaIdModifier: tabQaIdModifier } }: JSX.Element,
                        index
                    ) => (
                        <Tab
                            key={`tab-${text}`}
                            text={text}
                            icon={icon}
                            disabled={disabled}
                            active={activeTabIndex === index}
                            onTabClicked={() => {
                                onTabClicked && onTabClicked();
                                changeTab(index);
                            }}
                            qaIdModifier={tabQaIdModifier || index}
                        />
                    )
                )}
            </ul>
            <div className={classNames(tabsBem.element("content"))}>
                {tabItems.map(
                    ({ props: { children, text } }: JSX.Element, index) =>
                        children && (
                            <div
                                className={classNames(tabPanelBem.block(), {
                                    [tabPanelBem.modifier("active")]: activeTabIndex === index
                                })}
                                key={`tab-panel-${text}`}
                                {...tabsQa.element(`tab-panel-${index}`)}
                            >
                                {children}
                            </div>
                        )
                )}
            </div>
        </div>
    );
};

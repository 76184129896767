import { actions as reducerActions } from "./reducers";
import * as thunks from "./thunks";
import * as userActionThunks from "./userActionThunks";

export const actions = {
    ...reducerActions,
    ...thunks,
    ...userActionThunks
};

export * as selectors from "./selectors";

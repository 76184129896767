import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { arrayMoveImmutable } from "array-move";
import {
    AvoidOption,
    CostModel,
    ItineraryPlanningRequest,
    ItineraryPlanningResponse,
    Point,
    ThrillingPreferences,
    TravelMode
} from "@anw/gor-sdk";
import compact from "lodash/compact";
import isNil from "lodash/isNil";
import { Feature, FeatureCollection, LineString } from "geojson";
import { Polygon } from "@turf/helpers";

import { settingsSlice, VehicleSettings } from "../settings/reducers";
import { withInsertionAt, withRemovalAt } from "../../../../utils/lists";
import { toPlanningVehicleParameters } from "../../../../utils/vehicleMeasurements";
import {
    TTMLocationInfo,
    TTMSearchableResult,
    TTMSearchResult,
    TTMUserMapLocation,
    TTMWaypoint
} from "../../../../utils/locationTypes";
import {
    isChargingStop,
    isRoundTrip,
    NewWaypointIndex,
    withoutChargingStops,
    withoutSoftWaypoints
} from "../../../../utils/waypoint";
import { TealiumLogger } from "../../../../classes/TealiumLogger";
import { PlannerMode } from "./constants";

const defaultThrillPref: ThrillingPreferences = { hilliness: "NORMAL", windingness: "NORMAL" };

export type RouteSelection = {
    index: number;
    selectedFrom: "PLANNER" | "MAP" | "DEFAULT";
    selectedAt: number;
};

/**
 * What originated the calculation of a route.
 */
export type PlanningActionContext = "DEFAULT" | "MAP_POPUP_ACTION" | "DRAGGING";

export type PlannedRouteInformation = {
    requestTimestamp: number;
    context: PlanningActionContext;
    effectiveRequest: ItineraryPlanningRequest;
    response: ItineraryPlanningResponse;
    responseFeatures: FeatureCollection<LineString>;
    lastRouteIsHistoricTrafficOne: boolean;
};

export type ReachableRangeInformation = {
    geoJson: Feature<Polygon>;
};

const initialState = {
    search: {
        query: "", // value for input tag
        results: [] as TTMSearchResult[],
        myPlacesSearchResults: [] as TTMUserMapLocation[],
        historySearchResults: [] as TTMSearchableResult[],
        lastRequestId: 0
    },
    hoveredWaypointIndex: null as number,
    highlightedWaypointIndex: null as number,
    routeSelection: {
        index: 0,
        selectedFrom: "PLANNER",
        selectedAt: Date.now()
    } as RouteSelection,
    routePathSelection: {
        startPointIndex: null as number,
        endPointIndex: null as number
    },
    currentPlannerParams: {
        timeIntervalMinutes: 60,
        distanceIntervalKMs: 100,
        costModel: "FASTEST",
        travelMode: "CAR",
        sortedGeoInputs: [null, null],
        includeGuidanceInFullView: true,
        compareWithHistoricTrafficItinerary: false,
        vehicleParameters: toPlanningVehicleParameters(settingsSlice.getInitialState().vehicleParameters)
    } as ItineraryPlanningRequest,
    routeCalculation: {
        // Request ID/timestamp of the last pending route calculation:
        lastPendingRequestTimestamp: 0,
        context: "DEFAULT" as PlanningActionContext,
        draggingWaypointIndex: null as number,
        draggingWaypointRadius: null as number
    },
    /**
     * After a route is planned, information about the request and its return
     * values are stored under `plannedRouteInformation`.
     */
    plannedRouteInformation: {
        requestTimestamp: 0,
        context: "DEFAULT",
        effectiveRequest: {},
        response: undefined,
        responseFeatures: undefined,
        lastRouteIsHistoricTrafficOne: false
    } as PlannedRouteInformation,

    reachableRangeInformation: {
        geoJson: undefined
    } as ReachableRangeInformation,

    errors: {
        routeCannotBePlanned: null as string
    },

    plannerMode: PlannerMode.HIDDEN
};

export const plannerPageSlice = createSlice({
    name: "plannerPage",
    initialState,
    reducers: {
        updateGeoInputsWaypoints: (state, action: PayloadAction<number>) => {
            const newWaypoints =
                state.plannedRouteInformation.response.plannedItineraries[action.payload].itinerary.segments[0]
                    .waypoints;
            state.currentPlannerParams.sortedGeoInputs = newWaypoints;
            // update effective request waypoints
            state.plannedRouteInformation.effectiveRequest.sortedGeoInputs = newWaypoints;
        },
        removeChargingStopsFromGeoInputs: (state) => {
            state.currentPlannerParams.sortedGeoInputs = state.currentPlannerParams.sortedGeoInputs.filter(
                (gi) => !isChargingStop(gi)
            );
        },
        updateSearchResults: (state, action: PayloadAction<TTMSearchResult[]>) => {
            state.search.results = action.payload;
        },
        updateMyPlacesSearchResults: (state, action: PayloadAction<TTMUserMapLocation[]>) => {
            state.search.myPlacesSearchResults = action.payload;
        },
        updateHistorySearchResults: (state, action: PayloadAction<TTMSearchableResult[]>) => {
            state.search.historySearchResults = action.payload;
        },
        clearSearchQuery: (state) => {
            state.search.query = "";
            state.search.results = [];
        },
        setSearchQuery: (state, action: PayloadAction<string>) => {
            state.search.query = action.payload;
        },
        setPendingSearchRequest: (state, action: PayloadAction<{ query: string; lastRequestId: number }>) => {
            state.search.query = action.payload.query;
            state.search.lastRequestId = action.payload.lastRequestId;
        },
        setLastPlannerRequestTimestamp: (state, action: PayloadAction<number>) => {
            state.routeCalculation.lastPendingRequestTimestamp = action.payload;
        },
        /**
         * Method parameter in action lets analytics know how the waypoint was added.
         * @param state
         * @param action
         */
        addWaypoint: (
            state,
            action: PayloadAction<{
                waypoint: TTMWaypoint;
                index: NewWaypointIndex;
                method: string;
                favourite?: boolean;
            }>
        ) => {
            state.routeCalculation.lastPendingRequestTimestamp = null;
            const { waypoint, index, method, favourite = false } = action.payload;
            const insertIndex = index.addMode === "INSERT" ? index.value : -1;
            const replaceIndex = index.addMode === "REPLACE" ? index.value : -1;
            const pointAtIndex = state.currentPlannerParams.sortedGeoInputs[insertIndex];
            if (replaceIndex === -1 && insertIndex !== -1) {
                // if desired insert index waypoint is filled add it to the index and shift other waypoints,
                if (pointAtIndex !== null) {
                    state.currentPlannerParams.sortedGeoInputs = withInsertionAt(
                        state.currentPlannerParams.sortedGeoInputs,
                        waypoint,
                        insertIndex
                    );
                    // and clear empty waypoint to calculate the route
                    const emptyPointIndex = state.currentPlannerParams.sortedGeoInputs.indexOf(null);
                    if (emptyPointIndex != -1) {
                        state.currentPlannerParams.sortedGeoInputs.splice(emptyPointIndex, 1);
                    }
                } else {
                    // if it's an empty waypoint, replace it with the added one
                    state.currentPlannerParams.sortedGeoInputs[insertIndex] = waypoint;
                }
            } else if (replaceIndex !== -1) {
                if (isRoundTrip(compact(state.currentPlannerParams.sortedGeoInputs)) && replaceIndex === 0) {
                    state.currentPlannerParams.sortedGeoInputs[0] = waypoint;
                    state.currentPlannerParams.sortedGeoInputs[state.currentPlannerParams.sortedGeoInputs.length - 1] =
                        waypoint;
                } else {
                    state.currentPlannerParams.sortedGeoInputs[replaceIndex] = waypoint;
                    const lastOrMidWaypointText =
                        replaceIndex === state.currentPlannerParams.sortedGeoInputs.length - 1
                            ? "add_destination"
                            : "add_stop";
                    TealiumLogger.link({
                        event_name: replaceIndex === 0 ? "add_start" : lastOrMidWaypointText,
                        method,
                        location_type: waypoint.locationInfo?.type,
                        favourite
                    });
                }
            } else {
                state.currentPlannerParams.unsortedGeoInputs = [waypoint];
                TealiumLogger.link({
                    event_name: "add_stop",
                    method,
                    location_type: waypoint.locationInfo?.type,
                    favourite
                });
            }
        },
        setWaypointLocationInfo: (state, action: PayloadAction<{ locationInfo: TTMLocationInfo; index: number }>) => {
            const waypoint = state.currentPlannerParams.sortedGeoInputs[action.payload.index] as TTMWaypoint;
            if (!isNil(waypoint)) {
                waypoint.locationInfo = action.payload.locationInfo;
            }
        },
        setDraggingWaypoint: (state, action: PayloadAction<Point>) => {
            state.routeCalculation.context = "DRAGGING";
            if (state.routeCalculation.draggingWaypointIndex != null) {
                // if we already know the index, we replace that sorted waypoint with new coordinates:
                const sortedWaypoint = state.currentPlannerParams.sortedGeoInputs[
                    state.routeCalculation.draggingWaypointIndex
                ] as TTMWaypoint;
                sortedWaypoint.pointLatLon = action.payload;
                // we ensure to keep the waypoint radius up to date:
                sortedWaypoint.radius = state.routeCalculation.draggingWaypointRadius || sortedWaypoint.radius;
            } else {
                const radius = state.routeCalculation.draggingWaypointRadius || 50;
                // otherwise, we generate new soft waypoint as unsorted
                state.currentPlannerParams.unsortedGeoInputs = [
                    { type: "SOFT", radius, pointLatLon: action.payload } as TTMWaypoint
                ];
            }
        },
        setDraggingWaypointIndex: (state, action: PayloadAction<number>) => {
            state.routeCalculation.draggingWaypointIndex = action.payload;
        },
        setDraggingWaypointRadius: (state, action: PayloadAction<number>) => {
            state.routeCalculation.draggingWaypointRadius = action.payload;
        },
        endRouteDrag: (state) => {
            state.routeCalculation.context = "DEFAULT";
            const lastPlannedRoute =
                state.plannedRouteInformation.response?.plannedItineraries?.[0].itinerary.segments[0];

            // if the last calculated route was from dragging: we'll stick the dragged soft waypoint to its path line:
            if (state.plannedRouteInformation.context == "DRAGGING") {
                const draggingWaypointIndex = state.routeCalculation.draggingWaypointIndex;
                if (draggingWaypointIndex != null && lastPlannedRoute) {
                    const waypointToStick = state.currentPlannerParams.sortedGeoInputs[
                        draggingWaypointIndex
                    ] as TTMWaypoint;
                    const routeWaypoint = lastPlannedRoute.waypoints[draggingWaypointIndex];
                    if (waypointToStick?.type === "SOFT") {
                        // We stick the request waypoint coords to the last route response waypoint mapped path point:
                        waypointToStick.pointLatLon = lastPlannedRoute.pathLatLonAlt[routeWaypoint.pathPointIndex] as [
                            number,
                            number
                        ];
                        // We overwrite the soft waypoint radius to a small value so the final calculation stays the same after sticking it:
                        waypointToStick.radius = 20;
                    }
                }
            }

            state.routeCalculation.draggingWaypointIndex = null;
            state.routeCalculation.draggingWaypointRadius = null;
        },
        addWaypointPlaceholder: (state) => {
            state.routeCalculation.lastPendingRequestTimestamp = 0;
            state.currentPlannerParams.sortedGeoInputs = withInsertionAt(
                state.currentPlannerParams.sortedGeoInputs,
                null,
                state.currentPlannerParams.sortedGeoInputs.length
            );
        },
        /**
         * Method parameter in action lets analytics know how the waypoint was removed.
         * @param state
         * @param action
         */
        removeWaypoint: (state, action: PayloadAction<{ index: number; method: string }>) => {
            state.routeCalculation.lastPendingRequestTimestamp = null;
            state.errors.routeCannotBePlanned = null;
            const { index } = action.payload;

            if (state.currentPlannerParams.sortedGeoInputs.length === 2) {
                state.currentPlannerParams.sortedGeoInputs[index] = null;
            } else {
                const waypointToRemove = state.currentPlannerParams.sortedGeoInputs[index];
                state.currentPlannerParams.sortedGeoInputs = withRemovalAt(
                    state.currentPlannerParams.sortedGeoInputs,
                    index
                );
                if (waypointToRemove && waypointToRemove.type !== "SOFT") {
                    // (For now we automatically clear soft waypoints if we removed a non-soft waypoint)
                    state.currentPlannerParams.sortedGeoInputs = withoutSoftWaypoints(
                        state.currentPlannerParams.sortedGeoInputs
                    );

                    // (we automatically clear charging stops as well)
                    state.currentPlannerParams.sortedGeoInputs = withoutChargingStops(
                        state.currentPlannerParams.sortedGeoInputs
                    );
                }
                if (state.currentPlannerParams.sortedGeoInputs.length === 1) {
                    state.currentPlannerParams.sortedGeoInputs.splice(index, 0, null);
                }
            }
        },
        moveWaypoint: (state, action: PayloadAction<{ oldIndex: number; newIndex: number }>) => {
            state.routeCalculation.lastPendingRequestTimestamp = 0;
            const { oldIndex, newIndex } = action.payload;
            state.currentPlannerParams.sortedGeoInputs = withoutSoftWaypoints(
                arrayMoveImmutable(state.currentPlannerParams.sortedGeoInputs, oldIndex, newIndex)
            );
        },
        clearCalculatedRouteAndWaypoints: (state) => {
            state.routeCalculation = initialState.routeCalculation;
            state.currentPlannerParams.sortedGeoInputs = initialState.currentPlannerParams.sortedGeoInputs;
            delete state.currentPlannerParams.unsortedGeoInputs;
            state.plannedRouteInformation = initialState.plannedRouteInformation;
            state.errors.routeCannotBePlanned = null;
            delete state.currentPlannerParams.departTime;
            delete state.currentPlannerParams.arriveTime;
        },
        clearCalculatedRoute: (state) => {
            state.plannedRouteInformation = initialState.plannedRouteInformation;
        },
        reverseRoute: (state) => {
            state.currentPlannerParams.sortedGeoInputs.reverse();
        },
        changeTravelMode: (state, action: PayloadAction<TravelMode>) => {
            state.currentPlannerParams.travelMode = action.payload;
        },
        changeCostModel: (state, action: PayloadAction<CostModel>) => {
            state.currentPlannerParams.costModel = action.payload;
            if (action.payload === "THRILLING") {
                state.currentPlannerParams.thrillingPreferences = defaultThrillPref;
            } else {
                delete state.currentPlannerParams.thrillingPreferences;
            }
        },
        changeThrillingPreferences: (state, action: PayloadAction<ThrillingPreferences>) => {
            state.currentPlannerParams.thrillingPreferences = action.payload;
        },
        changeDepartTime: (state, action: PayloadAction<number>) => {
            // request depart and arrive times are mutually exclusive:
            delete state.currentPlannerParams.arriveTime;
            if (action.payload && action.payload > new Date().getTime()) {
                state.currentPlannerParams.departTime = action.payload;
            } else {
                // date set in the past: we implicitly clear it (becomes "leave now")
                delete state.currentPlannerParams.departTime;
            }
        },
        changeArriveTime: (state, action: PayloadAction<number>) => {
            // request depart and arrive times are mutually exclusive:
            delete state.currentPlannerParams.departTime;
            if (action.payload && action.payload > new Date().getTime()) {
                state.currentPlannerParams.arriveTime = action.payload;
            } else {
                // date set in the past: we implicitly clear it (becomes "leave now")
                delete state.currentPlannerParams.arriveTime;
            }
        },
        changeRouteSelection: (state, action: PayloadAction<Omit<RouteSelection, "selectedAt">>) => {
            state.routeSelection = { ...action.payload, selectedAt: Date.now() };
        },
        setRoutePathSelection: (state, action: PayloadAction<[number, number]>) => {
            state.routePathSelection.startPointIndex = action.payload[0];
            state.routePathSelection.endPointIndex = action.payload[1];
        },
        toggleAvoidableOption: (state, action: PayloadAction<AvoidOption>) => {
            const avoidOptions = state.currentPlannerParams.avoidOptions;
            const index = avoidOptions?.indexOf(action.payload);
            if (!isNil(index) && index >= 0) {
                if (avoidOptions.length === 1) {
                    delete state.currentPlannerParams.avoidOptions;
                } else {
                    avoidOptions.splice(index, 1);
                }
            } else if (avoidOptions) {
                avoidOptions.push(action.payload);
            } else {
                state.currentPlannerParams.avoidOptions = [action.payload];
            }
        },
        hoverWaypointIndex: (state, action: PayloadAction<number>) => {
            state.hoveredWaypointIndex = action.payload;
            state.highlightedWaypointIndex = null;
        },
        highlightWaypointIndex: (state, action: PayloadAction<number>) => {
            state.highlightedWaypointIndex = action.payload;
        },
        updateVehicleParameters: (state, action: PayloadAction<VehicleSettings>) => {
            state.currentPlannerParams.vehicleParameters = toPlanningVehicleParameters(action.payload);
        },
        updateChargingParameters: (state, action: PayloadAction<any>) => {
            state.currentPlannerParams.vehicleParameters.chargingParameters.chargingParameters = action.payload;
        },
        setCurrentPlannerParams: (state, action: PayloadAction<ItineraryPlanningRequest>) => {
            state.currentPlannerParams = action.payload;
        },
        setPlannedRouteInformation: (state, action: PayloadAction<typeof initialState.plannedRouteInformation>) => {
            state.plannedRouteInformation = action.payload;
        },
        setRouteCannotBePlannedError: (state, action) => {
            state.errors.routeCannotBePlanned = action.payload;
            state.search.lastRequestId = 0;
        },
        updateReachableRange: (state, action: PayloadAction<GeoJSON.Feature<Polygon>>) => {
            state.reachableRangeInformation.geoJson = action.payload;
        },
        updatePlannerMode: (state, action: PayloadAction<PlannerMode>) => {
            state.plannerMode = action.payload;
        },
        setRouteCalculationContext: (state, action: PayloadAction<PlanningActionContext>) => {
            state.routeCalculation.context = action.payload;
        }
    }
});

export const { actions } = plannerPageSlice;

export default plannerPageSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { push, replace } from "connected-react-router";
import { ItineraryPlanningRequest } from "@anw/gor-sdk";
import queryString from "query-string";
import i18next from "i18next";

import { langParameter, URL_PATH_MAP } from "../../../components/elements/Links";
import { RootState } from "../../RootReducer";
import { encodeLocationURLPath } from "../../../utils/locationURL";
import { toFixedExtra, toFixedLngLat } from "../../../utils/numbers";
import { TTMLocation } from "../../../utils/locationTypes";
import { toURLPlanningCriteria } from "../../../utils/routeURL";
import RemoteLogger from "../../../classes/RemoteLogger";
import { AuthCheck, createRetryAsyncThunk } from "../../retryAsyncThunk";
import { ForegroundOption } from "../application/reducers";
import { selectPreviousForeground } from "../application/selectors";
import { selectLastSelectedLocation } from "../map-page/location/selectors";

type Viewport = {
    lng: number;
    lat: number;
    zoom: number;
    bearing?: number;
    pitch?: number;
};

const mapPositionParamsForViewport = (viewport: Viewport): string => {
    const zoomStr = viewport.zoom && `${toFixedExtra(viewport.zoom)}z`;
    const bearingStr = viewport.bearing && `${toFixedExtra(viewport.bearing)}b`;
    const pitchStr = viewport.pitch && `${toFixedExtra(viewport.pitch)}p`;

    return [toFixedLngLat(viewport.lat), toFixedLngLat(viewport.lng), zoomStr, bearingStr, pitchStr]
        .filter(Boolean)
        .join(",");
};

const stringifyQueryObject = (queryObject) => queryString.stringify(queryObject, { encode: false });

const parseQueryString = (inputString: string) => queryString.parse(inputString, { decode: false });

const dispatchPush = (thunkApi, pathName: string): void => {
    const location = thunkApi.getState().router.location;
    const pathnameFinal = pathName.replace(langParameter, `/${i18next.language}`);

    thunkApi.dispatch(
        push({
            pathname: pathnameFinal,
            hash: location.hash,
            search: location.search
        })
    );
};

export const navigateToPath = createAsyncThunk<void, string>("navigate/navigateToPath", (path, thunkApi) =>
    dispatchPush(thunkApi, path)
);

export const navigateToRoutePlan = createAsyncThunk<void>("navigate/navigateToRoutePlan", (data, thunkApi) =>
    dispatchPush(thunkApi, URL_PATH_MAP.ROUTE_PLAN)
);

export const navigateHome = createAsyncThunk<void>("navigate/navigateHome", (data, thunkApi) =>
    dispatchPush(thunkApi, URL_PATH_MAP.INDEX)
);

export const navigateTo404 = createAsyncThunk<void>("navigate/navigateTo404", (data, thunkApi) =>
    dispatchPush(thunkApi, URL_PATH_MAP.PAGE_404)
);

export const navigateToMyItemsWithAuth = createRetryAsyncThunk<void, void>(
    "navigate/navigateToMyItemsWithAuth",
    AuthCheck.EAGER,
    (data, thunkApi) => {
        dispatchPush(thunkApi, URL_PATH_MAP.MY_ITEMS_BASE);
    }
);

export const navigateToMyPlaces = createAsyncThunk<void, void>("navigate/navigateToMyPlaces", (data, thunkApi) =>
    dispatchPush(thunkApi, URL_PATH_MAP.MY_ITEMS_PLACES)
);

export const navigateToMyRoutes = createAsyncThunk<void, void>("navigate/navigateToMyRoutes", (data, thunkApi) =>
    dispatchPush(thunkApi, URL_PATH_MAP.MY_ITEMS_ROUTES)
);

export const navigateToRouteNewInfo = createAsyncThunk<void, void>(
    "navigate/navigateToRouteNewInfo",
    (data, thunkApi) => dispatchPush(thunkApi, URL_PATH_MAP.ROUTE_NEW_INFO)
);

export const navigateToRouteEditInfo = createAsyncThunk<void, string>(
    "navigate/navigateToRouteEditInfo",
    (itineraryId, thunkApi) => dispatchPush(thunkApi, `${URL_PATH_MAP.ROUTE_EDIT_INFO_BASE}/${itineraryId}`)
);

export const navigateToRouteEditLine = createAsyncThunk<void, string>(
    "navigate/navigateToRouteEditLine",
    (itineraryId, thunkApi) => dispatchPush(thunkApi, `${URL_PATH_MAP.ROUTE_EDIT_LINE_BASE}/${itineraryId}`)
);

export const navigateToRouteSync = createAsyncThunk<void, string>(
    "navigate/navigateToRouteSync",
    (itineraryId, thunkApi) => dispatchPush(thunkApi, `${URL_PATH_MAP.ROUTE_SYNC_BASE}/${itineraryId}`)
);

export const navigateToRouteView = createAsyncThunk<void, string>("navigate/navigateToRouteView", (id, thunkApi) =>
    dispatchPush(thunkApi, `${URL_PATH_MAP.ROUTE_VIEW_BASE}/${id}`)
);

export const navigateToLocation = createAsyncThunk<void, TTMLocation>(
    "navigate/navigateToLocation",
    (location, thunkApi) => {
        const urlPath = encodeLocationURLPath(location);
        if (urlPath) {
            dispatchPush(thunkApi, urlPath);
        } else {
            // ignore it. Should not happen
            console.error(`Tried to navigate to unknown place by result object: ${location}`);
            RemoteLogger.log({
                message: "Tried to navigate to unknown place by result object",
                category: "navigation.thunks",
                severity: "error",
                data: { location }
            });
        }
    }
);

export const navigateToLastSelectedLocation = createAsyncThunk<void, void, { state: RootState }>(
    "navigate/navigateToLastSelectedLocation",
    (data, thunkApi) => {
        thunkApi.dispatch(navigateToLocation(selectLastSelectedLocation(thunkApi.getState())));
    }
);

export const navigateToSettings = createAsyncThunk<void, void>("navigate/navigateToSettings", (data, thunkApi) =>
    dispatchPush(thunkApi, URL_PATH_MAP.SETTINGS)
);

export const navigateToLab = createAsyncThunk<void, void>("navigate/navigateToLab", (data, thunkApi) =>
    dispatchPush(thunkApi, URL_PATH_MAP.LABS)
);

export const navigateToPrevious = createAsyncThunk<void, void, { state: RootState }>(
    "navigate/navigateToPrevious",
    (data, thunkApi) => {
        const state = thunkApi.getState();
        const previousForeground = selectPreviousForeground(state) || ForegroundOption.DEFAULT_SEARCH;
        if (previousForeground === ForegroundOption.SELECTED_LOCATION) {
            // (navigating to selected location implies a dynamic URL path)
            // NOTE: selected location and foreground mode might not be fully aligned
            // (a 'current' selected location might exist while the current foreground mode is something else)
            thunkApi.dispatch(navigateToLastSelectedLocation());
        } else {
            dispatchPush(thunkApi, previousForeground);
        }
    }
);

export const updateWithViewport = createAsyncThunk<void, Viewport, { state: RootState }>(
    "navigate/updateWithViewport",
    (viewport, thunkApi) => {
        const location = thunkApi.getState().router.location;
        const newPositionString = mapPositionParamsForViewport(viewport);
        const parsedSearchParams = parseQueryString(location.search);

        if (newPositionString !== parsedSearchParams.p) {
            const search = `?${stringifyQueryObject({
                ...parsedSearchParams,
                p: newPositionString
            })}`;

            thunkApi.dispatch(
                push({
                    pathname: location.pathname,
                    hash: location.hash,
                    search
                })
            );
        }
    }
);

export const updateWithSearchQuery = createAsyncThunk<void, string, { state: RootState }>(
    "navigate/updateWithSearchQuery",
    (query, thunkApi) => {
        const location = thunkApi.getState().router.location;
        const { q, ...restParams } = parseQueryString(location.search);

        if (query !== (q || "")) {
            const search = `?${stringifyQueryObject({
                ...restParams,
                ...(query && { q: query })
            })}`;

            thunkApi.dispatch(
                replace({
                    pathname: location.pathname,
                    hash: location.hash,
                    search
                })
            );
        }
    }
);

export const updateWithPlanningCriteria = createAsyncThunk<void, ItineraryPlanningRequest, { state: RootState }>(
    "navigate/updateWithPlanningCriteria",
    (criteria, thunkApi) => {
        const location = thunkApi.getState().router.location;
        const { r, ...restParams } = parseQueryString(location.search);
        const urlPlanningCriteria = toURLPlanningCriteria(criteria);
        if (r !== urlPlanningCriteria) {
            const search = `?${stringifyQueryObject({
                ...restParams,
                ...(criteria && { r: urlPlanningCriteria })
            })}`;
            thunkApi.dispatch(
                push({
                    pathname: location.pathname,
                    hash: location.hash,
                    search
                })
            );
        }
    }
);

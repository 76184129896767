import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../RootReducer";
import {
    actions as globalConfigActions,
    actions as globalConfigurations,
    ServiceSource
} from "../global-configuration/reducers";
import { selectServiceSource } from "./selectors";

export const handleUpdateEndpoint = createAsyncThunk<void, ServiceSource, { state: RootState }>(
    "globalConfiguration/handleUpdateEndpoint",
    async (newServiceSource, thunkApi) => {
        // When switching endpoints, set the default mapstyle for it unless the mapstyle is shared
        // ORBIS: OM
        // GLOBAL + KOREA: DEFAULT
        const state = thunkApi.getState();
        const currentServiceSource = selectServiceSource(state);

        if (currentServiceSource == "ORBIS") {
            thunkApi.dispatch(
                globalConfigActions.putFeatureConfigs({
                    selectedMapStyle: { id: "DEFAULT" }
                })
            );
        } else if (newServiceSource == "ORBIS") {
            thunkApi.dispatch(
                globalConfigActions.putFeatureConfigs({
                    selectedMapStyle: { id: "OM" }
                })
            );
        }
        thunkApi.dispatch(globalConfigurations.putFeatureConfigs({ serviceSource: newServiceSource }));
    }
);

import { TTMSearchResult } from "../../../../utils/locationTypes";

export enum SearchIntention {
    RECOVERY = "recovery",
    DISCOVERY = "discovery"
}

export type MatchedAutoCompleteSearch = {
    searchIntention: SearchIntention;
    matchedBrand: string;
    matchedCategory: string;
    matchedTextValue: string;
    result?: TTMSearchResult;
};

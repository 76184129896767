import { Itinerary, UserMapLocation, UserMapLocationType } from "@anw/gor-sdk";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { AuthCheck, createRetryAsyncThunk } from "../../../retryAsyncThunk";
import { logEventWithActiveMode } from "../../application/thunks";
import { actions as myItemsActions } from "./reducers";
import { TTMLocation, TTMLocationContext } from "../../../../utils/locationTypes";
import { RootState } from "../../../RootReducer";
import { getLocationInfo } from "../../../../utils/location";
import { deleteItinerary, importItinerary, removePlace, savePlace } from "./thunks";
import { TealiumLogger } from "../../../../classes/TealiumLogger";
import { syncRoute } from "../route-sync/thunks";
import { navigateToRouteNewInfo } from "../../navigation/thunks";
import { IN_LINE_PLANNER, LOCATION_PANEL, MAP_CONTEXTUAL_PANEL, MYITEMS_PLACES } from "../../../../utils/analytics";
import { actions as applicationActions } from "../../application/reducers";

const calculateEventInfoForRemoveFavourite = (type: UserMapLocationType) => {
    let selectedItem, eventName;
    switch (type) {
        case "GENERIC":
            selectedItem = "remove_place";
            eventName = "place_deleted";
            break;
        case "HOME":
            selectedItem = "remove_home";
            eventName = "home_removed";
            break;
        case "WORK":
            selectedItem = "remove_work";
            eventName = "work_removed";
            break;
    }
    return { selectedItem, eventName };
};

export const onEditPlace = createRetryAsyncThunk<void, Partial<UserMapLocation>>(
    "myItems/onEditPlace",
    AuthCheck.EAGER,
    async (data, thunkApi) => {
        thunkApi.dispatch(myItemsActions.setPlaceBeingEdited(data));
    }
);

export const onAddPlacePopup = createAsyncThunk<void, TTMLocation, { state: RootState }>(
    "myItems/onAddPlacePopup",
    async (data: TTMLocation, thunkApi) => {
        const rightClick = data.context === TTMLocationContext.MAP_POINT;
        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: rightClick ? "map_rightclick_panel_action" : "map_contextual_panel_action",
                selected_item: "add_favourite",
                method: "primary_action"
            })
        );
        thunkApi.dispatch(myItemsActions.setPlaceBeingEditedOrigin(MAP_CONTEXTUAL_PANEL));
        const locationInfo = getLocationInfo(data);
        thunkApi.dispatch(
            applicationActions.setLoginAnalyticsInfo({ method: MAP_CONTEXTUAL_PANEL, trigger: "favourite" })
        );
        thunkApi.dispatch(
            onEditPlace({
                mapLocation: {
                    pointLatLon: locationInfo.point,
                    locationInfo
                }
            })
        );
    }
);

export const onAddPlaceDetails = createAsyncThunk<void, Partial<UserMapLocation>, { state: RootState }>(
    "myItems/onAddPlaceDetails",
    async (data: Partial<UserMapLocation>, thunkApi) => {
        thunkApi.dispatch(
            logEventWithActiveMode({
                event_name: "location_panel_action",
                selected_item: "add_favourite",
                method: "primary_action"
            })
        );
        thunkApi.dispatch(myItemsActions.setPlaceBeingEditedOrigin(LOCATION_PANEL));
        thunkApi.dispatch(applicationActions.setLoginAnalyticsInfo({ method: LOCATION_PANEL, trigger: "favourite" }));
        thunkApi.dispatch(onEditPlace(data));
    }
);

export const onRemovePlaceFromPopup = createAsyncThunk<
    void,
    { id: string; type: UserMapLocationType },
    { state: RootState }
>("myItems/onRemovePlacePopup", async ({ id, type }, thunkApi) => {
    const { selectedItem, eventName } = calculateEventInfoForRemoveFavourite(type);
    thunkApi.dispatch(
        logEventWithActiveMode({
            event_name: "map_contextual_panel_action",
            selected_item: selectedItem,
            method: "primary_action"
        })
    );
    TealiumLogger.link({
        event_name: eventName,
        method: MAP_CONTEXTUAL_PANEL
    });
    thunkApi.dispatch(applicationActions.setLoginAnalyticsInfo({ method: MAP_CONTEXTUAL_PANEL, trigger: null }));
    thunkApi.dispatch(removePlace(id));
});

export const onRemovePlaceFromList = createAsyncThunk<
    void,
    { id: string; type: UserMapLocationType },
    { state: RootState }
>("myItems/onRemovePlaceList", async ({ id, type }, thunkApi) => {
    const { eventName } = calculateEventInfoForRemoveFavourite(type);
    TealiumLogger.link({
        event_name: eventName,
        method: MYITEMS_PLACES
    });
    thunkApi.dispatch(applicationActions.setLoginAnalyticsInfo({ method: MYITEMS_PLACES, trigger: null }));
    thunkApi.dispatch(removePlace(id));
});

export const onRemovePlaceDetails = createAsyncThunk<
    void,
    { id: string; type: UserMapLocationType },
    { state: RootState }
>("myItems/onRemovePlaceDetails", async ({ id, type }, thunkApi) => {
    const { selectedItem, eventName } = calculateEventInfoForRemoveFavourite(type);
    thunkApi.dispatch(
        logEventWithActiveMode({
            event_name: "location_panel_action",
            selected_item: selectedItem,
            method: "primary_action"
        })
    );
    TealiumLogger.link({
        event_name: eventName,
        method: LOCATION_PANEL
    });
    thunkApi.dispatch(applicationActions.setLoginAnalyticsInfo({ method: LOCATION_PANEL, trigger: null }));
    thunkApi.dispatch(removePlace(id));
});

export const onSyncItinerary = createAsyncThunk<
    void,
    { itinerary?: Itinerary; itineraryId: string; sync?: boolean; method: string },
    { state: RootState }
>("myItems/onSyncItinerary", async ({ itinerary, itineraryId, sync = true, method }, thunkApi) => {
    TealiumLogger.link({
        event_name: sync ? "route_send_cloud" : "route_removed_cloud",
        method
    });
    thunkApi.dispatch(applicationActions.setLoginAnalyticsInfo({ method, trigger: null }));
    await thunkApi.dispatch(syncRoute({ itinerary, itineraryId, sync }));
});

export const onDeleteItinerary = createAsyncThunk<void, { itinerary: Itinerary; method: string }, { state: RootState }>(
    "myItems/onDeleteItinerary",
    async ({ itinerary, method }, thunkApi) => {
        TealiumLogger.link({
            event_name: "my_routes_deleted",
            method
        });
        thunkApi.dispatch(applicationActions.setLoginAnalyticsInfo({ method, trigger: null }));
        await thunkApi.dispatch(deleteItinerary(itinerary));
    }
);

export const onCopyToMyRoutes = createAsyncThunk<void, { itineraryId: string; method: string }, { state: RootState }>(
    "myItems/onImportItinerary",
    async ({ itineraryId, method }, thunkApi) => {
        TealiumLogger.link({
            event_name: "my_routes_imported",
            method
        });
        thunkApi.dispatch(applicationActions.setLoginAnalyticsInfo({ method, trigger: null }));
        await thunkApi.dispatch(importItinerary(itineraryId));
    }
);

export const onSavePlace = createAsyncThunk<
    void,
    { userMapLocation: Partial<UserMapLocation>; nameEdited: boolean },
    { state: RootState }
>("myItems/onSavePlace", async ({ userMapLocation, nameEdited }, thunkApi) => {
    const myPlaces = thunkApi.getState().mapPage.myItems.myPlaces;
    const placeBeingEditedOrigin = thunkApi.getState().mapPage.myItems.placeBeingEditedOrigin;
    const locationInfo = userMapLocation.mapLocation?.locationInfo;
    let eventName, update;
    switch (userMapLocation.type) {
        case "HOME":
            eventName = "home_added";
            update = !!myPlaces.find((place) => {
                return place.type === "HOME";
            });
            break;
        case "WORK":
            eventName = "work_added";
            update = !!myPlaces.find((place) => {
                return place.type === "WORK";
            });
            break;
        default:
            eventName = "place_saved";
            update = placeBeingEditedOrigin === MYITEMS_PLACES;
            break;
    }
    TealiumLogger.link({
        event_name: eventName,
        method: placeBeingEditedOrigin,
        location_type: locationInfo?.type,
        update,
        name_edited: nameEdited
    });
    thunkApi.dispatch(
        applicationActions.setLoginAnalyticsInfo({ method: placeBeingEditedOrigin, trigger: "favourite" })
    );
    thunkApi.dispatch(savePlace(userMapLocation));
});

export const onSaveItineraryPlanner = createAsyncThunk<void, void, { state: RootState }>(
    "myItems/onSaveItineraryPlanner",
    async (data, thunkApi) => {
        TealiumLogger.link({
            event_name: "my_routes_saved",
            method: IN_LINE_PLANNER,
            status: "Initiated"
        });
        await thunkApi.dispatch(navigateToRouteNewInfo());
    }
);

import { HTMLAttributes } from "react";

const modifierSeparator = "--";
const elementSeparator = "__";

export type ParentQaId = {
    parentQaId?: QaId;
    blockElement?: string;
};

export type QaIdModifier<T extends HTMLAttributes<unknown>> = Omit<T, "data-qa-id"> & {
    qaIdModifier?: string;
};

export type DataQaIdHtmlAttribute = {
    "data-qa-id": string;
};

/**
 * <ul>
 * <li>block(blockElement?) - Returns the block data attribute that can be destructed. If the blockElement argument is present the element data attribute is returned instead.
 * <li>blockValue(blockElement?) - Returns only the value of block(blockElement?).
 * <li>element(element) - Returns the element data attribute that can be destructed.
 * <li>elementValue(element?) - Returns only the value of element(element).
 * <li>modifierValue() - Returns the modifier value associate with the QaId.
 * <li>withModifier(modifier) - Returns a new instance of QaId with the passed modifier value.
 * </ul>
 */
export type QaId = {
    block(blockElement?): DataQaIdHtmlAttribute;
    blockValue(blockElement?): string;
    element(element: string): DataQaIdHtmlAttribute;
    elementValue(element: string): string;
    modifierValue(): string;
    withModifier(modifier): QaId;
};

/**
 * Provides a structured way of created data-qa-id values.
 * <p>
 * <em>Concept</em>
 * <ul>
 * <li><block>                          - Main element
 * <li><block>__<element>               - Sub element (still having a reference to the main element)
 * <li><block>--<modifier>              - Main element with a modifier postfix (ex: index or id)
 * <li><block>__<element>--<modifier>   - Sub elemenet with a modifier postfix (ex: index or id)
 * </ul>
 * <em>Locating elements</em>
 * <p>
 * Attribute selectors in conjunction with css selectors can be used to locate the elements needed. Such as the following.
 * <ul>
 * <li>document.querySelectorAll('[data-qa-id^=block-name]')                                - gets all elements starting with block-name
 * <li>document.querySelectorAll('[data-qa-id^=block-name][data-qa-id$=modifier-name]')     - gets all elements starting with block-name and ending with modifier-name
 * <li>document.querySelectorAll('[data-qa-id^=block-name] [data-qa-id$=modifier-name]')    - gets all elements ending with modifier-name and having a parent starting with block-name and ch
 * </ul>
 *
 * @param block     Main name for the data qa id for everything to be built on
 * @param modifier  Modifier used as a postfix to better identify the element (ex: index or id)
 *
 * @see <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors">Attribute Selectors</a>
 * @see <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors">CSS Selectors</a>
 */
export const qaId: (block: string, modifier?: string) => QaId = (block: string, modifier?: string) => {
    const withData = (value: string): DataQaIdHtmlAttribute => {
        return { "data-qa-id": value };
    };

    const modifierName = () => {
        return modifier ? `${modifierSeparator}${modifier}` : "";
    };

    const blockWithModifier = () => {
        return `${block}${modifierName()}`;
    };

    const elementWithModifier = (element: string) => {
        return `${block}${elementSeparator}${element}${modifierName()}`;
    };

    return {
        block: (blockElement?) =>
            blockElement ? withData(elementWithModifier(blockElement)) : withData(blockWithModifier()),
        blockValue: (blockElement?) => (blockElement ? elementWithModifier(blockElement) : blockWithModifier()),
        element: (element: string) => withData(elementWithModifier(element)),
        elementValue: (element: string) => elementWithModifier(element),
        modifierValue: () => modifier,
        withModifier: (modifier) => qaId(block, modifier)
    };
};

export default qaId;

import { RootState } from "../../../RootReducer";
import { createSelector } from "@reduxjs/toolkit";

export const selectSelectedLocation = (state: RootState) => state.mapPage.location.selectedLocation;
export const selectSelectedFromSavedLocations = (state: RootState) => state.mapPage.location.selectedFromSavedLocations;
export const selectPreviousSelectedLocation = (state: RootState) => state.mapPage.location.previousSelectedLocation;
export const selectLastSelectedLocation = createSelector(
    selectSelectedLocation,
    selectPreviousSelectedLocation,
    (currSelectedLocation, prevSelectedLocation) => currSelectedLocation || prevSelectedLocation
);
export const selectHasBoundaryError = (state: RootState) => state.mapPage.location.hasBoundaryError;
export const selectPreviousHeadingInfo = (state: RootState) => state.mapPage.location.previousHeadingInfo;

import { RootState } from "../../../RootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { TTMLocationContext } from "../../../../utils/locationTypes";

export const selectInputString = (state: RootState) => state.mapPage.search.inputString;

export const selectMyPlacesSearchResults = (state: RootState) => state.mapPage.search.myPlacesSearchResults;
export const selectHistorySearchResults = (state: RootState) => state.mapPage.search.historySearchResults;
export const selectSearchResults = (state: RootState) => state.mapPage.search.searchResults;
export const selectCombinedSearchLocations = createSelector(
    selectMyPlacesSearchResults,
    selectHistorySearchResults,
    selectSearchResults,
    (myPlacesSearchResults, historySearchResults, searchResults) => [
        ...myPlacesSearchResults.map((myPlacesSearchResult) => ({
            ...myPlacesSearchResult,
            context: TTMLocationContext.MY_PLACES
        })),
        ...historySearchResults.map((historySearchResult) => ({
            ...historySearchResult,
            context: TTMLocationContext.SEARCH_HISTORY
        })),
        ...searchResults.map((searchResult) => ({ ...searchResult, context: TTMLocationContext.SEARCH_RESULT }))
    ]
);

export const selectSearchResultsOnMap = (state: RootState) => state.mapPage.search.searchResultsOnMap;
export const selectViewportCheckTempLocations = (state: RootState) =>
    state.mapPage.search.viewportCheckTempResults.map((result) => ({
        ...result,
        context: TTMLocationContext.SEARCH_RESULT
    }));
export const selectAutocompleteSegments = (state: RootState) => state.mapPage.search.autocompleteSegments;
export const selectMatchedAutoCompleteSearch = (state: RootState) => state.mapPage.search.matchedAutoCompleteSearch;
export const selectIsAutoCompleteSegmentSelectedByUser = (state: RootState) =>
    state.mapPage.search.isAutoCompleteSegmentSelectedByUser;
export const selectAreAutocompleteDisplayed = (state: RootState) => state.mapPage.search.areAutocompleteDisplayed;
export const selectSearchBoxInputConfirmed = (state: RootState) => state.mapPage.search.searchBoxInputConfirmed;
export const selectSearchNeedsUpdate = (state: RootState) => state.mapPage.search.searchNeedsUpdate;
export const selectSearchFreeError = (state: RootState) => state.mapPage.search.errors.freeError;
export const selectNoResultsFoundError = (state: RootState) => state.mapPage.search.errors.noResultsFound;
export const selectIsFirstTimeLoaded = (state: RootState) => state.mapPage.search.isFirstTimeLoaded;
export const selectAreLatestResultsDisplayed = (state: RootState) => state.mapPage.search.areLatestResultsDisplayed;
export const selectSearchFormFocused = (state: RootState) => state.mapPage.search.searchFormFocused;
export const selectRecentSearches = (state: RootState) => state.mapPage.search.recentSearches;

import { ActiveItinerary, service } from "@anw/gor-sdk";

import { actions as activeDestinationActions } from "../active-destination/reducers";
import { actions as notificationActions, ActiveDestinationNotification } from "../../notification/reducers";
import { AuthCheck, createRetryAsyncThunk } from "../../../retryAsyncThunk";
import { TealiumLogger } from "../../../../classes/TealiumLogger";
import { LOCATION_PANEL } from "../../../../utils/analytics";

export const getActiveItinerary = createRetryAsyncThunk<void, void>(
    "activeDestination/getActiveItinerary",
    AuthCheck.EAGER,
    async (data, thunkApi) => {
        const response = await service().api.activeItinerary.getActiveItinerary();
        thunkApi.dispatch(activeDestinationActions.setActiveItinerary(response || null));
    }
);

export const setActiveItinerary = createRetryAsyncThunk<
    void,
    { activeItinerary: ActiveItinerary; notification: ActiveDestinationNotification; favourite: boolean }
>(
    "activeDestination/setActiveItinerary",
    AuthCheck.EAGER,
    async ({ activeItinerary, notification, favourite }, thunkApi) => {
        await service().api.activeItinerary.setActiveItinerary({ payload: activeItinerary });
        TealiumLogger.link({
            event_name: "destination_send_cloud",
            method: thunkApi.getState().application.loginAnalyticsInfo.method,
            favourite
        });
        thunkApi.dispatch(activeDestinationActions.setActiveItinerary(activeItinerary));
        thunkApi.dispatch(notificationActions.addActiveDestinationNotification(notification));
    }
);

export const deleteActiveItinerary = createRetryAsyncThunk<
    void,
    { notification: ActiveDestinationNotification; favourite: boolean }
>("activeDestination/deleteActiveItinerary", AuthCheck.EAGER, async ({ notification, favourite }, thunkApi) => {
    await service().api.activeItinerary.deleteActiveItinerary();
    TealiumLogger.link({
        event_name: "destination_remove_cloud",
        method: LOCATION_PANEL,
        favourite
    });
    thunkApi.dispatch(activeDestinationActions.setActiveItinerary(null));
    thunkApi.dispatch(notificationActions.addActiveDestinationNotification(notification));
});

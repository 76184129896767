import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type LngLatObject = { lat: number; lng: number };

const initialState = {
    mapReady: false,
    center: undefined as LngLatObject,
    bbox: null as [[number, number], [number, number]],
    viewableBBox: null as [[number, number], [number, number]]
};

export const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        updateMapCenter: (state, action: PayloadAction<LngLatObject>) => {
            state.center = action.payload;
        },
        updateMapBBox: (state, action: PayloadAction<[[number, number], [number, number]]>) => {
            state.bbox = action.payload;
        },
        updateMapViewableBBox: (state, action: PayloadAction<[[number, number], [number, number]]>) => {
            state.viewableBBox = action.payload;
        },
        setMapReady: (state, action: PayloadAction<boolean>) => {
            state.mapReady = action.payload;
        }
    }
});

export const { actions } = mapSlice;

export default mapSlice.reducer;

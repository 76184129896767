import React, { HTMLAttributes } from "react";
import classNames from "classnames";

import "../../../../sass/ui-library/components/base/Panel.scss";
import { ParentQaId, QaIdModifier } from "../../utils/qa-id";
import { Accent, Title } from "./Typography";
import useQaId from "../../hooks/useQaId";
import bem from "../../utils/bem";
import { ButtonClose } from "./Button";
import { IcChevronLeft16 } from "../../icons";

const panelBem = bem("panel");
const panelReturnBem = bem("panel-return");

type PanelNavigationProps = HTMLAttributes<HTMLAnchorElement> &
    ParentQaId & {
        returnText: string;
        onReturnClick?: () => void;
        returnContrast?: boolean;
    };

const PanelReturn = ({
    className,
    returnText,
    onReturnClick,
    returnContrast,
    parentQaId,
    ...rest
}: PanelNavigationProps) => {
    return (
        <a
            className={classNames(panelReturnBem.block(), className, {
                [panelReturnBem.modifier("contrast")]: returnContrast
            })}
            onClick={onReturnClick}
            {...parentQaId.element("return-link")}
            {...rest}
        >
            <IcChevronLeft16 className={panelReturnBem.element("icon")} {...parentQaId.element("return-icon")} />
            <Accent className={panelReturnBem.element("text")} {...parentQaId.element("return-text")}>
                {returnText}
            </Accent>
        </a>
    );
};

export type PanelProps = QaIdModifier<HTMLAttributes<HTMLDivElement>> &
    Partial<PanelNavigationProps> &
    ParentQaId & {
        heading: string;
        onCloseClicked?: () => void;
        hasClose?: boolean;
    };

export const Panel = ({
    blockElement,
    className,
    parentQaId,
    qaIdModifier,
    heading,
    onCloseClicked,
    children,
    returnText,
    onReturnClick,
    hasClose = true,
    returnContrast = true
}: PanelProps) => {
    const panelQa = useQaId("panel", parentQaId, qaIdModifier);
    return (
        <div className={classNames(panelBem.block(), className)} {...panelQa.block(blockElement)} role="group">
            <div className={panelBem.element("heading")}>
                <Title className={panelBem.element("title")} {...panelQa.element("heading")}>
                    {heading}
                </Title>
                {hasClose && (
                    <ButtonClose
                        className={panelBem.element("close")}
                        {...panelQa.element("close")}
                        onClick={onCloseClicked}
                    />
                )}
            </div>
            {returnText && (
                <PanelReturn
                    parentQaId={panelQa.qaId()}
                    returnText={returnText}
                    onReturnClick={onReturnClick}
                    returnContrast={returnContrast}
                />
            )}
            <div className={panelBem.element("content")} {...panelQa.element("content")}>
                {children}
            </div>
        </div>
    );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { hazmatOptions, UnitsType } from "@anw/gor-sdk";
import isNil from "lodash/isNil";

import { RootState } from "../../../RootReducer";
import { actions as plannerActions } from "../planner";
import { actions as settingsActions, vehicleParametersKey, ReachableRangeSettings, VehicleSettings } from "./reducers";
import { selectReachableRangeSettings, selectUnitsType, selectVehicleParameters } from "./selectors";
import { canRouteBePlanned as selectCanRouteBePlanned } from "../planner/selectors";
import { transformUnitsType, vehicleMeasureValues } from "../../../../utils/vehicleMeasurements";
import { getLocalStorageJson } from "../../../../utils/localStorage";

export const updateVehicleSettings = createAsyncThunk<void, VehicleSettings, { state: RootState }>(
    "settings/updateVehicleSettings",
    async (values, thunkApi) => {
        const selectedUnitsType = selectUnitsType(thunkApi.getState());
        const selectedHazmatOptions = [];
        const hazmatOptionsSelection: boolean[] = values.hazmatOptionsSelection;
        // getting selected values so we can create an array of strings that is expected by route api
        hazmatOptions.forEach((hazmatOption, index) => {
            if (hazmatOptionsSelection && hazmatOptionsSelection[index]) {
                selectedHazmatOptions.push(hazmatOption);
            }
        });
        const vehicleParameters = {
            ...values,
            unitsType: selectedUnitsType,
            hazmatOptions: selectedHazmatOptions
        };
        // updating the settings part of the state
        thunkApi.dispatch(settingsActions.setVehicleParameters(vehicleParameters));
        // updating the route planner options of the state
        thunkApi.dispatch(plannerActions.updateVehicleParameters(vehicleParameters));
        await thunkApi.dispatch(plannerActions.planRoute());
    }
);

export const updateUnitsType = createAsyncThunk<void, UnitsType, { state: RootState }>(
    "settings/updateUnitsType",
    async (unitType, thunkApi) => {
        const state = thunkApi.getState();
        const selectedVehicleParameters = selectVehicleParameters(state);
        const vehicleParameters: VehicleSettings = {
            ...selectedVehicleParameters,
            unitsType: unitType
        };

        vehicleMeasureValues.forEach((measure) => {
            const measureValue = selectedVehicleParameters[measure];
            if (measureValue && selectedVehicleParameters.unitsType !== unitType) {
                vehicleParameters[measure] = transformUnitsType(
                    selectedVehicleParameters.unitsType,
                    unitType,
                    measure,
                    measureValue
                );
            }
        });

        thunkApi.dispatch(settingsActions.setUnitsType(unitType));
        // update vehicle measures entered by user to the new unit type
        thunkApi.dispatch(settingsActions.setVehicleParameters(vehicleParameters));
    }
);

export const updateReachableRangeState = createAsyncThunk<void, boolean, { state: RootState }>(
    "settings/updateReachableRangeState",
    async (reachableRangeState, thunkApi) => {
        const selectedReachableRangeSettings = selectReachableRangeSettings(thunkApi.getState());
        const reachableRangeSettings = {
            ...selectedReachableRangeSettings,
            showReachableRange: reachableRangeState
        } as ReachableRangeSettings;

        console.log("reachable range state " + reachableRangeSettings.showReachableRange);
        thunkApi.dispatch(settingsActions.setReachableRangeSettings(reachableRangeSettings));
        thunkApi.dispatch(plannerActions.calculateReachableRange());
    }
);

export const updateDetailedRangeState = createAsyncThunk<void, boolean, { state: RootState }>(
    "settings/updateDetailedRangeState",
    async (detailedRangeState, thunkApi) => {
        const selectedReachableRangeSettings = selectReachableRangeSettings(thunkApi.getState());
        const reachableRangeSettings = {
            ...selectedReachableRangeSettings,
            showDetailedRange: detailedRangeState
        } as ReachableRangeSettings;

        thunkApi.dispatch(settingsActions.setReachableRangeSettings(reachableRangeSettings));
        thunkApi.dispatch(plannerActions.calculateReachableRange());
    }
);

export const initDefaultVehicleParametersForEV = createAsyncThunk<void, boolean, { state: RootState }>(
    "settings/initDefaultVehicleParametersForEV",
    async (toBeInitialized, thunkApi) => {
        const canRouteBePlanned = selectCanRouteBePlanned(thunkApi.getState());
        const vehicleParameters = getLocalStorageJson<VehicleSettings>(vehicleParametersKey);
        // initialize ev vehicle params with default values only if the user doesn't have saved values in localStorage
        // or remove them in case of resetting
        if (
            !toBeInitialized ||
            vehicleParameters === null ||
            isNil(vehicleParameters.combustionVehicleConsumptionModel) ||
            isNil(vehicleParameters.mspSettings) ||
            isNil(vehicleParameters.electricVehicleConsumptionSettings)
        ) {
            thunkApi.dispatch(settingsActions.updateEngineRelatedVehicleParameters(toBeInitialized));
            const updatedSettingsVehicleParameters = thunkApi.getState().mapPage.settings.vehicleParameters;
            thunkApi.dispatch(plannerActions.updateVehicleParameters(updatedSettingsVehicleParameters));
            // only to be used in CARIAD branch
            // thunkApi.dispatch(updateRoutingProvider(toBeInitialized ? "HCP3" : "GLOBAL"));
            if (canRouteBePlanned) {
                thunkApi.dispatch(plannerActions.planRoute());
            }
        }
    }
);

import React, { HTMLAttributes, useRef } from "react";
import classNames from "classnames";
import "../../../../sass/ui-library/components/simple/Rating.scss";
import { IconSelectType } from "../Variants";
import { IcThumbsUp16 } from "../../icons";
import { qaId, QaId, QaIdModifier } from "../../utils/qa-id";
import bem from "../../utils/bem";
import { IconSelect } from "../base/IconSelect";

const rating = bem("rating");

export type RatingProps = QaIdModifier<HTMLAttributes<HTMLElement>> & {
    ratingValue: number;
    ratingOutOf: number;
    noOfReviews?: number;
    type?: IconSelectType;
};

export const Rating = ({
    ratingValue,
    ratingOutOf,
    noOfReviews,
    type = "accent-icon",
    className,
    qaIdModifier,
    ...rest
}: RatingProps) => {
    const ratingQa = useRef<QaId>(qaId("rating", qaIdModifier));
    return (
        <IconSelect
            className={classNames(rating.block(), className)}
            {...rest}
            {...ratingQa.current.block()}
            value={
                <>
                    <span className={classNames(rating.element("value"))} {...ratingQa.current.element("value")}>
                        {ratingValue}
                    </span>
                    <span className={classNames(rating.element("out-of"))} {...ratingQa.current.element("out-of")}>
                        /{ratingOutOf}
                    </span>
                    {!!noOfReviews && (
                        <span
                            className={classNames(rating.element("no-of-reviews"))}
                            {...ratingQa.current.element("no-of-reviews")}
                        >
                            ({noOfReviews})
                        </span>
                    )}
                </>
            }
            type={type}
            icon={<IcThumbsUp16 />}
        />
    );
};

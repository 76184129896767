import { createBrowserHistory, History } from "history";

import { isSSR } from "../utils/agent";

export default class BrowserHistory {
    private static _instance: History;

    public static get Instance() {
        return isSSR() ? null : this._instance || (this._instance = createBrowserHistory());
    }
}

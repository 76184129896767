import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HazmatOption, UnitsType, VehicleEngineType } from "@anw/gor-sdk";
import {
    getLocalStorageJson,
    getLocalStorageValue,
    setLocalStorageValue,
    setLocalStorageJson
} from "../../../../utils/localStorage";
import {
    EngineTypeValue,
    defaultVehicleParameters,
    defaultReachableRangeState
} from "../../../../constants/EvConstants";

export const unitsTypeKey = "units-type";
export const vehicleParametersKey = "vehicle-parameters";

export type CombustionVehicleConsumptionModel = {
    currentFuelInLiters: number;
    constantSpeedConsumptionInLitersPerHundredkm: string;
};

export type MSPSetting = {
    id: string;
    label?: string;
    enabled: boolean;
};

export type ElectricVehicleConsumptionSettings = {
    constantSpeedConsumptionInkWhPerHundredkm: string;
    currentChargeInPercentage: number;
    maxChargeInkWh: number;
    minChargeAtDestinationInPercentage: number;
    minChargeAtChargingStopsInPercentage: number;
    auxiliaryPowerInkW?: number;
    chargeMarginsInkWh?: number;
    consumptionInkWhPerkmAltitudeGain?: number;
    recuperationInkWhPerkmAltitudeLoss?: number;
    vehicleWeight?: number;
    accelerationEfficiency?: number;
    decelerationEfficiency?: number;
    uphillEfficiency?: number;
    downhillEfficiency?: number;
};

export type VehicleSettings = {
    length?: string;
    width?: string;
    height?: string;
    weight?: string;
    axleWeight?: string;
    maxSpeed?: string;
    adrTunnelRestrictionCode?: "none" | "B" | "C" | "D" | "E";
    hazmatOptions?: HazmatOption[];
    unitsType: UnitsType;
    hazmatOptionsSelection: boolean[];
    engineType?: VehicleEngineType;
    electricVehicleConsumptionSettings?: ElectricVehicleConsumptionSettings;
    combustionVehicleConsumptionModel?: CombustionVehicleConsumptionModel;
    mspSettings?: MSPSetting[];
    chargingParameters?: any;
    vehicleModelId?: string;
};

export type ReachableRangeSettings = {
    showReachableRange: boolean;
    showDetailedRange: boolean;
};

const initialState = {
    unitsType: getLocalStorageValue<UnitsType>(unitsTypeKey, "METRIC"),
    // setting empty values so the form items are properly controlled
    vehicleParameters: getLocalStorageJson<VehicleSettings>(vehicleParametersKey, {
        length: "",
        width: "",
        height: "",
        weight: "",
        axleWeight: "",
        maxSpeed: "",
        unitsType: "METRIC" as UnitsType,
        hazmatOptionsSelection: new Array(12).fill(false),
        adrTunnelRestrictionCode: "none",
        vehicleModelId: null
    } as VehicleSettings),
    reachableRangeSettings: defaultReachableRangeState as ReachableRangeSettings
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setVehicleEngineType: (state, action: PayloadAction<VehicleEngineType>) => {
            state.vehicleParameters.engineType = action.payload;
        },
        setUnitsType: (state, action: PayloadAction<UnitsType>) => {
            state.unitsType = action.payload;
            setLocalStorageValue(unitsTypeKey, state.unitsType);
        },
        setAutoUnitsType: (state, action: PayloadAction<UnitsType>) => {
            // we don't store in local storage because user didn't choose it, it was auto-detected
            state.unitsType = action.payload;
        },
        setVehicleParameters: (state, action: PayloadAction<VehicleSettings>) => {
            state.vehicleParameters = action.payload;
            setLocalStorageJson(vehicleParametersKey, { ...state.vehicleParameters });
        },
        updateChargingParameters: (state, action: PayloadAction<any>) => {
            state.vehicleParameters.chargingParameters = action.payload;
            setLocalStorageJson(vehicleParametersKey, { ...state.vehicleParameters });
        },
        updateEngineRelatedVehicleParameters: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.vehicleParameters.engineType = EngineTypeValue.Electric as VehicleEngineType;
                state.vehicleParameters.electricVehicleConsumptionSettings =
                    defaultVehicleParameters.electricVehicleConsumptionSettings;
                state.vehicleParameters.combustionVehicleConsumptionModel =
                    defaultVehicleParameters.combustionVehicleConsumptionModel;
                state.vehicleParameters.chargingParameters = defaultVehicleParameters.chargingParameters;
                state.vehicleParameters.mspSettings = defaultVehicleParameters.mspSettings;
                state.vehicleParameters.vehicleModelId = defaultVehicleParameters.vehicleModelId;
            } else {
                // delete EV related values
                delete state.vehicleParameters.engineType;
                delete state.vehicleParameters.electricVehicleConsumptionSettings;
                delete state.vehicleParameters.combustionVehicleConsumptionModel;
                delete state.vehicleParameters.chargingParameters;
                delete state.vehicleParameters.mspSettings;
                delete state.vehicleParameters.vehicleModelId;
            }
            // update local storage
            setLocalStorageJson(vehicleParametersKey, { ...state.vehicleParameters });
        },
        setReachableRangeSettings: (state, action: PayloadAction<ReachableRangeSettings>) => {
            state.reachableRangeSettings = action.payload;
        }
    }
});

export const { actions } = settingsSlice;

export default settingsSlice.reducer;

import React, { HTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";
import "../../../../sass/ui-library/components/simple/ActionBar.scss";
import { bem } from "../../utils/bem";

const actionBar = bem("action-bar");

export type ActionBarProps = PropsWithChildren<
    HTMLAttributes<HTMLDivElement> & {
        size?: "big";
        type?: "primary" | "secondary";
        className?: string;
    }
>;

export const ActionBar = ({ children, size, type, className, ...rest }: ActionBarProps) => {
    const childrenWithProps = React.Children.map(children, (child: React.ReactElement<HTMLElement>) => {
        return (
            child &&
            React.cloneElement(child, {
                className: classNames(actionBar.element("action"), child.props.className)
            })
        );
    });
    return (
        <div
            className={classNames(actionBar.block(), className, {
                [actionBar.modifier(size)]: size,
                [actionBar.modifier(type)]: type
            })}
            {...rest}
        >
            {childrenWithProps}
        </div>
    );
};

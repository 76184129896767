import qaId, { DataQaIdHtmlAttribute, QaId } from "../utils/qa-id";
import { useRef } from "react";

export type UseQaIdType = {
    qaId(): QaId;
    block(element?: string): DataQaIdHtmlAttribute;
    element(element: string): DataQaIdHtmlAttribute;
};

export const useQaId = (
    block: string,
    parentQaId?: QaId,
    modifier?: string,
    onlyWithModifier?: boolean
): UseQaIdType => {
    const qaIdRef = useRef<QaId>(parentQaId || ((!onlyWithModifier || modifier) && qaId(block, modifier)));

    const extractBlock = (element?: string) => qaIdRef.current?.block(element || (parentQaId && block));

    const extractElement = (element: string) => qaIdRef.current?.element(element);

    return {
        block: (element) => extractBlock(element),
        element: (element: string) => extractElement(element),
        qaId: () => qaIdRef?.current
    };
};

export default useQaId;

import { RootState } from "../../RootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { isUserEntitledToRiderRoutes } from "../../../utils/user";

export const selectUserLngLat = (state: RootState) => state.user.userLngLat;
export const selectUserLocationInfo = (state: RootState) => state.user.userLocationInfo;
export const selectGeolocationAllowed = (state: RootState) => state.user.geolocationAllowed;
export const selectAwsUserLocationInformation = (state: RootState) => state.user.awsUserLocationInfo;
export const selectUserLocationAccuracy = (state: RootState) => state.user.locationAccuracy;
export const selectUserProfile = (state: RootState) => state.user.profile;
export const selectUserEntitledToRiderRoutes = createSelector(selectUserProfile, (profile) =>
    isUserEntitledToRiderRoutes(profile)
);
export const selectUserId = (state: RootState) => state.user.profile?.id;
export const selectUserLngLatFromRecentButtonClick = (state: RootState) => state.user.userLngLatFromRecentButtonClick;

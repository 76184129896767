import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Itinerary, OV2File, UserMapLocation } from "@anw/gor-sdk";
import { TTMUserMapLocation } from "../../../../utils/locationTypes";
import { TTMItinerary } from "../../../../utils/itineraryTypes";

const initialState = {
    myPlaces: [] as TTMUserMapLocation[],
    filteredMyPlaces: [] as TTMUserMapLocation[],
    userFavCollectionId: null as string,
    ov2Files: [] as OV2File[],
    userItineraries: [] as TTMItinerary[],
    filteredItineraries: [] as TTMItinerary[],
    selectedItinerary: null as Itinerary,
    selectedItineraryOrigin: null as string, //needed only for analytics presently
    busyItems: [] as string[],
    placeBeingEdited: null as Partial<UserMapLocation>,
    placeBeingEditedOrigin: null as string //needed only for analytics presently
};

export const myItemsSlice = createSlice({
    name: "myItems",
    initialState,
    reducers: {
        setMyPlaces: (state, action: PayloadAction<TTMUserMapLocation[]>) => {
            state.myPlaces = action.payload;
            state.filteredMyPlaces = action.payload;
        },
        setFilteredMyPlaces: (state, action: PayloadAction<TTMUserMapLocation[]>) => {
            state.filteredMyPlaces = action.payload;
        },
        resetFilteredMyPlaces: (state) => {
            state.filteredMyPlaces = state.myPlaces;
        },
        setFavCollectionId: (state, action: PayloadAction<string>) => {
            state.userFavCollectionId = action.payload;
        },
        setOV2Files: (state, action: PayloadAction<OV2File[]>) => {
            state.ov2Files = action.payload;
        },
        setUserItineraries: (state, action: PayloadAction<TTMItinerary[]>) => {
            state.userItineraries = action.payload;
            state.filteredItineraries = action.payload;
        },
        setFilteredItineraries: (state, action: PayloadAction<TTMItinerary[]>) => {
            state.filteredItineraries = action.payload;
        },
        resetFilteredItineraries: (state) => {
            state.filteredItineraries = state.userItineraries;
        },
        setSelectedItinerary: (state, action: PayloadAction<Itinerary>) => {
            state.selectedItinerary = action.payload;
        },
        setSelectedItineraryOrigin: (state, action: PayloadAction<string>) => {
            state.selectedItineraryOrigin = action.payload;
        },
        resetBusyItems: (state) => {
            state.busyItems.splice(0);
        },
        addBusyItem: (state, action: PayloadAction<string>) => {
            if (action.payload && !state.busyItems.includes(action.payload)) {
                state.busyItems.push(action.payload);
            }
        },
        removeBusyItem: (state, action: PayloadAction<string>) => {
            if (action.payload) {
                const index = state.busyItems.indexOf(action.payload);

                if (index !== -1) {
                    state.busyItems.splice(index, 1);
                }
            }
        },
        setPlaceBeingEdited: (state, action: PayloadAction<Partial<UserMapLocation>>) => {
            state.placeBeingEdited = action.payload;
        },
        setPlaceBeingEditedOrigin: (state, action: PayloadAction<string>) => {
            state.placeBeingEditedOrigin = action.payload;
        }
    }
});

export const { actions } = myItemsSlice;

export default myItemsSlice.reducer;

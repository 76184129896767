import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import { Size, SizeVariant } from "../Variants";

const sizeClass = (baseClass, size: Size) => (size && size != "m" ? `${baseClass}-${size}` : baseClass);

const getHeadingLevel = (size: Size, type: "title" | "subtitle") => {
    switch (size) {
        case "l":
            return type == "title" ? 1 : 5;
        case "s":
            return type == "title" ? 2 : 6;
        case "xs":
            return type == "title" ? 3 : 6;
        case "m":
        default:
            return 5;
    }
};

export type TitleProps = HTMLAttributes<HTMLHeadingElement> & SizeVariant;

export const Title = ({ className, size, ...rest }: TitleProps) => (
    <div
        role="heading"
        aria-level={getHeadingLevel(size, "title")}
        className={classNames(sizeClass("title", size), className)}
        {...rest}
    />
);

export type SubtitleProps = HTMLAttributes<HTMLHeadingElement> &
    SizeVariant & {
        caps?: boolean;
    };

export const Subtitle = ({ className, caps, size, ...rest }: SubtitleProps) => (
    <div
        role="heading"
        aria-level={getHeadingLevel(size, "subtitle")}
        className={classNames(className, sizeClass(caps ? "subtitle-caps" : "subtitle", size))}
        {...rest}
    />
);

export type BodyProps = HTMLAttributes<HTMLDivElement> &
    SizeVariant & {
        inline?: boolean;
    };

export const Body = ({ className, size, inline, ...rest }: BodyProps) => {
    return inline ? (
        <span className={classNames(sizeClass("body", size), className)} {...rest} />
    ) : (
        <div className={classNames(sizeClass("body", size), className)} {...rest} />
    );
};

export type SecondaryProps = HTMLAttributes<HTMLDivElement> &
    SizeVariant & {
        inline?: boolean;
    };

export const Secondary = ({ className, size, inline, ...rest }: SecondaryProps) => {
    return inline ? (
        <span className={classNames(sizeClass("secondary", size), className)} {...rest} />
    ) : (
        <div className={classNames(sizeClass("secondary", size), className)} {...rest} />
    );
};

export type AccentProps = HTMLAttributes<HTMLDivElement> &
    SizeVariant & {
        bold?: boolean;
        inline?: boolean;
    };

export const Accent = ({ className, size, inline, ...rest }: AccentProps) => {
    return inline ? (
        <span className={classNames(sizeClass("accent-text", size), className)} {...rest} />
    ) : (
        <div className={classNames(sizeClass("accent-text", size), className)} {...rest} />
    );
};

export type HyperlinkProps = HTMLAttributes<HTMLDivElement>;

export const Hyperlink = ({ className, ...rest }: HyperlinkProps) => (
    <span className={classNames(sizeClass("hyperlink", "m"), className)} {...rest} />
);

import { RootState } from "../../../RootReducer";

export const selectBusyItems = (state: RootState) => state.mapPage.myItems.busyItems;
export const selectMyItems = (state: RootState) => state.mapPage.myItems;
export const selectFilteredMyPlaces = (state: RootState) => state.mapPage.myItems.filteredMyPlaces;
export const selectOV2Files = (state: RootState) => state.mapPage.myItems.ov2Files;
export const selectFilteredItineraries = (state: RootState) => state.mapPage.myItems.filteredItineraries;
export const selectSelectedItinerary = (state: RootState) => state.mapPage.myItems.selectedItinerary;
export const selectSelectedItineraryOrigin = (state: RootState) => state.mapPage.myItems.selectedItineraryOrigin;
export const selectPlaceBeingEdited = (state: RootState) => state.mapPage.myItems.placeBeingEdited;

import { Itinerary, ResultError, service } from "@anw/gor-sdk";

import { AuthCheck, createRetryAsyncThunk } from "../../../retryAsyncThunk";
import { navigateTo404, navigateToRouteView } from "../../navigation/thunks";
import { actions as notificationActions } from "../../notification/reducers";
import { actions as myItemsActions } from "../my-items/reducers";

export const syncRoute = createRetryAsyncThunk<void, { itinerary?: Itinerary; itineraryId: string; sync?: boolean }>(
    "route-sync/syncRoute",
    AuthCheck.EAGER,
    async ({ itinerary, itineraryId, sync = true }, thunkApi) => {
        try {
            await service().api.itineraries.synchronizeToNavcloud({
                parameters: { itineraryId, synchronize: sync }
            });
        } catch (error: unknown) {
            const expectedError = error as ResultError;

            if (expectedError.code === 404) {
                thunkApi.dispatch(navigateTo404());
            } else if (expectedError.code === 403) {
                thunkApi.dispatch(
                    notificationActions.addGenericNotification({
                        heading: "Authorisation error",
                        message:
                            "We are sorry, but you're not allowed to edit or synchronise this route. Probably, you're logged in with a wrong user",
                        notificationType: "generic-error"
                    })
                );
                thunkApi.dispatch(navigateToRouteView(itineraryId));
            } else {
                // oops :)
                throw error;
            }
        }

        thunkApi.dispatch(
            notificationActions.addMyItemsRoutesNotification({
                notificationType: sync ? "route-synced" : "route-un-synced",
                itinerary
            })
        );
        const selectedItinerary = await service().api.itineraries.fetchItinerary({
            parameters: { itineraryId, viewType: "FULL" }
        });
        if (selectedItinerary) {
            thunkApi.dispatch(myItemsActions.setSelectedItinerary(selectedItinerary));
        }
    }
);

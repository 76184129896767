import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    editedPoint: null as [number, number],
    isEditModalOpened: false
};

export const suggestEditSlice = createSlice({
    name: "suggestEdit",
    initialState,
    reducers: {
        updateCoordsForEditedPoint: (state, action: PayloadAction<[number, number]>) => {
            state.editedPoint = action.payload;
        },
        setIsSuggestEditModalOpened: (state, action: PayloadAction<boolean>) => {
            state.isEditModalOpened = action.payload;
        }
    }
});

export const { actions } = suggestEditSlice;

export default suggestEditSlice.reducer;

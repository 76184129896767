import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../RootReducer";
import { service } from "@anw/gor-sdk";
import { TealiumLogger } from "../../../../classes/TealiumLogger";

export const submitSuggestion = createAsyncThunk<string, string, { state: RootState }>(
    "suggest-edit/submitSuggestion",
    async (text, thunkApi) => {
        const state = thunkApi.getState();
        const pointCoords = state.mapPage.suggestEdit.editedPoint;
        try {
            await service().api.vertexNotes.createNote({
                payload: {
                    latitude: pointCoords[0],
                    longitude: pointCoords[1],
                    text,
                    environment: "MIG_GLOB",
                    component: "TTM"
                }
            });
            TealiumLogger.linkEventName("suggest_map_edit");
            return "success";
        } catch (e) {
            return "error";
        }
    }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { FuzzySearchOptions, LngLatRequestParam } from "@tomtom-international/web-sdk-services";

import { TTMLocation, TTMLocationContext } from "../../../../utils/locationTypes";
import { RootState } from "../../../RootReducer";
import { buildLocationTitle } from "../../../../utils/location";
import { actions as locationActions, LocationSelectionOrigin } from "./reducers";
import { ForegroundOption } from "../../application/reducers";
import { navigateToLocation, navigateToPrevious } from "../../navigation/thunks";
import { searchActions } from "../search/reducers";
import { getMatchFromSavedLocations } from "../../../../utils/myPlaces";
import { SearchIntention } from "../search/types";
import { suggest } from "../../../../services/ngs/ngsClient";
import { toTTMSearchResult } from "../../../../services/ngs/ngsAdapter";
import i18next from "i18next";

export const changeSelectedLocation = createAsyncThunk<
    void,
    { location: TTMLocation; selectedFrom: LocationSelectionOrigin },
    { state: RootState }
>("location/changeSelectedLocation", async (data, thunkApi) => {
    const state = thunkApi.getState().mapPage;
    const prevLocation = state.location.selectedLocation;
    if (prevLocation?.context === TTMLocationContext.SEARCH_RESULT) {
        thunkApi.dispatch(searchActions.clearSelectedResultIndex());
    } else if (
        prevLocation?.context === TTMLocationContext.SEARCH_HISTORY ||
        prevLocation?.context === TTMLocationContext.MAP_POI ||
        prevLocation?.context === TTMLocationContext.MY_PLACES
    ) {
        thunkApi.dispatch(searchActions.clearSearchResults());
    }

    const location = data?.location;
    thunkApi.dispatch(locationActions.setSelectedLocation(data));
    thunkApi.dispatch(
        locationActions.setSelectedFromSavedLocations(getMatchFromSavedLocations(location, state.myItems.myPlaces))
    );
    if (location) {
        thunkApi.dispatch(navigateToLocation(location));
    } else {
        state.search.searchResults.length &&
            thunkApi.dispatch(searchActions.updateUserIntention(SearchIntention.DISCOVERY));
        // upon de-selecting a location, we navigate to the previous path only if we were still in the location details panel:
        thunkApi.getState().application.foreground == ForegroundOption.SELECTED_LOCATION &&
            thunkApi.dispatch(navigateToPrevious());
    }

    // carry out the selected result title to search input and URL
    thunkApi.dispatch(searchActions.changeSearchInput(buildLocationTitle(location)));
});

export const fetchAndSelectLocation = createAsyncThunk<
    void,
    { context: TTMLocationContext; searchOptions: Partial<FuzzySearchOptions> },
    { state: RootState }
>("location/fetchAndSelectLocation", async (data, thunkApi) => {
    const state = thunkApi.getState();
    const center: LngLatRequestParam = data.searchOptions.center;
    const query = data.searchOptions.query;
    const idxSet = data.searchOptions.idxSet;
    //@ts-ignore
    const response = await suggest({
        query: query,
        position: `${center[1]},${center[0]}`,
        types: idxSet,
        language: i18next.language
    });
    if (response.results) {
        thunkApi.dispatch(
            changeSelectedLocation({
                location: { context: data.context, ...toTTMSearchResult(response.results[0]) },
                selectedFrom: "HTML_UI"
            })
        );
    }
});

export const clearSelectedLocation = createAsyncThunk<void, void, { state: RootState }>(
    "location/clearSelectedLocation",
    async (data, thunkApi) => {
        if (thunkApi.getState().mapPage.location.selectedLocation) {
            thunkApi.dispatch(changeSelectedLocation(null));
        }
    }
);

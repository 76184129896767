import React, { HTMLAttributes, ReactElement, ReactNode } from "react";
import classNames from "classnames";
import bem from "../../utils/bem";
import { ParentQaId, QaIdModifier } from "../../utils/qa-id";

import "../../../../sass/ui-library/components/base/IconSelect.scss";
import { IconSelectVariants } from "../Variants";
import useQaId from "../../hooks/useQaId";

const iconSelect = bem("icon-select");

export type IconSelectProps = QaIdModifier<HTMLAttributes<HTMLDivElement>> &
    ParentQaId &
    Partial<IconSelectVariants> & {
        value: string | ReactElement;
        icon?: ReactNode;
    };

export const IconSelect = ({
    value,
    icon,
    className,
    iconSize = "16",
    layout = "horizontal-left-icon",
    state = "default",
    type = "base",
    parentQaId,
    blockElement,
    qaIdModifier,
    ...rest
}: IconSelectProps) => {
    const iconSelectQa = useQaId("icon-select", parentQaId, qaIdModifier, true);
    return (
        <div
            {...iconSelectQa.block(blockElement)}
            className={classNames(iconSelect.block(), className, {
                [iconSelect.modifier(type)]: type,
                [iconSelect.modifier(layout)]: layout,
                [iconSelect.modifier(state)]: state,
                [iconSelect.modifier(iconSize)]: iconSize
            })}
            {...rest}
        >
            {icon && (
                <span className={classNames(iconSelect.element("icon"))} {...iconSelectQa.element("icon")}>
                    {icon}
                </span>
            )}
            {value && (
                <span className={classNames(iconSelect.element("value"))} {...iconSelectQa.element("text")}>
                    {value}
                </span>
            )}
        </div>
    );
};

import React, { HTMLAttributes } from "react";
import classNames from "classnames";

import "../../../../sass/ui-library/components/base/Badge.scss";
import { ParentQaId, QaIdModifier } from "../../utils/qa-id";
import { Body } from "./Typography";
import useQaId from "../../hooks/useQaId";

export type BadgeGroupProps = QaIdModifier<HTMLAttributes<HTMLDivElement>> &
    ParentQaId & {
        title?: string;
        value?: string;
        size?: "small" | "normal";
        className?: string;
    };

export const BadgeGroup = ({
    blockElement,
    className,
    parentQaId,
    qaIdModifier,
    size,
    title,
    value
}: BadgeGroupProps) => {
    const badgeQa = useQaId("badge", parentQaId, qaIdModifier);
    return (
        <div className={classNames("badge-group", className)} {...badgeQa.block(blockElement)} role="group">
            {title && (
                <Body
                    className={classNames("badge bg-primary text-light")}
                    size={size == "small" ? "xs" : "s"}
                    {...badgeQa.element("title")}
                >
                    {title}
                </Body>
            )}
            {value && (
                <Body
                    className={classNames("badge bg-light")}
                    size={size == "small" ? "xs" : "s"}
                    {...badgeQa.element("value")}
                >
                    {value}
                </Body>
            )}
        </div>
    );
};

export type BadgeCollectionProps = React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>;

export const BadgeCollection = (props: BadgeCollectionProps) => {
    const { children, className, ...rest } = props;
    return (
        <div {...rest} className={classNames("badges", className)}>
            {children}
        </div>
    );
};

export type BEM = {
    block(): string;
    modifier(modifierName: string | string[]): string;
    element(childName: string): string;
};

export const bem: (block: string) => BEM = (block: string) => {
    return {
        block: () => block,
        modifier: (modifierName: string | string[]) =>
            Array.isArray(modifierName)
                ? modifierName.map((m) => `${block}--${m}`).join(" ")
                : `${block}--${modifierName}`,
        element: (childName: string) => `${block}__${childName}`
    };
};
export const bemBlock = (block: string) => bem(block).block();
export const bemModifier = (block: string, modifier: string | string[]) => bem(block).modifier(modifier);
export const bemElement = (block: string, element: string) => bem(block).element(element);

export default bem;

import { createAsyncThunk } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import { RootState } from "../../RootReducer";
import { actions as userActions, userLngLatKey } from "../user/reducers";
import { removeLocalStorage, setLocalStorageJson } from "../../../utils/localStorage";

export const updateUserLocation = createAsyncThunk<
    void,
    { lngLat: [number, number]; accuracy: number; userLngLatFromRecentButtonClick?: boolean },
    { state: RootState }
>("user/updateUserLocation", async (data, thunkApi) => {
    if (!isEmpty(data?.lngLat)) {
        setLocalStorageJson(userLngLatKey, data.lngLat);
    } else {
        removeLocalStorage(userLngLatKey);
    }
    thunkApi.dispatch(userActions.setUserLocation(data));
});

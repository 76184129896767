import classNames from "classnames";
import React, { useRef } from "react";
import "../../../../sass/ui-library/components/simple/RatingPrice.scss";
import { Rating, RatingProps } from "./Rating";
import { PriceIndicator, PriceIndicatorProps } from "./PriceIndicator";
import bem from "../../utils/bem";
import { qaId, QaId } from "../../utils/qa-id";

const ratingPrice = bem("rating-price");

export type RatingPriceProps = RatingProps & PriceIndicatorProps;

export const RatingPrice = ({
    className,
    noOfReviews,
    priceValue,
    ratingOutOf,
    ratingValue,
    type,
    qaIdModifier,
    ...rest
}: RatingPriceProps) => {
    const priceRatingQa = useRef<QaId>(qaId("price-rating", qaIdModifier));
    return (
        <ul className={classNames(ratingPrice.block(), className)} {...rest} {...priceRatingQa.current.block()}>
            {!!ratingValue && (
                <li>
                    <Rating
                        ratingValue={ratingValue}
                        ratingOutOf={ratingOutOf}
                        noOfReviews={noOfReviews}
                        type={type}
                        qaIdModifier={qaIdModifier}
                    />
                </li>
            )}
            {priceValue != null && (
                <li>
                    <PriceIndicator priceValue={priceValue} qaIdModifier={qaIdModifier} />
                </li>
            )}
        </ul>
    );
};

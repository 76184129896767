import React from "react";
import "../../../../sass/ui-library/components/base/ListText.scss";
import { Body } from "./Typography";
import { ParentQaId } from "../../utils/qa-id";
import bem from "../../utils/bem";
import classNames from "classnames";

export type ListTwoLinesTextProps = ParentQaId & {
    topText: string;
    bottomLeftText?: string;
    bottomRightText?: string;
};

const listTwoTextLine = bem("list-two-line-text");

export const ListTwoLinesText = ({ bottomLeftText, bottomRightText, parentQaId, topText }: ListTwoLinesTextProps) => {
    return (
        <div className={listTwoTextLine.block()}>
            <Body className={listTwoTextLine.element("title")} {...(parentQaId ? parentQaId.element("top-text") : {})}>
                {topText}
            </Body>
            <div className={classNames(listTwoTextLine.element("text-bottom"), "d-flex justify-content-between")}>
                <Body
                    size="s"
                    className={listTwoTextLine.element("bottom-left")}
                    {...(parentQaId ? parentQaId.element("left-text") : {})}
                >
                    {bottomLeftText}
                </Body>
                <Body
                    size="s"
                    className={listTwoTextLine.element("bottom-right")}
                    {...(parentQaId ? parentQaId.element("right-text") : {})}
                >
                    {bottomRightText}
                </Body>
            </div>
        </div>
    );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

import { RootState } from "../../RootReducer";
import { selectUserLngLat, selectUserLocationAccuracy, selectUserLocationInfo } from "../user/selectors";
import { actions as userActions } from "../user/reducers";
import { reverseGeocode } from "../../../components/map-page/SearchResults";
import { selectApiKey, selectServiceUrls } from "../global-configuration/selectors";
import { updateUserLocation } from "../user/thunks";
import i18next from "i18next";

const locate = async (): Promise<GeolocationPosition> =>
    new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
    });

export const selectCurrentLocation = createAsyncThunk<void, GeolocationPosition, { state: RootState }>(
    "mapPage/selectCurrentLocation",
    async (props, thunkApi) => {
        const state = thunkApi.getState();

        const { coords } = props;
        const lngLat = { lng: coords.longitude, lat: coords.latitude };
        const userLocationReceived: [number, number] = [coords.longitude, coords.latitude];
        const accuracy = coords.accuracy;

        const userLocation = selectUserLngLat(state);
        const userLocationInfo = selectUserLocationInfo(state);
        const apiKey = selectApiKey(state);
        const serviceUrls = selectServiceUrls(state);
        const language = i18next.language;
        let res;

        if (!isEqual(userLocation, userLocationReceived)) {
            // new current location
            await thunkApi.dispatch(updateUserLocation({ lngLat: userLocationReceived, accuracy }));
            res = await reverseGeocode(lngLat, apiKey, language, serviceUrls);
            thunkApi.dispatch(userActions.updateUserLocationInfo(res));
        } else if (!userLocationInfo) {
            // we don't have user location info yet
            res = await reverseGeocode(lngLat, apiKey, language, serviceUrls);
            thunkApi.dispatch(userActions.updateUserLocationInfo(res));
        }

        return res;
    }
);

export const updateUserPosition = createAsyncThunk<void, void, { state: RootState }>(
    "mapPage/updateUserPosition",
    async (props, thunkApi) => {
        const state = thunkApi.getState();
        const userLocation = selectUserLngLat(state);
        const locationAccuracy = selectUserLocationAccuracy(state);

        if (userLocation) {
            await thunkApi.dispatch(
                selectCurrentLocation({
                    coords: { longitude: userLocation[0], latitude: userLocation[1], accuracy: locationAccuracy }
                } as GeolocationPosition)
            );
        } else {
            const coords = await locate();
            const { longitude, accuracy, latitude } = coords.coords;
            await thunkApi.dispatch(
                selectCurrentLocation({
                    coords: { longitude, latitude, accuracy }
                } as GeolocationPosition)
            );
        }
    }
);

import React, { HTMLAttributes, useRef, useState } from "react";
import Collapsible, { CollapsibleProps } from "react-collapsible";

import "../../../../sass/ui-library/components/simple/Expander.scss";
import { IcArrowDown24, IcArrowUp24 } from "../../icons";
import { ListsItem } from "./Lists";
import classNames from "classnames";
import { qaId, QaId, QaIdModifier, ParentQaId } from "../../utils/qa-id";

const DEFAULT_EXPAND_TIME = 100;
const DEFAULT_COLLAPSE_TIME = 100;

export type ExpanderMinimumProps = QaIdModifier<HTMLAttributes<HTMLElement>> &
    ParentQaId &
    Partial<CollapsibleProps> & {
        title: string;
    };

export type ExpanderWithDescriptionProps = ExpanderMinimumProps & {
    subTitle: string;
};

type ExpanderHeaderProps = ExpanderMinimumProps &
    Partial<ExpanderWithDescriptionProps> & {
        isCollapsed?: boolean;
    };

const ExpanderHeader = ({ className, isCollapsed, subTitle, title, ...rest }: ExpanderHeaderProps) => {
    const collapseIcon = isCollapsed ? (
        <IcArrowDown24 className="expander__arrow" />
    ) : (
        <IcArrowUp24 className="expander__arrow" />
    );
    return (
        <ListsItem
            asSubtitle
            title={title}
            subTitle={subTitle}
            iconRight={collapseIcon}
            className={className}
            {...rest}
            blockElement={"header"}
        />
    );
};

const BaseExpander = (props: ExpanderHeaderProps) => {
    // Need to hide the body element so that the SimpleBar component can correctly calculate
    // dimension when the expander is collapsed.
    // Collapsible's is-closed cannot be used as the class is also present when the height
    // animation is in progress.
    const [bodyHidden, setBodyHidden] = useState(true);

    const expanderQa = useRef<QaId>(props.parentQaId || qaId("expander", props.qaIdModifier));

    const collapsibleBaseClassName = `expander ${props.className || ""}`;
    const collapsibleClassName = classNames(collapsibleBaseClassName, { "invisible-body": bodyHidden });

    return (
        <Collapsible
            {...props}
            className={collapsibleClassName}
            openedClassName={collapsibleBaseClassName}
            trigger={
                <ExpanderHeader
                    title={props.title}
                    subTitle={props.subTitle}
                    isCollapsed
                    parentQaId={expanderQa.current}
                    qaIdModifier={props.qaIdModifier}
                />
            }
            triggerWhenOpen={
                <ExpanderHeader
                    title={props.title}
                    subTitle={props.subTitle}
                    parentQaId={expanderQa.current}
                    qaIdModifier={props.qaIdModifier}
                />
            }
            transitionTime={DEFAULT_EXPAND_TIME}
            transitionCloseTime={DEFAULT_COLLAPSE_TIME}
            onClose={() => setBodyHidden(true)}
            onOpening={() => setBodyHidden(false)}
        >
            <div className="expander__body" {...expanderQa.current.element("body")}>
                {props.children}
            </div>
        </Collapsible>
    );
};

export const Expander = (props: ExpanderMinimumProps) => <BaseExpander {...props} />;

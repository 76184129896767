import React, { forwardRef, MutableRefObject, useLayoutEffect, useRef, useState } from "react";
import SimpleBar, { Props as SimpleBarProps } from "simplebar-react";
import classNames from "classnames";

import "../../../../sass/ui-library/components/base/ScrollBar.scss";
import bem from "../../utils/bem";
import useResizeObserver from "@react-hook/resize-observer";

const scrollBarBem = bem("scroll-bar");

export type ScrollBarProps = SimpleBarProps;

export const ScrollBarWithRef = forwardRef<HTMLElement, ScrollBarProps>(
    ({ className, ...rest }: ScrollBarProps, scrollWrapper: MutableRefObject<HTMLElement>) => {
        const [scrollTopIndicator, setScrollTopIndicator] = useState(false);
        const [scrollBottomIndicator, setScrollBottomIndicator] = useState(false);

        const updateScrollIndicators = () => {
            if (scrollWrapper.current) {
                const currentScrollY = scrollWrapper.current.scrollTop;
                const currentScrollHeight = scrollWrapper.current.scrollHeight;
                const currentClientHeight = scrollWrapper.current.clientHeight;
                setScrollTopIndicator(currentScrollY != 0);
                setScrollBottomIndicator(currentScrollY != currentScrollHeight - currentClientHeight);
            }
        };

        useLayoutEffect(() => updateScrollIndicators(), [scrollWrapper?.current?.scrollHeight]);

        useResizeObserver(scrollWrapper.current, () => updateScrollIndicators());

        return (
            <SimpleBar
                {...rest}
                className={classNames(scrollBarBem.block(), className, {
                    [scrollBarBem.modifier("top-indicator")]: scrollTopIndicator,
                    [scrollBarBem.modifier("bottom-indicator")]: scrollBottomIndicator
                })}
                autoHide={true}
                scrollableNodeProps={{ onScroll: updateScrollIndicators, ref: scrollWrapper }}
            />
        );
    }
);
ScrollBarWithRef.displayName = "ScrollBar";

export const ScrollBar = (props: Omit<ScrollBarProps, "ref">) => {
    const scrollWrapper = useRef<HTMLElement>();
    return <ScrollBarWithRef {...props} ref={scrollWrapper} />;
};

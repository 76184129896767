import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    startedWithPosition: false
};

export const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        updateStartedWithPosition: (state, action: PayloadAction<boolean>) => {
            state.startedWithPosition = action.payload;
        }
    }
});

export const { actions } = navigationSlice;

export default navigationSlice.reducer;

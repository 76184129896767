import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { service } from "@anw/gor-sdk";

import { RootState } from "../../RootReducer";
import { actions as authActions } from "./reducers";
import { actions as applicationActions } from "./../application/reducers";
import { actions as userActions } from "./../user/reducers";
import { actions as suggestEditActions } from "./../map-page/suggest-edit/reducers";
import { fetchJson } from "../../../utils/async";
import { selectAuthConfig } from "../global-configuration/selectors";
import { fetchMyPlaces, fetchOV2Files, getItineraries, savePlace, updateMyPlaces } from "../map-page/my-items/thunks";
import { actions as myItemsActions } from "../map-page/my-items/reducers";
import RemoteLogger from "../../../classes/RemoteLogger";
import { actions as activeDestinationActions } from "../map-page/active-destination/reducers";
import { getActiveItinerary } from "../map-page/active-destination/thunks";
import { retryAsyncThunks } from "../../retryAsyncThunk";
import { TealiumLogger } from "../../../classes/TealiumLogger";

export const signIn = createAsyncThunk<void, { login: string; password: string }, { state: RootState }>(
    "authentication/sign-in",
    async (credentials, thunkApi) => {
        const state = thunkApi.getState();
        const authCfg = selectAuthConfig(state);

        try {
            RemoteLogger.log({
                message: "Sign-in request",
                category: "authentication.thunks.signIn",
                severity: "info"
            });
            const authResponse: { error: { message: string }; data } = await fetchJson(authCfg.signInUrl, {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    username: credentials.login,
                    password: credentials.password
                })
            });

            if (authResponse.error.message) {
                RemoteLogger.log({
                    message: "Sign-in request failed",
                    category: "authentication.thunks.signIn",
                    severity: "warn",
                    data: { error: authResponse.error.message }
                });
                thunkApi.dispatch(authActions.setAuthenticated(false));
                return thunkApi.rejectWithValue(null);
            } else {
                const profile = await service().api.users.fetchProfile({
                    parameters: { viewType: "SUMMARY" },
                    headers: {
                        X_OAUTH_ACCESS_TOKEN: authResponse.data.access_token
                    }
                });

                thunkApi.dispatch(userActions.setCurrentProfile(profile));
                thunkApi.dispatch(authActions.setAuthenticated(true));
                RemoteLogger.log({
                    message: "Sign-in request succeeded",
                    category: "authentication.thunks.signIn",
                    severity: "info"
                });

                let myPlacesFetched = false;
                if (state.application.retryAction) {
                    const { retryActionName, retryActionPayload } = state.application.retryAction;
                    if (retryActionName === "open_suggest_edit_modal") {
                        // special case, need to do this first to get the favourites collection id
                        thunkApi.dispatch(suggestEditActions.setIsSuggestEditModalOpened(true));
                    } else {
                        if (retryActionName === savePlace.typePrefix) {
                            myPlacesFetched = true;
                            // special case, need to do this first to get the favourites collection id
                            await thunkApi.dispatch(fetchMyPlaces());
                        }
                        const thunk = retryAsyncThunks.get(retryActionName);
                        if (retryActionPayload) {
                            await thunkApi.dispatch(thunk(retryActionPayload));
                        } else {
                            // we don't have payload
                            await thunkApi.dispatch((thunk as AsyncThunk<any, void, any>)());
                        }
                    }
                }
                !myPlacesFetched && thunkApi.dispatch(fetchMyPlaces());
                thunkApi.dispatch(fetchOV2Files());
                thunkApi.dispatch(getItineraries());
                thunkApi.dispatch(getActiveItinerary());
            }
            thunkApi.dispatch(applicationActions.setRetryAction(null));
        } catch (e) {
            console.info("Login ERROR", e, e.message);

            RemoteLogger.log({
                message: "Sign-in flow failed",
                category: "authentication.thunks.signIn",
                severity: "error",
                data: { error: e.message }
            });

            thunkApi.dispatch(authActions.setAuthenticated(false));
            thunkApi.dispatch(applicationActions.setRetryAction(null));
        }
    }
);

export const signOut = createAsyncThunk<void, boolean, { state: RootState }>(
    "authentication/sign-out",
    async (userInitiated, thunkApi) => {
        RemoteLogger.log({
            message: "Sign-out request",
            category: "authentication.thunks.signOut",
            severity: "info"
        });

        const state = thunkApi.getState();
        const authCfg = selectAuthConfig(state);

        try {
            thunkApi.dispatch(myItemsActions.resetBusyItems());
            await fetch(authCfg.signOutUrl, {
                credentials: "include",
                method: "PUT",
                headers: {
                    "Content-Type": "text/plain"
                }
            });
            if (userInitiated) {
                TealiumLogger.link({
                    event_name: "logout",
                    status: "completed"
                });
            }
        } catch (e) {
            RemoteLogger.log({
                message: "Sign-out request failed",
                category: "authentication.thunks.signOut",
                severity: "error",
                data: { error: e.message }
            });
            if (userInitiated) {
                TealiumLogger.link({
                    event_name: "logout",
                    status: "failed"
                });
            }
        } finally {
            thunkApi.dispatch(authActions.setAuthenticated(false));
            thunkApi.dispatch(userActions.setCurrentProfile(null));
            thunkApi.dispatch(updateMyPlaces([]));
            thunkApi.dispatch(myItemsActions.setOV2Files([]));
            thunkApi.dispatch(myItemsActions.setUserItineraries([]));
            thunkApi.dispatch(activeDestinationActions.setActiveItinerary(null));
            thunkApi.dispatch(applicationActions.setRetryAction(null));
        }
    }
);

export const checkAuthentication = createAsyncThunk<void, void>("authentication/check", async (location, thunkApi) => {
    try {
        const profile = await service().api.users.fetchProfile({
            parameters: { viewType: "SUMMARY" }
        });

        if (profile) {
            thunkApi.dispatch(userActions.setCurrentProfile(profile));
            thunkApi.dispatch(authActions.setAuthenticated(true));
            RemoteLogger.log({
                message: "User is already signed in",
                category: "authentication.thunks.checkAuthentication",
                severity: "info"
            });
            // because this is used only at the start application no action should require retry
            thunkApi.dispatch(fetchMyPlaces());
            thunkApi.dispatch(fetchOV2Files());
            thunkApi.dispatch(getItineraries());
            thunkApi.dispatch(getActiveItinerary());
        }
    } catch (error) {
        console.info(error);
        RemoteLogger.log({
            message: "User is not signed in",
            category: "authentication.thunks.checkAuthentication",
            severity: "info"
        });
        try {
            // make sure that we delete expired/invalid auth cookie
            await thunkApi.dispatch(signOut(false));
        } catch (e) {
            // ignore errors if any
        }
    }
});

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    getLocalStorageBoolean,
    getLocalStorageJson,
    setLocalStorageValue,
    setLocalStorageJson
} from "../../../../utils/localStorage";
import { MapStyleEditInput, StyleLayerGroup } from "../../../../utils/mapStyle";

const trafficIncidentsToggledKey = "traffic-incidents-toggled";
const trafficFlowToggledKey = "traffic-flow-toggled";
const scenicSegmentsToggledKey = "scenic-segments-toggled";
const roadTripsToggledKey = "tomtom-road-trips-toggled";
const riderRoutesToggledKey = "tomtom-rider-routes-toggled";
const myPlacesToggledKey = "my-places-toggled";
const selectAllPOIsToggledKey = "select-all-pois-toggled";
const otherPOIsToggledKey = "other-pois-toggled";
const hiddenPOIsCategoryGroupIconIDsKey = "hidden-pois-icon-ids";

type MapOptionsDisplayedType = { displayed: boolean; fromTrafficShortcut?: boolean; fromRoadTripShortcut?: boolean };

export type MapStyleEdit = Record<StyleLayerGroup, MapStyleEditInput>;

/**
 * Map style edits link each style TTM ID to a set of edits for each layer group.
 */
export type MapStyleEdits = Record<string, MapStyleEdit>;

const initialState = {
    mapOptionsDisplayed: { displayed: false } as MapOptionsDisplayedType,
    mapStyleIDBeingEdited: null as string,
    mapStyleEdits: {} as MapStyleEdits,
    trafficIncidentsToggled: getLocalStorageBoolean(trafficIncidentsToggledKey),
    trafficFlowToggled: getLocalStorageBoolean(trafficFlowToggledKey),
    scenicSegmentsToggled: getLocalStorageBoolean(scenicSegmentsToggledKey),
    roadTripsToggled: getLocalStorageBoolean(roadTripsToggledKey),
    riderRoutesToggled: getLocalStorageBoolean(riderRoutesToggledKey),
    isMyPlacesToggled: getLocalStorageBoolean(myPlacesToggledKey, true),
    isSelectAllPOIsToggled: getLocalStorageBoolean(selectAllPOIsToggledKey, true),
    isOtherPOIsToggled: getLocalStorageBoolean(otherPOIsToggledKey, true),
    // every hidden category group is represented by array of it's categories icon IDs
    // example [[231, 320, 257]] represents excluding Food & drinks group, [[231, 320, 257], [203, 195]] represents excluding Food & drinks, Parking groups.
    hiddenPOIsCategoryGroupIconIDs: getLocalStorageJson(hiddenPOIsCategoryGroupIconIDsKey, [] as number[][])
};

export const mapControlsSlice = createSlice({
    name: "mapControls",
    initialState,
    reducers: {
        toggleMapOptionsDisplay: (state, action: PayloadAction<boolean>) => {
            state.mapOptionsDisplayed = { displayed: action.payload };
            state.mapStyleIDBeingEdited = null;
        },
        setMapStyleIDBeingEdited: (state, action: PayloadAction<string>) => {
            state.mapStyleIDBeingEdited = action.payload;
        },
        putMapStyleEdit: (
            state,
            action: PayloadAction<{ styleID: string; layerGroup: StyleLayerGroup; props: MapStyleEditInput }>
        ) => {
            const styleID = action.payload.styleID;
            const layerGroup = action.payload.layerGroup;
            state.mapStyleEdits[styleID] = {
                ...state.mapStyleEdits[styleID],
                [layerGroup]: {
                    ...state.mapStyleEdits[styleID]?.[layerGroup],
                    ...action.payload.props
                }
            };
        },
        removeMapStyleEdit: (state, action: PayloadAction<{ styleID: string }>) => {
            delete state.mapStyleEdits[action.payload.styleID];
        },
        openMapOptionsFromTrafficShortcut: (state) => {
            state.mapOptionsDisplayed = { displayed: true, fromTrafficShortcut: true };
        },
        openMapOptionsFromRoadTripShortcut: (state) => {
            state.mapOptionsDisplayed = { displayed: true, fromRoadTripShortcut: true };
        },
        toggleTrafficIncidents: (state, action: PayloadAction<boolean>) => {
            state.trafficIncidentsToggled = action.payload;
            setLocalStorageValue(trafficIncidentsToggledKey, action.payload);
        },
        toggleTrafficFlow: (state, action: PayloadAction<boolean>) => {
            state.trafficFlowToggled = action.payload;
            setLocalStorageValue(trafficFlowToggledKey, action.payload);
        },
        toggleScenicSegments: (state, action: PayloadAction<boolean>) => {
            state.scenicSegmentsToggled = action.payload;
            setLocalStorageValue(scenicSegmentsToggledKey, state.scenicSegmentsToggled);
        },
        toggleRoadTrips: (state, action: PayloadAction<boolean>) => {
            state.roadTripsToggled = action.payload;
            setLocalStorageValue(roadTripsToggledKey, state.roadTripsToggled);
        },
        toggleRiderRoutes: (state, action: PayloadAction<boolean>) => {
            state.riderRoutesToggled = action.payload;
            setLocalStorageValue(riderRoutesToggledKey, state.riderRoutesToggled);
        },
        updateHiddenPOIsCategoryGroupIconIDs: (state, action: PayloadAction<number[][]>) => {
            state.hiddenPOIsCategoryGroupIconIDs = action.payload;
            setLocalStorageJson(hiddenPOIsCategoryGroupIconIDsKey, action.payload);
        },
        toggleMyPlaces: (state, action: PayloadAction<boolean>) => {
            state.isMyPlacesToggled = action.payload;
            setLocalStorageValue(myPlacesToggledKey, state.isMyPlacesToggled);
        },
        toggleSelectAllPOIs: (state, action: PayloadAction<boolean>) => {
            state.isSelectAllPOIsToggled = action.payload;
            setLocalStorageValue(selectAllPOIsToggledKey, action.payload);
        },
        toggleOtherPOIs: (state, action: PayloadAction<boolean>) => {
            state.isOtherPOIsToggled = action.payload;
            setLocalStorageValue(otherPOIsToggledKey, action.payload);
        }
    }
});

export const actions = mapControlsSlice.actions;

export default mapControlsSlice.reducer;

import { ActiveItinerary } from "@anw/gor-sdk";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    activeItinerary: null as ActiveItinerary
};

export const activeDestinationSlice = createSlice({
    name: "activeDestination",
    initialState,
    reducers: {
        setActiveItinerary: (state, action: PayloadAction<ActiveItinerary>) => {
            state.activeItinerary = action.payload;
        }
    }
});

export const { actions } = activeDestinationSlice;

export default activeDestinationSlice.reducer;

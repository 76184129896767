import { combineReducers } from "@reduxjs/toolkit";

import planner from "./planner/reducers";
import search from "./search/reducers";
import header from "./header/reducers";
import map from "./map/reducers";
import mapControls from "./map-controls/reducers";
import location from "./location/reducers";
import settings from "./settings/reducers";
import myItems from "./my-items/reducers";
import activeDestination from "./active-destination/reducers";
import suggestEdit from "./suggest-edit/reducers";

export const mapPageSlice = combineReducers({
    planner,
    search,
    header,
    location,
    map,
    mapControls,
    settings,
    myItems,
    activeDestination,
    suggestEdit
});

export default mapPageSlice;

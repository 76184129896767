import React, { HTMLAttributes, ReactNode, useRef } from "react";

import "../../../../sass/ui-library/components/simple/Lists.scss";
import classNames from "classnames";
import { Checkbox, Switch, ToggleBaseProps } from "../base/Toggles";
import { ListTwoLinesText } from "../base/ListText";
import { Body, Subtitle } from "../base/Typography";
import { Tooltip } from "../base/Tooltip";
import { IcAdd16 } from "../../icons";
import { ParentQaId, qaId, QaId, QaIdModifier } from "../../utils/qa-id";
import bem from "../../utils/bem";

const listsItem = bem("lists-item");

export type ListsItemMinimumProps = QaIdModifier<Omit<HTMLAttributes<HTMLDivElement>, "title">> &
    ParentQaId & {
        title: string | ReactNode;
        subTitle?: string;
        additionalInfo?: string;
        asSubtitle?: boolean;
        asWrapper?: boolean;
        asNavigation?: boolean;
        compact?: boolean;
        noSidePadding?: boolean;
        tooltipText?: string;
        clickable?: boolean;
    };

export type ListsItemProps = ListsItemMinimumProps & {
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    onRightIconClick?: () => void;
    asInteractive?: boolean;
    trailingText?: string;
};

export type ListsItemFormProps = ListsItemMinimumProps &
    Omit<ToggleBaseProps, "text" | "title"> & {
        position?: "left" | "right";
    };

export type ListsItemBaseProps = ListsItemProps &
    Partial<ListsItemFormProps> & {
        formType?: "checkbox" | "switch";
    };

const ListsItemBase = ({
    asSubtitle,
    asNavigation,
    asInteractive,
    className,
    formType,
    iconLeft,
    iconRight,
    id,
    subTitle,
    additionalInfo,
    title,
    compact,
    noSidePadding,
    asWrapper,
    tooltipText,
    clickable,
    onClick,
    onRightIconClick,
    parentQaId,
    qaIdModifier,
    blockElement,
    position = "right",
    trailingText,
    ...rest
}: ListsItemBaseProps) => {
    const listsQa = useRef<QaId>(parentQaId || qaId("lists", qaIdModifier));

    const createTitleElement = () => {
        if (typeof title == "string") {
            if (asSubtitle) {
                return (
                    <Subtitle size="s" caps {...listsQa.current.element("text")}>
                        {title}
                    </Subtitle>
                );
            }
            return (
                <ListTwoLinesText
                    topText={title}
                    bottomLeftText={subTitle}
                    bottomRightText={additionalInfo}
                    parentQaId={listsQa.current}
                />
            );
        } else {
            return title;
        }
    };

    return (
        <div
            className={classNames(listsItem.block(), className, {
                [listsItem.modifier("two-line")]: subTitle,
                [listsItem.modifier("subtitle")]: asSubtitle,
                [listsItem.modifier("navigation")]: asNavigation,
                [listsItem.modifier("interactive")]: asInteractive,
                [listsItem.modifier("compact")]: compact,
                [listsItem.modifier("no-side-padding")]: noSidePadding,
                [listsItem.modifier("form")]: formType,
                [listsItem.modifier("clickable")]: clickable
            })}
            {...listsQa.current.block(blockElement || (parentQaId && "lists"))}
            onClick={onClick}
        >
            {asWrapper && rest.children}
            {!asWrapper && (
                <div className={classNames(listsItem.element("wrapper"))}>
                    {iconLeft && (
                        <div
                            className={classNames(listsItem.element("icon"), listsItem.element("icon--left"))}
                            {...listsQa.current.element("icon-left")}
                        >
                            {iconLeft}
                        </div>
                    )}
                    {(formType && (
                        <label
                            htmlFor={id}
                            className={classNames(listsItem.element("text"))}
                            {...listsQa.current.element("text")}
                            {...{ disabled: rest.disabled }}
                        >
                            {title}
                            {tooltipText && <Tooltip icon={<IcAdd16 />} message={tooltipText} />}
                        </label>
                    )) || <div className={classNames(listsItem.element("text"))}>{createTitleElement()}</div>}

                    {(iconRight && (
                        <div
                            className={classNames(
                                onRightIconClick && listsItem.element("icon--interactive"),
                                listsItem.element("icon"),
                                listsItem.element("icon--right")
                            )}
                            {...listsQa.current.element("icon-right")}
                            {...(onRightIconClick && { onClick: onRightIconClick })}
                        >
                            {iconRight}
                        </div>
                    )) ||
                        (trailingText && (
                            <Body
                                size={"s"}
                                className={classNames(listsItem.element("trailing-text"))}
                                {...listsQa.current.element("trailing-text")}
                            >
                                {trailingText}
                            </Body>
                        ))}
                    {formType && (
                        <div
                            className={classNames(listsItem.element("control"), {
                                [listsItem.element("control--left")]: position === "left"
                            })}
                        >
                            {formType === "checkbox" && <Checkbox id={id} parentQaId={listsQa.current} {...rest} />}
                            {formType === "switch" && <Switch id={id} parentQaId={listsQa.current} {...rest} />}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export const ListsItemWithCheckbox = (props: ListsItemFormProps) => <ListsItemBase {...props} formType="checkbox" />;

export const ListsItemWithToggle = (props: ListsItemFormProps) => <ListsItemBase {...props} formType="switch" />;

export const ListsItem = (props: ListsItemProps) => <ListsItemBase {...props} />;

export const ListsItemWrapper = (props: Partial<ListsItemProps>) => (
    <ListsItemBase asWrapper compact title="" {...props} />
);

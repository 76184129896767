import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import omit from "lodash/omit";

import { getLocalStorageValue, setLocalStorageJson } from "../../../utils/localStorage";

export const featureConfigsKey = "feature-configs";

export const CUSTOM_URL_STYLE_ID = "CUSTOM_URL";

export type MapStyleConfigBase = {
    /**
     * The style URL. Api KEY section should be excluded.
     */
    style: string;

    /**
     * Reference layer from the base map style to render e.g. our own route lines below.
     */
    layerToRenderLinesUnder?: string;
};

export type MapStyleGlobalConfig = MapStyleConfigBase & {
    /**
     * An internal ID for the config, used for us to recognize it.
     */
    id: string;
    /**
     * Title to display in UI.
     */
    title: string;
    /**
     * The korean style URL. Api KEY section should be excluded.
     */
    styleKr?: string;
    /**
     * Whether the style is considered experimental (to be shown in the lab menu) vs official.
     */
    experimental?: boolean;
    /**
     * Whether the style is part of Orbis.
     */
    orbis?: boolean;
};

export type ServiceSource = "GLOBAL" | "KOREA" | "ORBIS";

export type ServiceUrls = {
    reverseGeocodeEndpoint: string;
    placeByIdEndpoint: string;
    searchEndpoint: string;
    chargingAvailabilityEndpoint: string;
};

export type EndpointServiceUrls = {
    [serviceSource in ServiceSource]: ServiceUrls;
};

export const defaultGlobalConfiguration = {
    apiKey: "",
    map: {
        attribution: {
            ORBIS: {
                url: ""
            },
            OTHER: {
                url: ""
            }
        },
        defaultLanguage: "en-GB",
        // If no styles are pre-configured, the map SDK should pick the default one.
        // However, it's strongly recommended we always configure at least one here with 'layerToRenderLinesUnder' defined.
        styles: [] as MapStyleGlobalConfig[],
        itineraryCollectionTilesURL: ""
    },
    search: {
        apiKeySearch: "", // @deprecated
        maxResults: 25,
        endpointServiceUrls: {
            GLOBAL: {
                chargingAvailabilityEndpoint: "",
                placeByIdEndpoint: "",
                reverseGeocodeEndpoint: "",
                searchEndpoint: ""
            }
        } as EndpointServiceUrls
    },
    ngs: {
        apiKey: "",
        baseURL: ""
    },
    gor: {
        baseURL: "https://api-dev.mydrive.tomtom.com",
        locale: "en_gb",
        defaultHeaders: {
            GOR_CLIENT_APP_ID: "TTM",
            GOR_CLIENT_APP_VERSION: APP_VERSION
        }
    },
    i18n: {
        availableLocales: ["en-GB"]
    },
    analytics: {
        envKey: "dev"
    },
    featureConfigs: {
        // optional array to hide specific feature configs completely in the UI:
        hidden: [] as string[],
        interactiveRouteMarkers: false,
        extraPlanningTrafficInfo: false,
        detectCurves: false,
        routeTimeline: false,
        boostedMapPOIsFromCategorySearch: false,
        boostedMapPOIsFromSelectedLocation: false,
        // Refers to: map.styles[X].id
        selectedMapStyle: { id: "DEFAULT", orbis: false },
        defaultMapStyleID: "DEFAULT",
        allowCustomURLStyles: true,
        enableLDEV: false,
        userMapStyling: false,
        vertexFeedback: false,
        serviceSource: getLocalStorageValue<ServiceSource>("serviceSource", "GLOBAL")
    },
    authentication: {
        signInUrl: "",
        signOutUrl: "",
        type: "simple",
        enabled: false
    },
    externalLinks: {
        tomtomURL: "https://www.tomtom.com",
        create_account: "account/create.html",
        account_details: "account/details.html",
        forgot_password: "account/forgot-password.html"
    },
    legalLinks: {
        support: "https://help.tomtom.com/hc",
        privacy: "privacy",
        legal: "legal"
    },
    routeSync: {
        helpURL: ""
    }
};

export const globalConfigurationSlice = createSlice({
    name: "globalConfiguration",
    initialState: defaultGlobalConfiguration,
    reducers: {
        putFeatureConfigs: (
            state,
            action: PayloadAction<Partial<typeof defaultGlobalConfiguration.featureConfigs>>
        ) => {
            Object.assign(state.featureConfigs, action.payload);
            setLocalStorageJson(featureConfigsKey, omit(state.featureConfigs, "hidden"));
        },
        putCustomURLMapStyle: (state, action: PayloadAction<MapStyleConfigBase>) => {
            const styles = state.map.styles;
            const index = styles.findIndex((style) => style.id == CUSTOM_URL_STYLE_ID);
            const customStyle: MapStyleGlobalConfig = {
                id: CUSTOM_URL_STYLE_ID,
                title: "Custom URL",
                style: action.payload.style,
                layerToRenderLinesUnder: action.payload.layerToRenderLinesUnder,
                experimental: true
            };
            if (index != -1) {
                styles[index] = customStyle;
            } else {
                styles.push(customStyle);
            }
        }
    }
});

export const { actions } = globalConfigurationSlice;

export default globalConfigurationSlice.reducer;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { connectRouter, routerMiddleware } from "connected-react-router";

import BrowserHistory from "./BrowserHistory";
import { isRelease } from "../utils/agent";

import globalConfiguration from "./tree/global-configuration/reducers";
import mapPage from "./tree/map-page/reducers";
import RemoteLogger from "../classes/RemoteLogger";
import userReducer from "./tree/user/reducers";
import applicationReducer from "./tree/application/reducers";
import navigationReducer from "./tree/navigation/reducers";
import authenticationReducer from "./tree/authentication/reducers";
import notificationReducer from "./tree/notification/reducers";

const logger = () => (next) => (action) => {
    // tracking router navigation
    if (action.type === "@@router/LOCATION_CHANGE") {
        RemoteLogger.log({
            message: `Navigating to ${action.payload.location.pathname}`,
            category: "RootReducer",
            severity: "info"
        });
    }

    return next(action);
};

export const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        globalConfiguration,
        user: userReducer,
        authentication: authenticationReducer,
        mapPage,
        application: applicationReducer,
        navigation: navigationReducer,
        notification: notificationReducer
    });

export const configureAppStore = (preloadedState) => {
    return configureStore({
        reducer: createRootReducer(BrowserHistory.Instance),
        preloadedState,
        // TODO Skip logger and devtools for tests
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(routerMiddleware(BrowserHistory.Instance), logger),
        devTools: isRelease() ? false : { trace: true, traceLimit: 50, maxAge: 50, shouldCatchErrors: true }
    });
};

export type RootState = ReturnType<ReturnType<typeof configureAppStore>["getState"]>;
export type AppDispatch = ReturnType<typeof configureAppStore>["dispatch"];
export type AppDispatchProp = { dispatch: AppDispatch };
export const useAppDispatch = () => useDispatch<AppDispatch>();

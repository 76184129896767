import { createAsyncThunk } from "@reduxjs/toolkit";
import isNil from "lodash/isNil";

import { RootState } from "../../../RootReducer";
import { selectMapCenterCoordinates, selectViewableMapBBox } from "../map/selectors";
import {
    checkIfResultsOutsideViewport,
    detectRecoveryVsDiscovery,
    highlightSearchResults,
    ngsCheckIfResultsOutsideViewport,
    searchItems,
    withSearchText
} from "../../../../utils/search";
import { recentSearchesKey, searchActions } from "./reducers";
import { changeSelectedLocation } from "../location/thunks";
import { actions as locationActions } from "./../location/reducers";
import {
    selectAreLatestResultsDisplayed,
    selectIsAutoCompleteSegmentSelectedByUser,
    selectIsFirstTimeLoaded,
    selectMatchedAutoCompleteSearch,
    selectViewportCheckTempLocations
} from "./selectors";
import { retrievePositionFromURL, retrieveQueryFromSearch } from "../../navigation/selectors";
import { selectors } from "./index";
import { MatchedAutoCompleteSearch, SearchIntention } from "./types";
import { detailsToTTMSearchResult, getLocationInfo, locationInfosToMapBounds } from "../../../../utils/location";
import { TTMLocation, TTMSearchableResult, TTMSearchResult, TTMUserMapLocation } from "../../../../utils/locationTypes";
import { URL_PATH_MAP } from "../../../../components/elements/Links";
import { navigateHome, updateWithSearchQuery } from "../../navigation/thunks";
import { logEventWithActiveMode } from "../../application/thunks";
import { setLocalStorageJson } from "../../../../utils/localStorage";
import { MAIN_LIST } from "../../../../utils/analytics";
import { isExactURLPathMatch } from "../../../../classes/localization/Localization";
import { discover, suggest } from "../../../../services/ngs/ngsClient";
import { mapDiscoverToTTMSearchResults, mapToTTMSearchResults } from "../../../../services/ngs/ngsAdapter";
import { isSearchType, ResultType } from "../../../../services/ngs/ngsAPITypes";
import { fetchPoiById } from "../../../../hooks/useFetchPoi";
import i18next from "i18next";

export const recentSearchMaxListSize = 5;

type SearchByBrandCategoryFilterData = {
    query?: string;
    matchedAutoCompleteSearch: MatchedAutoCompleteSearch;
    boundingBox?: [[number, number], [number, number]];
    isFinalCall: boolean;
};
type FetchSearchResultsData = { query: string; center?: { lng: number; lat: number }; isFinalCall?: boolean };
type AutocompleteSegmentClickedData = {
    boundingBox?: [[number, number], [number, number]];
    autocompleteSegment: TTMSearchResult;
};

export const initialNumOfResults = 10;

export const searchByBrandCategoryFilter = createAsyncThunk<
    void,
    SearchByBrandCategoryFilterData,
    { state: RootState }
>("searchPage/searchByBrandCategoryFilter", async (data, thunkApi) => {
    function updatePosition(link: string, center: { lat: number; lng: number }): string {
        const newLink = new URLSearchParams(link);
        newLink.delete("boundingBox");
        newLink.set("position", `${center.lat},${center.lng}`);
        return decodeURIComponent(newLink.toString());
    }

    function replaceBoundingBox(link: string, newBoundingBox: [[number, number], [number, number]]): string {
        const newLink = new URLSearchParams(link);
        newLink.delete("boundingBox");
        newLink.delete("position");
        newLink.append("boundingBox", `${newBoundingBox[1][1]},${newBoundingBox[0][0]},${newBoundingBox[0][1]},${newBoundingBox[1][0]}`);
        return decodeURIComponent(newLink.toString());
    }

    const {
        matchedAutoCompleteSearch: { result },
        boundingBox,
        isFinalCall
    } = data;

    let state = thunkApi.getState();
    const requestId = Date.now();

    const center = selectMapCenterCoordinates(state);
    let discoverLink = result.link;

    if (!isFinalCall && !boundingBox) {
        discoverLink = updatePosition(result.link, center);
    } else if (boundingBox) {
        discoverLink = replaceBoundingBox(result.link, boundingBox);
    }

    try {
        const discoverCall = discover({
            link: discoverLink
        });

        const [, , discoverResponse] = await Promise.all([
            thunkApi.dispatch(searchActions.setPendingRequest({ requestId })),
            thunkApi.dispatch(searchActions.setAreLatestResultsDisplayed(isFinalCall)),
            discoverCall
        ]);

        // get the LATEST state
        state = thunkApi.getState();

        const results = mapDiscoverToTTMSearchResults(discoverResponse.results);

        // if it's the final viewport call, populate results on map
        if (isFinalCall) {
            // update the results only if the response belongs to the last request
            if (requestId === state.mapPage.search.lastRequestId) {
                thunkApi.dispatch(
                    // @ts-ignore
                    searchActions.setSearchCategoryBrandFinal(results)
                );
            }
        } else {
            thunkApi.dispatch(
                // @ts-ignore
                searchActions.setSearchCategoryBrandTemporary(results)
            );
        }
    } catch (e) {
        thunkApi.dispatch(searchActions.setSearchError(e));
        thunkApi.rejectWithValue(JSON.stringify(e));
    }
});

export const fetchSearchAndAutocompleteSuggestions = createAsyncThunk<
    void,
    FetchSearchResultsData,
    { state: RootState }
>("searchPage/fetchSearchAndAutocompleteSuggestions", async ({ isFinalCall = true, ...data }, thunkApi) => {
    let state = thunkApi.getState();

    // *** My-Places and History Search section: see similar logic in planner/thunks/fetchSearchResults
    const searchIDsToSkip: string[] = [];
    let myPlacesSearchResults: TTMUserMapLocation[] = [];
    if (state.mapPage.myItems.myPlaces) {
        myPlacesSearchResults = searchItems(state.mapPage.myItems.myPlaces, data.query)?.slice(0, 1) || [];
        thunkApi.dispatch(searchActions.updateMyPlacesSearchResults(myPlacesSearchResults));
        searchIDsToSkip.push(...myPlacesSearchResults.map((result) => result.mapLocation.locationInfo?.externalID));
    }

    let historySearchResults: TTMSearchableResult[] = [];
    if (state.mapPage.search.recentSearches) {
        historySearchResults =
            searchItems(state.mapPage.search.recentSearches, data.query)
                ?.slice(0, 1)
                .filter((result) => !searchIDsToSkip.includes(result.externalID)) || [];
        searchIDsToSkip.push(...historySearchResults.map((result) => result.externalID));
        thunkApi.dispatch(searchActions.updateHistorySearchResults(historySearchResults));
    }
    // *** end of My-Places and History Search section

    try {
        const requestId = Date.now();
        const center =
            !isNil(data.center?.lng) && !isNil(data.center?.lat) ? data.center : selectMapCenterCoordinates(state);

        const suggestCall = suggest({
            query: data.query,
            position: `${center.lat},${center.lng}`,
            language: i18next.language,
            viewport: selectViewableMapBBox(state)
        });

        const [, , suggestResponse] = await Promise.all([
            thunkApi.dispatch(searchActions.setPendingRequest({ requestId })),
            thunkApi.dispatch(searchActions.resetAutocomplete()),
            suggestCall
        ]);

        const filteredResults = suggestResponse.results.filter((result) =>
            isSearchType(result.type) ? !searchIDsToSkip.includes(result.id) : true
        );

        const matchedAutocomplete: MatchedAutoCompleteSearch = {
            searchIntention: SearchIntention.DISCOVERY,
            matchedBrand: undefined,
            matchedCategory: undefined,
            matchedTextValue: undefined
        };
        //  detectRecoveryVsDiscovery(filteredResults);

        const results = mapToTTMSearchResults(filteredResults);

        // if the user intention detected to be recovery, this will be the last call
        // if it is discovery there could be an additional call to filter by detected brand/category
        if (matchedAutocomplete.searchIntention == SearchIntention.RECOVERY) {
            isFinalCall = true;
        }
        thunkApi.dispatch(searchActions.setAreLatestResultsDisplayed(isFinalCall));

        state = thunkApi.getState();

        if (isFinalCall) {
            const noResultsFound = !results.length && !myPlacesSearchResults.length && !historySearchResults.length;
            // update the results only if the response belongs to the last request
            if (requestId === state.mapPage.search.lastRequestId) {
                thunkApi.dispatch(
                    searchActions.setSearchAndAutocompleteFinal({
                        results
                    })
                );
            }
            if (noResultsFound) {
                thunkApi.dispatch(searchActions.setNoResultsFoundError(true));
            }
        } else {
            thunkApi.dispatch(
                searchActions.setSearchAndAutocompleteTemporary({
                    results
                })
            );
        }
    } catch (e) {
        return thunkApi.rejectWithValue(JSON.stringify(e));
    }
});

export const fetchSuggestionsForViewport = createAsyncThunk<
    void,
    FetchSearchResultsData,
    { state: RootState }
>("searchPage/fetchSuggestionsForViewport", async ({ ...data }, thunkApi) => {
    const state = thunkApi.getState();

    // *** My-Places and History Search section: see similar logic in planner/thunks/fetchSearchResults
    const searchIDsToSkip: string[] = [];
    let myPlacesSearchResults: TTMUserMapLocation[] = [];
    if (state.mapPage.myItems.myPlaces) {
        myPlacesSearchResults = searchItems(state.mapPage.myItems.myPlaces, data.query)?.slice(0, 1) || [];
        thunkApi.dispatch(searchActions.updateMyPlacesSearchResults(myPlacesSearchResults));
        searchIDsToSkip.push(...myPlacesSearchResults.map((result) => result.mapLocation.locationInfo?.externalID));
    }

    let historySearchResults: TTMSearchableResult[] = [];
    if (state.mapPage.search.recentSearches) {
        historySearchResults =
            searchItems(state.mapPage.search.recentSearches, data.query)
                ?.slice(0, 1)
                .filter((result) => !searchIDsToSkip.includes(result.externalID)) || [];
        searchIDsToSkip.push(...historySearchResults.map((result) => result.externalID));
        thunkApi.dispatch(searchActions.updateHistorySearchResults(historySearchResults));
    }
    // *** end of My-Places and History Search section

    try {
        const requestId = Date.now();
        const center =
            !isNil(data.center?.lng) && !isNil(data.center?.lat) ? data.center : selectMapCenterCoordinates(state);

        const suggestCall = suggest({
            query: data.query,
            position: `${center.lat},${center.lng}`,
            language: i18next.language,
            viewport: selectViewableMapBBox(state),
            limit: 25
        });

        const [, suggestResponse] = await Promise.all([
            thunkApi.dispatch(searchActions.setPendingRequest({ requestId })),
            suggestCall
        ]);

        const filteredResults = suggestResponse.results.filter((result) =>
            isSearchType(result.type) ? !searchIDsToSkip.includes(result.id) : true
        );

        const results = mapToTTMSearchResults(filteredResults);

        thunkApi.dispatch(searchActions.setAreLatestResultsDisplayed(false));

        thunkApi.dispatch(
            searchActions.setSearchAndAutocompleteTemporary({
                results
            })
        );
    } catch (e) {
        return thunkApi.rejectWithValue(JSON.stringify(e));
    }
});

export const fetchInitialSearchData = createAsyncThunk<void, void, { state: RootState }>(
    "searchPage/fetchInitialSearchData",
    async (data, thunkApi) => {
        const state = thunkApi.getState();
        const isFirstTimeLoaded = selectIsFirstTimeLoaded(state);

        if (isFirstTimeLoaded) {
            thunkApi.dispatch(searchActions.setIsFirstTimeLoaded(false));

            const query = retrieveQueryFromSearch(state);
            const center = retrievePositionFromURL(state);

            if (query) {
                await thunkApi.dispatch(
                    fetchSearchAndAutocompleteSuggestions({
                        query,
                        center
                    })
                );
                highlightSearchResults(query);
            }
        }
    }
);

export const fetchSearchThisArea = createAsyncThunk<void, void, { state: RootState }>(
    "searchPage/fetchSearchThisArea",
    async (data, thunkApi) => {
        const state = thunkApi.getState();
        const matchedAutoCompleteSearch = selectMatchedAutoCompleteSearch(state);

        thunkApi.dispatch(
            searchByBrandCategoryFilter({
                matchedAutoCompleteSearch,
                boundingBox: state.mapPage.map.viewableBBox,
                isFinalCall: true
            })
        );
        thunkApi.dispatch(searchActions.setSearchNeedsUpdate(false));
    }
);

export const autocompleteSegmentClicked = createAsyncThunk<void, AutocompleteSegmentClickedData>(
    "searchPage/autocompleteSegmentClicked",
    async ({ boundingBox, autocompleteSegment }, thunkApi) => {
        const autocompleteMatch: MatchedAutoCompleteSearch = {
            searchIntention: SearchIntention.DISCOVERY,
            matchedCategory: "",
            matchedBrand: "",
            matchedTextValue: "",
            result: autocompleteSegment
        };

        autocompleteSegment.ngsType === ResultType.BRAND
            ? (autocompleteMatch.matchedBrand = autocompleteSegment.name)
            : (autocompleteMatch.matchedCategory = autocompleteSegment.externalID);
        autocompleteMatch.matchedTextValue = autocompleteSegment.name;

        thunkApi.dispatch(searchActions.updateMatchedAutoCompleteSearch(autocompleteMatch));
        // set flag to indicate that brand/category was selected by the user to avoid re-detect the matched segment
        thunkApi.dispatch(searchActions.updateIsAutoCompleteSegmentSelectedByUser(true));
        // extra search call filtered by clicked brand/category (update results to help defining the new viewport for the final call)
        await thunkApi.dispatch(
            searchByBrandCategoryFilter({
                matchedAutoCompleteSearch: autocompleteMatch,
                isFinalCall: false
            })
        );
        thunkApi.dispatch(searchActions.changeSearchInput(autocompleteSegment.name));
        thunkApi.dispatch(searchActions.confirmSearchInput(true));
        thunkApi.dispatch(updateWithSearchQuery(autocompleteSegment.name));
    }
);

export const searchResultClicked = createAsyncThunk<void, { result: TTMLocation; index: number }>(
    "searchPage/searchResultClicked",
    async (data, thunkApi) => {
        thunkApi.dispatch(searchActions.highlightedSelectedResultIndex(null));
        thunkApi.dispatch(searchActions.updateSelectedResultIndex(data.index));
        thunkApi.dispatch(
            changeSelectedLocation({
                location: data.result,
                selectedFrom: "HTML_UI"
            })
        );
    }
);

export const updateRecentSearches = createAsyncThunk<void, TTMSearchResult, { state: RootState }>(
    "searchPage/updateRecentSearches",
    async (data, thunkApi) => {
        const state = thunkApi.getState();
        // add new item on front, filter in case it was selected before and crop to max list size
        const recentSearches = [
            withSearchText(data),
            ...state.mapPage.search.recentSearches.filter((r) => r.externalID !== data.externalID)
        ].slice(0, recentSearchMaxListSize);
        setLocalStorageJson(recentSearchesKey, recentSearches);
        thunkApi.dispatch(searchActions.setRecentSearches(recentSearches));
    }
);

export const searchBoxInputConfirmed = createAsyncThunk<void, void, { state: RootState }>(
    "searchPage/searchBoxInputConfirmed",
    async (data, thunkApi) => {
        const state = thunkApi.getState();

        const displayedSearchLocations = selectors.selectCombinedSearchLocations(state);
        const areLatestResultsDisplayed = selectAreLatestResultsDisplayed(state);
        const viewportCheckTempLocations = selectViewportCheckTempLocations(state);
        const isAutoCompleteSegmentSelectedByUser = selectIsAutoCompleteSegmentSelectedByUser(state);
        const matchedAutoCompleteSearch = selectMatchedAutoCompleteSearch(state);
        const mapViewableBBox = selectViewableMapBBox(state);

        let newMatchedAutoCompleteSearch = matchedAutoCompleteSearch;
        const searchLocations = areLatestResultsDisplayed ? displayedSearchLocations : viewportCheckTempLocations;
        const searchResults = searchLocations.map((location) => getLocationInfo(location));
        const noResultsFound = !searchLocations.length;

        if (noResultsFound) {
            thunkApi.dispatch(searchActions.setNoResultsFoundError(true));
            return;
        }

        const extractViewport = (link: string) => {
            if (!link.includes('boundingBox')) {
                return undefined;
            }
            const urlSearchParams = new URLSearchParams(link.split('?')[1]);
            const boundingBox = urlSearchParams.get('boundingBox');
            const [topLatitude, leftLongitude, bottomLatitude, rightLongitude] = boundingBox.split(',');
            return [[new Number(topLatitude), new Number(leftLongitude)], [new Number(bottomLatitude), new Number(rightLongitude)]];
        }

        if (!isAutoCompleteSegmentSelectedByUser) {
            newMatchedAutoCompleteSearch = detectRecoveryVsDiscovery(searchResults);
            thunkApi.dispatch(searchActions.updateMatchedAutoCompleteSearch(newMatchedAutoCompleteSearch));
        }
        const firstSearchResult: TTMSearchResult = searchResults.find((result) => isSearchType(result.ngsType));
        // if search intention detected to be discovery, redo final search call with category/brand filter based on the new viewport
        if (newMatchedAutoCompleteSearch?.searchIntention == SearchIntention.DISCOVERY) {
            // TODO replace this with positions from /discover and see what happens (it should fix the map moving back to old place)
            
            let areResultsOutsideViewport;
            let newBounds;
            if (isAutoCompleteSegmentSelectedByUser) {
                areResultsOutsideViewport = checkIfResultsOutsideViewport(
                    searchResults,
                    newMatchedAutoCompleteSearch,
                    mapViewableBBox
                );
                newBounds = areResultsOutsideViewport
                    ? (locationInfosToMapBounds(searchResults).toArray() as [[number, number], [number, number]])
                    : mapViewableBBox;
            } else {
                const viewport = extractViewport(newMatchedAutoCompleteSearch.result.link) as [[number, number], [number, number]];
                areResultsOutsideViewport = ngsCheckIfResultsOutsideViewport(
                    viewport,
                    newMatchedAutoCompleteSearch,
                    mapViewableBBox
                );
                newBounds = areResultsOutsideViewport
                    ? ([[viewport[0][1], viewport[1][0]],[viewport[1][1], viewport[0][0]]] as [[number, number], [number, number]])
                    : mapViewableBBox;
            }
            thunkApi.dispatch(searchActions.changeAreResultsOutsideViewport(areResultsOutsideViewport));
            // if ANY fuzzy search results are outside of viewport, get the new bbox that contains all results and performing the last search call based on it
            await thunkApi.dispatch(
                searchByBrandCategoryFilter({
                    matchedAutoCompleteSearch: newMatchedAutoCompleteSearch,
                    boundingBox: newBounds,
                    isFinalCall: true
                })
            );
            thunkApi.dispatch(
                logEventWithActiveMode({
                    event_name: "search_result_selected",
                    search_query_length: newMatchedAutoCompleteSearch.matchedTextValue.length,
                    item_index: 0,
                    search_intent: "discovery",
                    location_type: "POI",
                    method: MAIN_LIST
                })
            );
            thunkApi.dispatch(searchActions.changeAreAutocompleteDisplayed(false));
            // carry out the selected category/brand value to search input and URL
            thunkApi.dispatch(searchActions.changeSearchInput(newMatchedAutoCompleteSearch.matchedTextValue));
            thunkApi.dispatch(updateWithSearchQuery(newMatchedAutoCompleteSearch.matchedTextValue));
        } else if (firstSearchResult) {
            const locationDetails = await fetchPoiById(
                firstSearchResult.externalID,
                firstSearchResult.link
            );
            const locationFromDetails = detailsToTTMSearchResult(locationDetails);
            thunkApi.dispatch(
                logEventWithActiveMode({
                    event_name: "search_result_selected",
                    search_query_length: newMatchedAutoCompleteSearch.matchedTextValue.length,
                    item_index: 0,
                    search_intent: "recovery",
                    location_type: locationFromDetails.type,
                    method: MAIN_LIST
                })
            );
            await thunkApi.dispatch(updateRecentSearches(locationFromDetails));
            thunkApi.dispatch(
                changeSelectedLocation({
                    location: locationFromDetails as TTMLocation,
                    selectedFrom: "HTML_UI"
                })
            );
        }
    }
);

export const clearSearch = createAsyncThunk<void, void, { state: RootState }>(
    "searchPage/clearSearch",
    async (data, thunkApi) => {
        thunkApi.dispatch(searchActions.clearSearchResults());
        thunkApi.dispatch(searchActions.setSearchCategoryBrandTemporary([]));
        thunkApi.dispatch(locationActions.setSelectedLocation(null));
        const pathname = thunkApi.getState().router.location.pathname;
        !isExactURLPathMatch(pathname, URL_PATH_MAP.INDEX) && thunkApi.dispatch(navigateHome());
        thunkApi.dispatch(updateWithSearchQuery(""));
    }
);

export const fetchSearch = createAsyncThunk<void, { query: string }, { state: RootState }>(
    "searchPage/fetchSearch",
    async (data, thunkApi) => {
        const state = thunkApi.getState();
        thunkApi.dispatch(updateWithSearchQuery(data.query));
        thunkApi.dispatch(searchActions.changeAreAutocompleteDisplayed(true));
        // clear search pins on the map (if any) when you change the search query
        thunkApi.dispatch(searchActions.clearSearchResultsOnMap());

        await thunkApi.dispatch(
            fetchSearchAndAutocompleteSuggestions({
                ...data,
                center: retrievePositionFromURL(state)
            })
        );
        highlightSearchResults(data.query);
    }
);
